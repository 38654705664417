// A partial list of LaunchDarkly feature flag names.
// See also the list in projects/portal-frontend/scw-angular/feature-flags/FeatureFlagsApi.js
export const featureFlags = {
    MISSION_IN_COURSES: 'MISSION_IN_COURSES',
    LEVEL_GROUPING: 'level-grouping-feature',
    COMMS_CENTRE: 'comms-centre',
    COMMS_CENTRE_QUILL: 'comms-centre-quill',
    COMMS_CENTRE_LEGACY: 'comms-centre-legacy',
    CUSTOMER_PLAN: 'customer-plan',
    CYBERMON: 'cybermon',
    PUBLIC_TOURNAMENTS: 'public-tournaments',
    NEW_TOURNAMENTS_LIST: 'new-tournaments-list-page',
    REPORTING: 'reporting',
    ONBOARDING_APP: 'onboarding-app',
    CUSTOMER_ANALYTICS: 'customer-analytics',
    VULNERABILITY_SUMMARY_REPORT: 'vulnerability-summary-report',
    MULTI_COMPANY_TOURNAMENT_BANNER: 'multi-company-tournament-banner',
    QUESTS: 'quests',
    GOALS_ADMIN: 'goals-admin',
    MULTI_ASSESSMENTS: 'multi-assessments',
    HIDE_TRAINING: 'hide-training',
    SHOW_EXPLORE_MENU_ITEM: 'show-explore-menu-item',
    TOURNAMENT_INVITES: 'tournament-invites',
};
