import { useEffect, useMemo, useState } from 'react';
import { useExploreFilterContext } from '../../explore/FilterContext';
import { useCybermon } from './useCybermon';
const ITEM_INCREMENT = 3;
const CYBERMON_WEEKLY_VULNERABILITY = 'Injection Flaws';
const prioritiseActivities = (items, activeCybermon) => {
    // Define the activity order
    const activityOrder = ['coding_lab', 'walkthrough', 'mission', 'challenge'];
    const activities = items.filter((item) => {
        var _a;
        if (!('language' in item) ||
            item.type.includes('video') ||
            item.type.includes('guideline') ||
            item.status === 'completed' ||
            item.language.languageId === 'pseudocode') {
            return false;
        }
        return (_a = item.category.mainName === (activeCybermon === null || activeCybermon === void 0 ? void 0 : activeCybermon.custom_data.category)) !== null && _a !== void 0 ? _a : CYBERMON_WEEKLY_VULNERABILITY;
    });
    // Sort otherActivities according to the activityOrder
    return activities.sort((a, b) => activityOrder.indexOf(a.type) - activityOrder.indexOf(b.type));
};
export const useCybermonActivityFilter = (initialUserLanguageFrameworks) => {
    const { activeCybermon } = useCybermon();
    const { doFilterAction } = useExploreFilterContext().getState();
    const { selectedLanguages, filteredItems } = useExploreFilterContext()((store) => ({
        selectedLanguages: store.filterState.language,
        filteredItems: store.filteredItems,
    }));
    const prioritisedActivities = useMemo(() => prioritiseActivities(filteredItems, activeCybermon), [filteredItems, activeCybermon]);
    useEffect(() => {
        const newLanguages = selectedLanguages.filter((lang) => !lang.startsWith('pseudocode'));
        if (newLanguages.length !== selectedLanguages.length) {
            doFilterAction({ action: 'changeLanguages', value: newLanguages });
        }
    }, [selectedLanguages, doFilterAction]);
    const [maxItems, setMaxItems] = useState(ITEM_INCREMENT);
    useEffect(() => {
        setMaxItems(ITEM_INCREMENT);
    }, [selectedLanguages, prioritisedActivities]);
    useEffect(() => {
        if (initialUserLanguageFrameworks && !selectedLanguages.length) {
            doFilterAction({ action: 'changeLanguages', value: [initialUserLanguageFrameworks] });
        }
    }, [initialUserLanguageFrameworks, selectedLanguages.length, doFilterAction]);
    const visibleActivities = useMemo(() => {
        if (selectedLanguages.length === 0) {
            return [];
        }
        return prioritisedActivities.slice(0, maxItems);
    }, [maxItems, prioritisedActivities, selectedLanguages.length]);
    return {
        selectedLanguages: selectedLanguages,
        canLoadMore: prioritisedActivities.length > maxItems,
        visibleActivities,
        loadMore: () => setMaxItems((max) => max + ITEM_INCREMENT),
        handleLanguageSelected: (languages) => doFilterAction({ action: 'changeLanguages', value: languages }),
        itemsPerPage: ITEM_INCREMENT,
    };
};
