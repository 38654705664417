// imports with global side-effects
import 'bootstrap';
// The expose-loader is not required for angular because angular 1.7.9 isn't a common js module
// (it sets window.angular internally).
import 'angular';
import 'angular-animate';
import 'angular-messages';
import 'angular-aria';
import 'angular-material';
import 'angular-sanitize';
import 'angular-touch';
import 'angular-drag-and-drop-lists';
import 'angular-ui-router';
import 'angular-dynamic-locale';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-translate-loader-partial';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';
import 'angularjs-count-to';
import 'imports-loader?imports=default|humanize-duration|humanizeDuration!angular-timer';
import 'expose-loader?exposes=introJs!intro.js';
import 'angular-spinkit';
import 'angular-tablesort';
// find the correct version for this
import '../vendor/js/angular-tree-control';
import 'chart.js';
import 'd3';
import 'nvd3';
import 'angular-chart.js';
import 'angular-nvd3';
import 'easy-pie-chart/dist/angular.easypiechart';
import 'angular-svg-round-progressbar';
import 'ui-select';
import 'bootstrap-select';
import '@lordfriend/nya-bootstrap-select';
import 'sweetalert';
import 'angular-ui-bootstrap';
import 'ng-file-upload';
import 'ng-tags-input';
import 'bootstrap-switch';
import 'angular-bootstrap-switch';
import 'tv4';
import 'objectpath';
import 'lodash';
import 'angulartics';
import 'angulartics-google-analytics';
import 'keyboardevent-key-polyfill';
import 'angularjs-slider';
import 'angular-scroll';
import 'angular-libphonenumber/dist/libphonenumber.full';
import 'angular-libphonenumber';
import 'dd-text-collapse';
import _ from 'lodash';

import '../vendor/js/schema-form.min.js';
import '../vendor/js/bootstrap-decorator.min.js';

// required by this file
import angular from 'angular';
import moment from 'moment-timezone';

import SCWAuth from './auth';
import SCWCommons from './components/commons';
import SCWError from './error';
import SCWChallenge from './challenge';
import SCWGameApi from './game-api';
import SCWStory from './story';
import SCWIntroSplash from './intro-splash';
import SCWManagementMetrics from './management/metrics';
import SCWManagementAdmin from './management/admin';
import SCWAdminApi from './management/admin-api';
import SCWLearningResources from './learning-resources';
import ngMedia from './ng-media-events';
import SCWGame013 from './game-013';
import SCWUtil, { SCWUtilRun } from './util';
import SCWTraining from './training';
import SCWProductTrial from './product-trial';
import SCWAssessments from './assessments';
import SCWQuestProgress from './components/quest-progress';
import SCWTournaments from './tournaments';
import SCWTournamentQuestLog from './components/tournament-quest-log';
import SCWTournamentQuestPreview from './components/tournament-quest-preview';
import SCWChallengeInactivityDetect from './components/challenge-inactivity-detect';
import SCWActivityTracker from './components/activity-tracker';
import ContextualMicrolearning from './contextual-microlearning';
import SCWSearchWidget from './components/search-widget';
import SenseiApi from './sensei/sensei-api';
import SCWSensei from './sensei';
import SCWEventLogApi from './eventlog';
import SCWGameDashboard from './dashboard';
import SCWWalkthrough from './walkthroughs';
import SCWMetadataApi from './metadata';
import SCWCustomCheckbox from './components/custom-checkbox';
import SCWFileInput from './components/file-input';
import SCWTriggerFocus from './components/trigger-focus';
import SCWInactivityDetector from './components/inactivity-detector';
import SCWMissionsDebugBar from './components/shared/missions-debug-bar/missions-debug-bar.component';
import SCWMultiUiSelectModule from './components/shared/multi-ui-select/multi-ui-select.component';
import SCWTaggingUiSelectModule from './components/shared/tagging-ui-select/tagging-ui-select.component';
import SCWAnalyticsRouteChange from './AnalyticsRouteChange';
import SCWMaturityBadge from './components/maturity-badge';
import SCWCustomerPlan from './customer-plan';
import SCWReporting from './reporting';
import SCWTrustAgent from './trust-agent';
import SCWReact from './scw-react';

import gameChallengeTemplate from './challenge/challenge.html';
import managementMetricsTemplate from './management/metrics/stats.html';
import managementAdminTemplate from './management/admin/admin.html';
import senseiMetricsTemplate from './sensei/metrics.html';
import senseiDownloadTemplate from './sensei/download.html';
import senseiDownloadIdeTemplate from './sensei/download-ide.html';
import senseiNotLicensedTemplate from './sensei/sensei-not-licensed.html';
import senseiAuthTemplate from './sensei/auth.html';
import introSplashTemplate from './intro-splash/intro-splash.html';
import unsupportedApiTemplate from './unsupported-api.html';
import errorTemplate from './error/error.html';
import unavailableTemplate from './unavailable.html';
import notFoundTemplate from './not-found.html';
import keyboardNavigationTemplate from './components/commons/keyboard-navigation.directive.help.html';
import signupModalTemplate from './new-simple-flow/signup-modal.html';
import { iframes, messages } from './enums';

import {
  generateUserAndPlanPropertiesForUsersnap,
  setMissionChallengeIdIfApplicable,
  setMissionCourseChallengeIdIfApplicable,
  addExploreOrQuestMissionDataToUserSnapData,
} from './usersnap-helpers';
import { AUTH_EVENTS, FEATURE_EVENTS } from './auth/constants'; // Design System
import SCWDesignSystem from '../lib/design-system-angularjs';
import { changeLocalVideoPreferences, generateTheme } from '@securecodewarrior/design-system-react';
import { i18n, platformLocaleToBcp47, decideThemeByBareRoute } from '@scw/react-components';
import { mapDesignSystemUser } from './assessments/utils';

window.moment = moment;
window.emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~\-.]+@[a-z0-9_.-]+\.[a-z]{2,63}(\.[a-z]{2})?$/i;

// set document domain to root domain & skip localhost (for userflow)
try {
  if (document.domain !== 'localhost') {
    document.domain = 'securecodewarrior.com';
  }
} catch (e) {
  console.log('Error setting document.domain', e);
}

const basePageTitle = 'Secure Code Warrior';
const basePageMetaDescription =
  'Secure your code from the start with gamified, scalable online security training for software developers';

function updatePageTitleAndDescription($window, $state, $translate) {
  try {
    if (!$state.current?.data?.translateKey) {
      $window.document.title = basePageTitle;
      $window.document.getElementsByTagName('meta').title.content = basePageTitle;
      $window.document.getElementsByTagName('meta').description.content = basePageMetaDescription;

      return;
    }

    $translate([$state.current.data.translateKey]).then(function (translations) {
      const title = Object.values(translations)[0] + ' | ' + basePageTitle;

      $window.document.title = title;
      $window.document.getElementsByTagName('meta').title.content = title;
      $window.document.getElementsByTagName('meta').description.content = basePageMetaDescription;
    });
  } catch (error) {
    console.log('updatePageTitleAndDescription error: ', error);
  }
}

function versionCheck(detectedVersion) {
  var minCheck = false;
  var maxCheck = false;
  var i;

  var detectedVersionArr = detectedVersion.split('.').map(function (val) {
    return parseInt(val);
  });
  var API_MIN_VER_Arr = window.SCW_ENV.API_MIN_VER.split('.').map(function (val) {
    return parseInt(val);
  });
  var API_MAX_VER_Arr = window.SCW_ENV.API_MAX_VER.split('.').map(function (val) {
    return parseInt(val);
  });

  if (detectedVersionArr.length !== 3) return false;

  for (i = 0; i < 3; i++) {
    if (detectedVersionArr[i] < API_MIN_VER_Arr[i]) {
      minCheck = false;
      break;
    }

    if (detectedVersionArr[i] === API_MIN_VER_Arr[i]) {
      minCheck = true;
      continue;
    }

    if (detectedVersionArr[i] > API_MIN_VER_Arr[i]) {
      minCheck = true;
      break;
    }
  }

  for (i = 0; i < 3; i++) {
    if (detectedVersionArr[i] > API_MAX_VER_Arr[i]) {
      maxCheck = false;
      break;
    }

    if (detectedVersionArr[i] === API_MAX_VER_Arr[i]) {
      maxCheck = true;
      continue;
    }

    if (detectedVersionArr[i] < API_MAX_VER_Arr[i]) {
      maxCheck = true;
      break;
    }
  }
  return minCheck && maxCheck;
}

/*
  Workaround for SweetAlert not cleaning up its keydown handler and preventing tab key from working
  https://github.com/t4t5/sweetalert/issues/127
*/
// (function (){
// 	var _swal = window.swal;
// 	window.swal = function() {
// 		var previousWindowKeyDown = window.onkeydown;
// 		_swal.apply(this, Array.prototype.slice.call(arguments, 0));
// 		window.onkeydown = previousWindowKeyDown;
// 	};
// 	window.swal.close = _swal.close;
// })();

// IMPORTANT: when using this function you might need to do a `scope.$watch('isHeaderHidden', ...)` to
// redo this check (if the page hides the header)
window.getResponsiveHeight = function getResponsiveHeight() {
  const header = angular.element('#header > nav').outerHeight(true) ?? 0;
  const footer = angular.element('#footer').outerHeight(true) ?? 0;
  return window.innerHeight - header - footer;
};

window.minHeight = 450;

/* exported encodeParams */
window.encodeParams = function encodeParams(params) {
  for (var i = 0; i < params.length; i++) {
    var param = params[i];
    if (typeof param == 'string') {
      param = encodeURIComponent(param);
    }
  }
};

const MODULE = 'SCWApp';
export default MODULE;

var app = angular.module(MODULE, [
  'ngMaterial',
  'ngMessages',
  'ngAnimate',
  'ngTouch',
  'pascalprecht.translate',
  'countTo',
  'ui.router',
  'tmh.dynamicLocale',
  'ui.bootstrap',
  'treeControl',
  'ngSanitize',
  'ui.select',
  'angular-svg-round-progressbar',
  'chart.js',
  'rzModule',
  'easypiechart',
  'angular-spinkit',
  'nvd3',
  'tableSort',
  'dndLists',
  'nya.bootstrap.select',
  'random-components',
  'ngFileUpload',
  'frapontillo.bootstrap-switch',
  'schemaForm',
  'ngTagsInput',
  'angulartics',
  'angulartics.google.analytics',
  SCWAuth,
  SCWError,
  SCWChallenge,
  SCWGameApi,
  SCWStory,
  SCWIntroSplash,
  SCWManagementMetrics,
  SCWManagementAdmin,
  SCWAdminApi,
  SCWLearningResources,
  ngMedia,
  SCWGame013,
  SCWTraining,
  SCWProductTrial,
  SCWAssessments,
  SCWQuestProgress,
  SCWTournaments,
  SCWTournamentQuestLog,
  SCWTournamentQuestPreview,
  SCWChallengeInactivityDetect,
  SCWActivityTracker,
  ContextualMicrolearning,
  SCWUtil,
  SCWUtilRun,
  SCWCommons,
  SCWSearchWidget,
  SenseiApi,
  SCWSensei,
  SCWEventLogApi,
  SCWGameDashboard,
  SCWWalkthrough,
  SCWMetadataApi,
  SCWCustomCheckbox,
  SCWFileInput,
  SCWTriggerFocus,
  SCWInactivityDetector,
  SCWMissionsDebugBar,
  SCWMultiUiSelectModule,
  SCWTaggingUiSelectModule,
  SCWAnalyticsRouteChange,
  SCWMaturityBadge,
  SCWCustomerPlan,
  SCWReporting,
  SCWTrustAgent,
  SCWDesignSystem,
  SCWReact,
]);

app.controller('NotFoundController', [
  '$location',
  function ($location) {
    console.error('Route not found', $location.absUrl());
  },
]);

app.controller('UnavailableController', [
  '$log',
  '$scope',
  '$rootScope',
  '$state',
  '$http',
  '$interval',
  '$timeout',
  '$window',
  'HttpConfigService',
  function ($log, $scope, $rootScope, $state, $http, $interval, $timeout, $window, HttpConfigService) {
    $rootScope.cancelCheckInterval();

    $scope.inMaintenance = false;

    // close walkthroughs
    if (introJs && introJs().exit) {
      introJs().exit();
    }

    function ping() {
      $http
        .get($window.SCW_ENV.ApiEndpoint + '/ping', HttpConfigService.getHttpConfigNoIFR())
        .then(function (response) {
          if (
            response.data &&
            response.data.app &&
            response.data.app.version &&
            versionCheck(response.data.app.version) &&
            response.data.healthy
          ) {
            $log.debug('API endpoint back up: ', response);
            $rootScope.startCheckInterval(false);

            if ($rootScope.requestedState) {
              if ($rootScope.requestedState === 'unavailable') {
                $rootScope.requestedState = null;
                $rootScope.requestedStateParams = null;
                $rootScope.defaultRedirects();
                return;
              }
              $log.debug('Trying to re-route to original destination state: ' + $rootScope.requestedState);
              var goToState = $rootScope.requestedState;
              var goToStateParams = $rootScope.requestedStateParams;
              $rootScope.requestedState = null;
              $rootScope.requestedStateParams = null;
              $state.go(goToState, goToStateParams);
            } else {
              $rootScope.defaultRedirects();
            }
          } else {
            $log.debug('API endpoint up but unhealthy or version remains unsupported: ', response);
          }
        })
        .catch(function (response) {
          $log.debug('API endpoint still down: ', response);
        });
    }

    function pingCheckVersionOnly() {
      return $http
        .get($window.SCW_ENV.ApiEndpoint + '/ping', HttpConfigService.getHttpConfigNoIFR())
        .then(function (response) {
          if (
            response.data &&
            response.data.app &&
            response.data.app.version &&
            versionCheck(response.data.app.version)
          ) {
            $log.debug('API endpoint back up: ', response);
            $rootScope.startCheckInterval(false);

            if ($rootScope.requestedState) {
              if ($rootScope.requestedState === 'unavailable') {
                $rootScope.requestedState = null;
                $rootScope.requestedStateParams = null;
                $rootScope.defaultRedirects();
                return;
              }
              $log.debug('Trying to re-route to original destination state: ' + $rootScope.requestedState);
              var goToState = $rootScope.requestedState;
              var goToStateParams = $rootScope.requestedStateParams;
              $rootScope.requestedState = null;
              $rootScope.requestedStateParams = null;
              $state.go(goToState, goToStateParams);
            } else {
              $rootScope.defaultRedirects();
            }
          } else {
            throw new Error('Unsupported version');
          }
        })
        .catch(function (response) {
          if (response.message === 'Unsupported version') {
            $log.debug('Reloading page in 30 seconds to check for new version');
            $timeout(function () {
              $window.location.reload(); // try to force reload to load new version of frontend
            }, 30000);
          } else {
            throw new Error('API endpoint down');
          }
        });
    }

    const { MAINTENANCE_WINDOW } = $window.SCW_ENV;

    if ($state.current.name === 'unavailable') {
      if (Date.now() >= MAINTENANCE_WINDOW.start && Date.now() <= MAINTENANCE_WINDOW.end) {
        $scope.inMaintenance = true;
        $scope.maintenance = {
          start: moment(MAINTENANCE_WINDOW.start).format('LLL'),
          end: moment(MAINTENANCE_WINDOW.end).format('LLL'),
          message: MAINTENANCE_WINDOW.message,
        };
      }
      $scope.INTERVAL = 10000;
      $scope.intervalPromise = $interval(ping, $scope.INTERVAL);
      ping();
    } else if ($state.current.name === 'unsupported-api') {
      $scope.maintenance = {
        start: moment(MAINTENANCE_WINDOW.start).format('LLL'),
        end: moment(MAINTENANCE_WINDOW.end).format('LLL'),
        message: MAINTENANCE_WINDOW.message,
      };

      pingCheckVersionOnly();
    }

    $scope.$on('$destroy', function () {
      if ($scope.intervalPromise) {
        $interval.cancel($scope.intervalPromise);
      }
    });
  },
]);

app.directive('origNgClick', [
  '$parse',
  function ($parse) {
    return {
      compile: function (_$element, attr) {
        var fn = $parse(attr['origNgClick']);

        return function handler(scope, element) {
          var origClickHandler = function (event) {
            scope.$apply(function () {
              fn(scope, { $event: event });
            });
          };

          element.on('click', origClickHandler);
          scope.$on('$destroy', function () {
            element.off('click', origClickHandler);
          });
        };
      },
    };
  },
]);

app.directive('ngOnload', [
  function () {
    return {
      scope: {
        callBack: '&ngOnload',
      },
      link: function (scope, element) {
        element.on('load', function () {
          return scope.callBack();
        });
      },
    };
  },
]);

app.filter('domain', function () {
  return function (input) {
    var matches,
      output = '',
      urls = /\w+:\/\/([\w|.]+)/;

    matches = urls.exec(input);

    if (matches !== null) output = matches[1];

    return output;
  };
});

app.filter('trusted', [
  '$sce',
  function ($sce) {
    return function (url) {
      return $sce.trustAsResourceUrl(url);
    };
  },
]);

app.filter('duration', [
  '$rootScope',
  function ($rootScope) {
    return function (duration, _props) {
      return $rootScope.utility.longFormatDuration(duration);
    };
  },
]);

app.filter('categoryFilter', [
  function () {
    function matchesSubcategory(categories, categoryId, searchTerm) {
      var category = categories[categoryId];
      var text = searchTerm.toLowerCase();

      var itemMatches;
      for (var i in category.subitem) {
        itemMatches = category.subitem[i].name.toLowerCase().indexOf(text) !== -1;
        if (itemMatches) {
          break;
        }
      }

      return itemMatches;
    }

    return function (items, opt) {
      var out = [];

      var props = opt.props;
      var categories = opt.categories;

      if (angular.isArray(items)) {
        items.forEach(function (item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();

            // if empty, assume true
            if (!text) {
              itemMatches = true;
            } else {
              // match against top level category name
              itemMatches = item[prop].toString().toLowerCase().indexOf(text) !== -1;

              // if no match, try to match against a subitem too
              if (!itemMatches) {
                itemMatches = matchesSubcategory(categories, item.categoryId, text);
              }

              if (itemMatches) {
                break;
              }
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  },
]);

// for ui-selects - from ui-select example - turns search from AND to OR on 2 search fields
app.filter('propsFilter', function () {
  return function (items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach(function (item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
});

app.filter('newlineToBrTag', function () {
  return function (str) {
    return str && str.replace(/\n/g, '<br/>');
  };
});

app.filter('timeElapsed', [
  'UtilityService',
  function (UtilityService) {
    return function (ms) {
      return UtilityService.timeElapsed(ms);
    };
  },
]);

app.filter('replace', function () {
  return function (str, _from, _to) {
    if (!str) return str;

    return str.replace(new RegExp(_from, 'g'), _to);
  };
});

app.config([
  '$mdThemingProvider',
  function ($mdThemingProvider) {
    $mdThemingProvider.definePalette(
      'scw-primary',
      $mdThemingProvider.extendPalette('orange', {
        300: 'fed18f',
        400: 'ffc012',
        500: 'ffb700',
        600: 'f38b00',
      })
    );
    $mdThemingProvider.definePalette(
      'scw-accent',
      $mdThemingProvider.extendPalette('grey', {
        50: 'ffffff',
        100: 'd3d6d9',
        200: 'd4d4d4',
        300: 'aeaeae',
        400: '606060',
        500: '4f5459',
        600: '5d656f',
        700: '383c42',
        800: '25282c',
        900: '1e2125',
        A100: '464b53',
        A200: '2f3237',
        A400: '484e55',
        A700: '000000',
      })
    );

    $mdThemingProvider.theme('default').primaryPalette('scw-primary').accentPalette('scw-accent');
  },
]);

// Disable Angular Debug
app.config([
  '$compileProvider',
  function ($compileProvider) {
    $compileProvider.debugInfoEnabled(false);
    $compileProvider.commentDirectivesEnabled(false);
  },
]);

app.config([
  'tmhDynamicLocaleProvider',
  function (tmhDynamicLocaleProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
  },
]);

// Route stuff
app.config([
  '$provide',
  '$stateProvider',
  'USER_ROLES',
  '$logProvider',
  '$translateProvider',
  '$locationProvider',
  '$windowProvider',
  function (
    $provide,
    $stateProvider,
    USER_ROLES,
    $logProvider,
    $translateProvider,
    $locationProvider,
    $windowProvider
  ) {
    const $window = $windowProvider.$get();
    $logProvider.debugEnabled($window.SCW_ENV.DEBUG_ENABLED);

    (function () {
      var flow = false;
      var $sP = $stateProvider.state;
      $stateProvider.state = function (name, config) {
        config = config || {};
        config.params = config.params || {};
        config.params.flow = config.params.flow || false;

        return $sP.apply(this, [name, config]);
      };

      $provide.decorator('$state', [
        '$delegate',
        function ($delegate) {
          $delegate.flow = function (path, params, options) {
            var args = flow || [path, params, options];
            if ($window.SCW_ENV.DEBUG_ENABLED) {
              console.log('Invoked flow and using args ', args);
            }
            return $delegate.go.apply($delegate, args);
          };

          var fnGo = $delegate.go;
          $delegate.go = function (name, params, config) {
            flow = false;
            if (params && params.flow) {
              flow = params.flow;
              if (flow === 'bounce' || flow === 'back')
                //:
                flow = [$delegate.current.name, $delegate.current.params];
            }

            return fnGo.apply($delegate, [name, params, config]);
          };
          return $delegate;
        },
      ]);
    })();

    $locationProvider.hashPrefix('');

    $stateProvider
      .state('challenge-test', {
        url: '/app/{appId}/challenge/{challengeId}/cbl/{cbl}',
        templateUrl: gameChallengeTemplate,
        controller: 'ChallengeTestController',
        data: {
          authorizedRoles: [USER_ROLES.cmsDeveloper, USER_ROLES.admin],
        },
      })

      .state('learning-resources-search', {
        url: '/learning-resources-search?searchtext',
        controller: 'LearningResourcesSearchController',
      })

      .state('learning-resources', {
        url: '/learning-resources/{path:.*}',
        controller: 'LearningResourcesController',
      })

      .state('metrics', {
        url: '/metrics?panel&t1&t2&interval&from&to&language&framework&d',
        templateUrl: managementMetricsTemplate,
        controller: 'ManagementMetricsController',
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('admin', {
        url: '/admin?d',
        templateUrl: managementAdminTemplate,
        controller: 'ManagementAdminController',
        data: {
          authorizedRoles: [USER_ROLES.admin, USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager],
        },
      })
      .state('sensei-metrics', {
        url: '/sensei/metrics',
        templateUrl: senseiMetricsTemplate,
        controller: 'SenseiController',
      })
      .state('sensei', {
        url: '/sensei',
        templateUrl: senseiDownloadTemplate,
        controller: 'SenseiController',
      })
      .state('sensei-download', {
        url: '/sensei/download',
        templateUrl: senseiDownloadTemplate,
        controller: 'SenseiController',
      })
      .state('sensei-download-ide', {
        url: '/sensei/download/:ideName',
        templateUrl: senseiDownloadIdeTemplate,
        controller: 'SenseiController',
      })
      .state('sensei-not-licensed', {
        url: '/sensei-not-licensed',
        templateUrl: senseiNotLicensedTemplate,
      })
      .state('sensei-auth', {
        url: '/sensei/auth/:token',
        templateUrl: senseiAuthTemplate,
        controller: 'SenseiAuthController',
      })
      .state('intro-splash', {
        url: '/intro-splash',
        templateUrl: introSplashTemplate,
        controller: 'IntroSplashController',
      })
      .state('unsupported-api', {
        url: '/unsupported-api',
        templateUrl: unsupportedApiTemplate,
        controller: 'UnavailableController',
      })
      .state('error', {
        url: '/error/:err?ecid&region',
        templateUrl: errorTemplate,
        controller: 'ErrorPageController',
      })
      .state('unavailable', {
        url: '/unavailable',
        templateUrl: unavailableTemplate,
        controller: 'UnavailableController',
      })
      .state('otherwise', {
        url: '*path',
        templateUrl: notFoundTemplate,
        controller: 'NotFoundController',
      });
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
    // $translateProvider.useMessageFormatInterpolation();
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
    $translateProvider.directivePriority(0);
    //Sets available language keys and determines language preference by browser settings
    $translateProvider.registerAvailableLanguageKeys(['en', 'en-gb', 'es', 'fr', 'de', 'ko', 'zh-tw', 'zh', 'ja'], {
      en_GB: 'en-gb',
      en_US: 'en',
      'es_*': 'es',
      'fr_*': 'fr',
      'de_*': 'de',
      'ko_*': 'ko',
      zh_TW: 'zh-tw',
      'zh_*': 'zh',
      'ja_*': 'ja',
      '*': 'en',
    });
    $translateProvider.determinePreferredLanguage();
    $translateProvider.useStaticFilesLoader({
      prefix: '/main-',
      suffix: '.json',
    });
  },
]);

app.filter('isDefined', function () {
  return function (value, msg) {
    if (value === undefined || value === false) {
      throw new Error('isDefined filter got undefined value ' + msg);
    }
    return value;
  };
});

app.run([
  '$location',
  '$rootScope',
  '$log',
  '$http',
  '$state',
  '$stateParams',
  '$window',
  '$timeout',
  '$interval',
  '$analytics',
  'Session',
  '$uibModal',
  '$translate',
  '$swal',
  'tmhDynamicLocale',
  'HttpConfigService',
  'UserPreferences',
  'AuthService',
  'FeatureFlags',
  'FeatureFlagsApi',
  'ErrorHandler',
  'MetadataApiService',
  'ActivityTracker',
  'PlatformLanguagesService',
  'USER_ROLES',
  'CustomerPlanApi',
  'LearningresourcesApiService',
  'AnalyticsService',
  'AdminApiService',
  function (
    $location,
    $rootScope,
    $log,
    $http,
    $state,
    $stateParams,
    $window,
    $timeout,
    $interval,
    $analytics,
    Session,
    $uibModal,
    $translate,
    $swal,
    tmhDynamicLocale,
    HttpConfigService,
    UserPreferences,
    AuthService,
    FeatureFlags,
    FeatureFlagsApi,
    ErrorHandler,
    MetadataApiService,
    ActivityTracker,
    PlatformLanguagesService,
    USER_ROLES,
    CustomerPlanApi,
    LearningresourcesApiService,
    AnalyticsService,
    AdminApiService
  ) {
    ActivityTracker.init();

    if ($location.search().lmsEnabled && window.opener) {
      $window.sessionStorage.setItem('lmsEnabled', JSON.stringify(true));
      if (window.top.opener) {
        // TODO: see if we can move inteval duration this to env variables
        $rootScope.lmsWatchdogPromise = $interval(function () {
          if (!$window.top.opener) {
            $swal({
              title: $translate.instant('LMS_CONNECTION_LOST_TITLE'),
              text: $translate.instant('LMS_CONNECTION_LOST_MESSAGE'),
              type: 'warning',
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
              keyResolution: true,
            });
            $window.CancelLmsWatchdogInterval();
          }
        }, 1000);
      }
    }

    $rootScope.isLMSInitialized = function () {
      return window.top.opener && JSON.parse($window.sessionStorage.getItem('lmsEnabled')) === true;
    };

    $rootScope.lmsClose = function () {
      $window.CancelLmsWatchdogInterval;
      $window.close();
    };
    $window.CancelLmsWatchdogInterval = function () {
      if ($rootScope.lmsWatchdogPromise) {
        $interval.cancel($rootScope.lmsWatchdogPromise);
        delete $rootScope.lmsWatchdogPromise;
      }
    };

    $rootScope.copyrightYear = moment().year();

    /*
     * PORTAL-1168
     * Tracking referal sources for partner links
     */

    // use utm_source query param OR document.referrer
    $rootScope.referrer = $location.search().utm_source || document.referrer;

    $rootScope.ifr = []; // in flight $http requests
    $rootScope.requestLog = [];

    $rootScope.$ui = {};

    $rootScope.$go = function (where, payload) {
      $state.go(where, payload);
    };
    $rootScope.$focus = function (selector, within, wait) {
      // visual cleanup
      $('.keyboard-selected').removeClass('keyboard-selected');

      $timeout(function () {
        var res = angular.element(selector, within).visible().focus();
        $log.debug('[$focus] requested for', selector, within, res);
      }, wait || 100);
      return true;
    };

    // see isFeatureEnabled in FeatureFlagsApi.js - returns boolean or Promise<boolean>
    $rootScope.isFeatureFlagEnabled = FeatureFlagsApi.isFeatureEnabled;

    $rootScope.$on(AUTH_EVENTS.loginSuccess, function () {
      refreshFeatureFlags();
    });

    $rootScope.$on(FEATURE_EVENTS.refreshFeatures, function () {
      refreshFeatureFlags();
    });

    $rootScope.$on(AUTH_EVENTS.loginSuccess, async () => {
      const userRoles = _.get(Session, 'user.roles', []);
      const isScwAdmin = userRoles.includes(USER_ROLES.admin);
      const isCompanyAdmin = userRoles.includes(USER_ROLES.companyAdmin);
      const isOutsideCompanyCheck = AuthService.isStandaloneDeveloper() || AuthService.isStandaloneTeam() || isScwAdmin;

      $rootScope.isAssessmentAllowedByCustomerPlan =
        isOutsideCompanyCheck || (await CustomerPlanApi.isAllowed('assessment'));
      $rootScope.isUserManagementApiAllowedByCustomerPlan =
        isOutsideCompanyCheck || (await CustomerPlanApi.isAllowed('userManagementApi'));
      $rootScope.isTrainingAllowedByCustomerPlan =
        isOutsideCompanyCheck || (await CustomerPlanApi.isAllowed('training')); // next request would be from cache
      $rootScope.isReportingApiAllowedByCustomerPlan =
        isOutsideCompanyCheck || (await CustomerPlanApi.isAllowed('reportingApi'));
      $rootScope.isAnonymisationAllowedByCustomerPlan =
        isOutsideCompanyCheck || (await CustomerPlanApi.isAllowed('anonymisation'));

      const companyId = Session.user?.properties?.company?._id;
      await checkIfSuccessHubIsEnabled(companyId, isCompanyAdmin);
    });

    async function checkIfSuccessHubIsEnabled(companyId, isCompanyAdmin) {
      if (companyId && isCompanyAdmin) {
        try {
          $rootScope.isSuccessHubEnabled = (await AdminApiService.getSuccessHub(companyId, false)).enabled;
        } catch (e) {
          $log.error('Success Hub error', e);
        }
      } else {
        $rootScope.isSuccessHubEnabled = false;
      }
      $rootScope.$apply();
    }

    $rootScope.toggleKeyboardHelp = function toggleKeyboardHelp(_event) {
      const body = $('body');
      const isEnabled = body.hasClass('keyboard-navigation-help-enabled');
      body[(isEnabled && 'removeClass') || 'addClass']('keyboard-navigation-help-enabled');
    };

    $rootScope.keyboardNavigationHelp = function keyboardNavigationHelp(_event) {
      $swal({
        title: $translate.instant('KeyboardNavigation.Help.GeneralBehavior.Explanation.title'),
        templateUrl: keyboardNavigationTemplate,
        scope: $rootScope.$new(),
        keyResolution: true,
      }).then($swal.close);
    };

    $rootScope.$tabControl = function (selector, within, direction) {
      var element = angular.element(selector, within);
      var scope = element.isolateScope();
      $log.debug('[tabControl] got scope for element', scope, element);
      var tabset = scope.tabset;
      var curr = tabset.active || 0;
      var inc = (typeof direction === 'number' && direction) || 1 * ((direction === 'next' && 1) || -1);
      curr = curr + inc;
      if (curr < 0) curr = 0;
      if (curr > tabset.tabs.length - 1) curr = tabset.tabs.length - 1;

      tabset.active = curr;

      $timeout(magicFocus, 1);
      var _activePane = element.find('.tab-pane.active');
      if (_activePane && _activePane.once) {
        _activePane.once('DOMSubtreeModified', magicFocus);
      }

      function magicFocus() {
        element.find('.tab-pane.active [keyboard-navigation]').first().trigger('focus');
      }
    };

    $rootScope.setZendeskSearchKeyword = function (word) {
      if (!window.zE) return;
      zE('webWidget', 'helpCenter:setSuggestions', { search: word });
    };

    $rootScope.showZendesk = () => {
      if (!window.zE) return;

      zE('webWidget', 'show');
      zE('webWidget', 'open');
    };

    $rootScope.showChurnzeroPanel = function () {
      $window.ChurnZero.push(['open']);
    };

    function refreshFeatureFlags() {
      const userRoles = _.get(Session, 'user.roles', []);
      const isCompanyAdmin = userRoles.includes(USER_ROLES.companyAdmin);

      // each feature flag value will be stored under $rootScope using its key from this object:
      const features = {
        levelGroupingFeatureEnabled: FeatureFlags.LEVEL_GROUPING,
        commsCentreFeatureEnabled: FeatureFlags.COMMS_CENTRE,
        commsCentreQuillFeatureEnabled: FeatureFlags.COMMS_CENTRE_QUILL,
        isCybermonEnabled: FeatureFlags.CYBERMON,
        ...(isCompanyAdmin ? { isGoalsAdminEnabled: FeatureFlags.GOALS_ADMIN } : {}),
      };
      const promises = Object.entries(features).map(([scopeKey, flagName]) =>
        $rootScope.isFeatureFlagEnabled(flagName).then((flagValue) => {
          $rootScope[scopeKey] = typeof flagValue === 'boolean' ? flagValue : false;
        })
      );
      // Trigger a digest cycle to ensure AngularJS uses the new values
      Promise.all(promises).then(() => {
        $rootScope.$apply();
      });
    }

    function getDefaultUserSnapData() {
      const userSnapData = {
        browserLanguages: window.navigator.languages,
        requestLog: $rootScope.requestLog || 'Request log not available',
        parentUrl: window.location.href,
      };

      const Session = $rootScope.Session;
      const { user, customerPlan } = generateUserAndPlanPropertiesForUsersnap(Session?.user);

      userSnapData.user = user;
      userSnapData.customerPlan = customerPlan;
      return userSnapData;
    }

    function getMissionUserSnapData() {
      const userSnapData = { ...getDefaultUserSnapData(), isMission: true };
      setMissionChallengeIdIfApplicable(userSnapData);
      setMissionCourseChallengeIdIfApplicable(userSnapData);
      addExploreOrQuestMissionDataToUserSnapData(userSnapData);
      return userSnapData;
    }

    // Temp solution to handle BCP47Tags from the videoplayer, will be removed with PLAT-12190
    const bcp47LanguageTagsToPlatformLanguageTags = {
      'en-US': 'en',
      'en-GB': 'en-gb',
      'es-ES': 'es',
      'fr-FR': 'fr',
      'de-DE': 'de',
      'ko-KR': 'ko',
      'zh-CN': 'zh',
      'zh-TW': 'zh-tw',
      'ja-JP': 'ja',
    };

    function onIframeMessage(e) {
      if (e.data.event === messages.CONTACT_SUPPORT) {
        $rootScope.showUsersnap();
      }
      if (e.data.event === messages.MISSIONS_USERSNAP_CLOSED) {
        setUsersnapDisplayingState(false);
      }
      if (e.data.event === messages.CHALLENGEPLAYER_USERSNAP_CLOSED) {
        setUsersnapDisplayingState(false);
      }
      if (e.data.event === messages.LIVECODINGLABS_USERSNAP_CLOSED) {
        setUsersnapDisplayingState(false);
      }
      if (e.data.type === messages.PROFILE_COMPLETED) {
        userUpdate().then(() => $rootScope.$applyAsync());
      }
      if (e.data.event === messages.VIDEO_PREFERENCES_CHANGED) {
        changeLocalVideoPreferences(e.data.preferences);
      }
      if (e.data.event === messages.PROFILE_CHANGED) {
        if (!Session || !Session.user) return;

        // Temp solution to handle BCP47Tags from the videoplayer, will be removed with PLAT-12190
        if (e.data.changes?.subtitlesLanguage === null) e.data.changes.subtitlesLanguage = 'none';

        if (e.data.changes.subtitlesLanguage in bcp47LanguageTagsToPlatformLanguageTags)
          e.data.changes.subtitlesLanguage = bcp47LanguageTagsToPlatformLanguageTags[e.data.changes.subtitlesLanguage];
        // end of temp solution to map BCP47 tags

        const changedProfile = { ...Session.user.properties.profile, ...e.data.changes };

        AuthService.updateProfile({
          email: Session.user.email,
          profile: changedProfile,
        })
          .then((response) => {
            Session.user.properties.profile = response.profile;
          })
          .catch((e) => {
            $log.error('Failed to update user profile', e);
          });
      }

      if (e.data.event === messages.VIDEO_WATCHED && AuthService.isAuthenticated()) {
        const { videoId, duration, playmode } = e.data;
        LearningresourcesApiService.createLogRecord(videoId, duration, playmode).catch(function (response) {
          $translate(['ERROR_CREATING_LOG_RECORD']).then(function (translations) {
            ErrorHandler.addHttpError(translations.ERROR_CREATING_LOG_RECORD, response);
          });
        });
      }
    }

    window.addEventListener('message', onIframeMessage);

    function getIframeWindowByNameOrId(nameOrId) {
      // With window.frames (and window, because window.frames === window), iframes can be accessed by their name or id.
      // Name gets precedence. If name matches, the value is a Window. If id matches, value is a HTMLIFrameElement
      let frameWindow = window.frames[nameOrId];
      let parentWindow = window;
      if (!frameWindow) {
        // maybe the wanted iframe is wrapped in the courses iframe
        const courseIframe = window.frames[iframes.COURSES];
        if (courseIframe) {
          parentWindow = courseIframe;
          frameWindow = courseIframe.frames?.[nameOrId];
        }
      }
      // If the iframe didn't have a name but id set (like coding-labs), frameWindow will be the iframe element instead of window object.
      // We still need to get the window object, but checking if `contentWindow` is present on the frameWindow resuls
      // in crashes due to CORS errors. Checking 'frameWindow instanceof HTMLIFrameElement' also doesn't work because instanceof is funky,
      // checking 'frameWindow.constructor.name === "HTMLIFrameElement"' also results in CORS errors. So the best way to check if we have
      // an HTMLIFrameElement instead of window object is to see if it's equal to the result of getElementById.
      if (parentWindow.document.getElementById(nameOrId) === frameWindow) {
        frameWindow = frameWindow.contentWindow;
      }
      return frameWindow;
    }

    $rootScope.showUsersnap = function () {
      let missionIframe = getIframeWindowByNameOrId(iframes.MISSIONS);
      if (missionIframe) {
        $rootScope.usersnapDisplaying = true; // Do not use the setUsersnapDisplayingState function here, will trigger apply error
        const userSnapData = getMissionUserSnapData();
        missionIframe.postMessage({ event: messages.MISSIONS_USERSNAP_OPEN, userSnapData }, '*');
      }

      let challengeIframe = getIframeWindowByNameOrId(iframes.CHALLENGEPLAYER);
      if (challengeIframe) {
        $rootScope.usersnapDisplaying = true; // Do not use the setUsersnapDisplayingState function here, will trigger apply error
        const userSnapData = getDefaultUserSnapData();
        challengeIframe.postMessage({ event: messages.CHALLENGEPLAYER_USERSNAP_OPEN, userSnapData }, '*');
      }

      let lessonIframe = getIframeWindowByNameOrId(iframes.LIVECODINGLABS);
      if (lessonIframe) {
        $rootScope.usersnapDisplaying = true; // Do not use the setUsersnapDisplayingState function here, will trigger apply error
        const userSnapData = getDefaultUserSnapData();
        lessonIframe.postMessage({ event: messages.LIVECODINGLABS_USERSNAP_OPEN, userSnapData }, '*');
      }

      if (!missionIframe && !challengeIframe && !lessonIframe && $window.Usersnap) {
        $timeout(function () {
          $window.Usersnap.logEvent($window.SCW_ENV.USERSNAP_PROJECT);
        }, 0);
      }
    };

    $rootScope.showTutorial = function () {
      $timeout(function () {
        $('.tutorial-button').click();
      }, 0);
    };

    $rootScope.$on('$viewContentLoaded', function () {
      $timeout(function () {
        $rootScope.hasTutorial = !!document.getElementsByClassName('tutorial-button')[0];
        if (
          $window.ChurnZero &&
          $window.ChurnZero._successPanel &&
          $window.ChurnZero._successPanel.previousUnreadCount
        ) {
          $rootScope.churnZeroAlerts = $window.ChurnZero._successPanel.previousUnreadCount;
        }
      }, 500);
    });

    $rootScope.$back = function ($event) {
      $event && $event.stopPropagation();
      $event && $event.stopDefault();
    };

    $rootScope.focusTopMenu = function () {
      $('header .ScwAppBarButton-root').first().trigger('focus');
    };

    $rootScope.$on('$stateChangeStart', function (_event, next, nextParams, from, fromParams) {
      $log.debug('statechange on root: ', next, nextParams);
      if (nextParams.flow === 'bounce') {
        nextParams.flow = [from, fromParams];
      }
    });

    if ($window.localStorage) {
      try {
        $rootScope.requestedState = $window.localStorage.requestedState
          ? JSON.parse($window.localStorage.requestedState)
          : null;
      } catch (exc) {
        $rootScope.requestedState = null;
        delete $window.localStorage.requestedState;
      }

      try {
        $rootScope.requestedStateParams = $window.localStorage.requestedStateParams
          ? JSON.parse($window.localStorage.requestedStateParams)
          : null;
      } catch (exc) {
        $rootScope.requestedStateParams = null;
        delete $window.localStorage.requestedStateParams;
      }

      $rootScope.$watch('requestedState', function (newVal) {
        if (newVal === null) {
          delete $window.localStorage.requestedState;
        } else {
          $window.localStorage.requestedState = JSON.stringify(newVal);
        }
      });
      $rootScope.$watch('requestedStateParams', function (newVal) {
        if (newVal === null) {
          delete $window.localStorage.requestedStateParams;
        } else {
          $window.localStorage.requestedStateParams = JSON.stringify(newVal);
        }
      });
    }

    $rootScope.showBsod = false;
    $rootScope.displayBsod = function () {
      $rootScope.maintenance = {
        start: moment($window.SCW_ENV.MAINTENANCE_WINDOW.start).format('LLL'),
        end: moment($window.SCW_ENV.MAINTENANCE_WINDOW.end).format('LLL'),
        message: $window.SCW_ENV.MAINTENANCE_WINDOW.message,
      };
      $rootScope.showBsod = true;
    };
    $rootScope.closeBsod = function () {
      $rootScope.showBsod = false;
    };

    $rootScope.cancelCheckInterval = function () {
      if ($rootScope.checkIntervalPromise) {
        $interval.cancel($rootScope.checkIntervalPromise);
        delete $rootScope.checkIntervalPromise;
      }
    };

    $rootScope.startCheckInterval = function (checkNow) {
      if (checkNow) {
        check();
      }
      var INTERVAL = 5 * 60 * 1000; // 5 minutes
      $rootScope.cancelCheckInterval();
      $rootScope.checkIntervalPromise = $interval(check, INTERVAL);
    };

    function userUpdate() {
      return AuthService.refreshUser().then(function (data) {
        Session.updateUser(data);
      });
    }

    $rootScope.userUpdate = userUpdate;

    $rootScope.startUserUpdateInterval = function () {
      var INTERVAL = 10 * 60 * 1000; // 10 minutes
      $rootScope.cancelUserUpdateInterval();
      $rootScope.userUpdateIntervalPromise = $interval(userUpdate, INTERVAL);
    };

    $rootScope.cancelUserUpdateInterval = function () {
      if ($rootScope.userUpdateIntervalPromise) {
        $interval.cancel($rootScope.userUpdateIntervalPromise);
        delete $rootScope.userUpdateIntervalPromise;
      }
    };

    // health and version check
    $rootScope.haveCheckedMaintenanceWindow = false;

    function check() {
      $http
        .get($window.SCW_ENV.ApiEndpoint + '/ping', HttpConfigService.getHttpConfigNoIFR())
        .then(function (response) {
          $log.debug('Success: ', response);
          $window.SCW_ENV.SERVER_API_VER = response.data && response.data.app && response.data.app.version;
          $window.SCW_ENV.SERVER_ENV = response.data && response.data.app && response.data.app.env;

          // check API version
          if (
            response.data &&
            response.data.app &&
            response.data.app.version &&
            versionCheck(response.data.app.version) &&
            response.data.healthy
          ) {
            $log.debug('Supported API version ' + response.data.app.version + ' confirmed');
          } else {
            $log.debug('Unsupported API version ' + response.data.app.version + ' detected');
            $state.go('unsupported-api');
            return;
          }

          // check for upcoming maintenance
          var now = Date.now();
          if (
            !$rootScope.haveCheckedMaintenanceWindow &&
            now >= $window.SCW_ENV.MAINTENANCE_WINDOW.notifyStart &&
            now <= $window.SCW_ENV.MAINTENANCE_WINDOW.end
          ) {
            $rootScope.haveCheckedMaintenanceWindow = true;
            $rootScope.displayBsod();
          }
        })
        .catch(function (response) {
          if (
            (response.status === 401 || response.status === 403) &&
            response.data &&
            response.data.details &&
            response.data.details.sessionTimeout
          ) {
            $rootScope.loginFlashMesage = response.data.error ? response.data.error : '';
            Session.destroy();
            $state.go('login');
          } else {
            $log.debug('Error: ', response);
            $rootScope.requestedState = $state.current.name;
            $rootScope.requestedStateParams = $stateParams;
            $state.go('unavailable');
          }
        });
    }

    $rootScope.$on('$destroy', function () {
      $window.CancelLmsWatchdogInterval;
      $rootScope.cancelCheckInterval();
      $rootScope.cancelUserUpdateInterval();
      window.removeEventListener('message', onIframeMessage);
    });

    // start ChurnZero
    $window.ChurnZeroStart = function (accountExternalId, contactExternalId, roles) {
      if (!$window.ChurnZero) return;

      $window.ChurnZero.push(['setAppKey', $window.SCW_ENV.CHURNZERO_ID]);

      // Tried this with 'null' as the second argument for contactExternalId which did not work as expected
      $window.ChurnZero.push(['setContact', accountExternalId, contactExternalId]);
      $window.ChurnZero.push(['setAttribute', 'contact', 'Roles', roles]);
    };

    $window.ChurnZeroShutdown = function () {
      if (!$window.ChurnZero) return;

      $window.ChurnZero.push(['stop']);
    };
    /**
     * ChurnZero - Attribute wrapper
     * @OBJECT -
     * {
     *		entity: "", //REQUIRED
     *		name: "", //REQUIRED
     *		value: "", //REQUIRED
     * }
     *
     * @OBJECT -
     * {
     *		entity: "", //REQUIRED
     *		attributes: "", //REQUIRED e.g. {'Email Enabled': true, 'Emails Sent': 100 }
     * }

     *
     */

    $window.ChurnZeroAttribute = function (obj) {
      if (!$window.ChurnZero) return;
      try {
        if (Object.keys(obj).length < 2) {
          throw new Error(
            '[ChurnZero] - "setAttribute": insufficient number of arguments, require atleast 2 arguments.'
          );
        }

        $log.debug('[ChurnZero] - "setAttribute": ', obj);
        if ($window.SCW_ENV.CHURNZERO_ON && Object.keys(obj).length === 2)
          $window.ChurnZero.push(['setAttribute', obj.entity, obj.attributes]);

        if ($window.SCW_ENV.CHURNZERO_ON && Object.keys(obj).length === 3)
          $window.ChurnZero.push(['setAttribute', obj.entity, obj.name, obj.value]);
      } catch (e) {
        $log.debug(e.message);
      }
    };

    /**
     * ChurnZero - Event wrapper
     * @OBJECT -
     * {
     *		eventName: "", //REQUIRED
     *		description: "", //OPTIONAL
     *		quantity: "", //OPTIONAL
     *		customfields: {} //OPTIONAL
     * }
     *
     */
    $window.ChurnZeroEvent = function (obj) {
      if (!$window.ChurnZero) return;
      try {
        if (Object.keys(obj).length < 1) {
          throw new Error('[ChurnZero] - "trackEvent": insufficient number of arguments, require atleast 1 argument.');
        }

        obj.description = obj.description || '';
        obj.quantity = obj.quantity || '';
        obj.customfields = obj.customfields || {};

        $log.debug('[ChurnZero] - "trackEvent": ', obj);
        if ($window.SCW_ENV.CHURNZERO_ON)
          $window.ChurnZero.push(['trackEvent', obj.eventName, obj.description, obj.quantity, obj.customfields]);
      } catch (e) {
        $log.debug(e.message);
      }
    };

    // end ChurnZero

    $rootScope.metadata = metadata; // from metadata/metadata.js

    $rootScope.getLocaleMetadata = function (langKey) {
      return MetadataApiService.GetLocaleMetadata(langKey)
        .then(function (data) {
          PlatformLanguagesService.addStats(data.languages);
          $rootScope.metadata = data;
          window.metadata = data;
          //$rootScope.userSnapInit(); // PORTAL-1989
          if (!$rootScope.$$phase) $rootScope.$apply();
        })
        .catch(function (response) {
          $translate(['UNABLE_TO_LOAD_LANGUAGE_FILE']).then(function (translations) {
            ErrorHandler.addHttpError(translations.UNABLE_TO_LOAD_LANGUAGE_FILE, response);
          });
        });
    };

    $rootScope.gameId = '';
    $rootScope.pagerPrefs = {};
    $rootScope.pagerPrefs.itemsPerPage = 10;
    $rootScope.pagerMaxSize = 10;

    // save items per page setting on update
    $rootScope.$watch('pagerPrefs.itemsPerPage', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        UserPreferences.save('pagination.itemsPerPage', newVal);
      }
    });

    // central place to update any rootScope preferences we might have
    $rootScope.updateRootPrefs = function () {
      $rootScope.pagerPrefs.itemsPerPage = UserPreferences.get('pagination.itemsPerPage', 10);
    };

    $rootScope.buttonActive = {};
    $rootScope.enableButton = function (name) {
      $rootScope.buttonActive[name] = false;
    };
    $rootScope.disableButton = function (name) {
      $rootScope.buttonActive[name] = true;
    };

    $rootScope.reloadPage = () => {
      location.reload();
    };

    $rootScope.showSimpleFlowSignupModalFromNavbar = function () {
      $uibModal
        .open({
          templateUrl: signupModalTemplate,
          windowClass: 'simple-flow-modal-z-index-hack',
          controller: 'SignupModalController',
          size: 'lg',
          resolve: {
            translations: {
              modalTitle: 'SIMPLE_FLOW_SIGNUP_MODAL_TITLE_DURING_CHALLENGE',
              modalIntroText: 'SIMPLE_FLOW_SIGNUP_MODAL_INTRO_TEXT_DURING_CHALLENGE',
            },
            sourcePage: function () {
              return 'teaser challenge';
            },
          },
        })
        .result.catch(angular.noop);
    };
    // cancel in flight $http requests on state change
    $rootScope.$on('$stateChangeSuccess', function (_event, next, _nextParams, from, fromParams) {
      if (from.name && !$state.includes(from.name)) {
        // if not coming from empty/initial state or going to child state
        $rootScope.loading = 0;
        for (var i = 0; i < $rootScope.ifr.length; i++) {
          if (!$rootScope.ifr[i].isRetrying) {
            var httpCancel = $rootScope.ifr[i].canceller;
            httpCancel.resolve('Abort $http');
          }
        }
        $rootScope.ifr = [];
        $log.debug('Setting previous state to: ', from);

        if (!next.abstract) {
          if (!from || from.abstract || !from.name) {
            if ($window && $window.localStorage && $window.localStorage.previousState) {
              try {
                from = JSON.parse($window.localStorage.previousState) || {};
              } catch (e) {
                from = {};
              }
            }
          }
          from.params = fromParams || from.params;
          $rootScope.previousState = from || {};

          if ($window.localStorage)
            //:
            $window.localStorage.previousState = JSON.stringify(from);
        }
      }

      $($window).scrollTop(0);
      $rootScope.appLoaded = true;

      updatePageTitleAndDescription($window, $state, $translate);

      // AppBar fix - iframe
      $timeout(function () {
        const iframes = document.querySelectorAll('iframe');
        const appBar = document.querySelector('.ScwAppBar');

        if (appBar && iframes.length > 0) {
          iframes.forEach(function (iframe) {
            iframe?.contentDocument?.body?.addEventListener(
              'click',
              function () {
                appBar?.click();
              },
              true
            );
          });
        }
      }, 1000);
    });

    $window.onbeforeunload = function () {
      $log.debug('destroying rootscope');
      $rootScope.loading = 0;
      for (var i = 0; i < $rootScope.ifr.length; i++) {
        var httpCancel = $rootScope.ifr[i].canceller;
        httpCancel.resolve('Abort $http');
      }
    };

    // add state tracking analytics
    $rootScope.analyticsHandlers = [];
    $rootScope.$on('$stateChangeSuccess', function (_event, next, _nextParams, _from, _fromParams) {
      $timeout(function () {
        $rootScope.routeParts = $state.$current.includes;

        // send state change to analytics
        var state = next.name;
        $analytics.eventTrack('state-change', {
          state: state,
          label: state,
        });
      });
    });

    $rootScope.stringEndsWith = function (str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    };

    $rootScope.stateIncludes = $state.includes;
    $rootScope.routeParts = $state.$current.includes;

    $rootScope.changeLanguage = function (langKey) {
      $translate.fallbackLanguage(['en']);

      //sets locale for date and time
      $rootScope.changeLocale = tmhDynamicLocale.set(langKey);

      //sets language for date time formatting through moments.
      moment.locale(langKey);

      $translate.use(langKey);
      if (!$rootScope.$$phase) $rootScope.$apply();

      i18n.changeLanguage(platformLocaleToBcp47(langKey));

      //$rootScope.userSnapInit(); // PORTAL-1989

      $rootScope.$broadcast('changeLanguage', { lang: langKey });
    };

    $rootScope.changeLanguageFramework = function () {
      $rootScope.$broadcast('changeLanguageFramework');
    };

    $rootScope.floor = function (n) {
      return $window.Math.floor(n);
    };

    $rootScope.showAnnoyingRedDot = function () {
      var userRoles = _.get(Session, 'user.roles', []);
      var preferredDevLanguages = _.get(Session, 'user.properties.profile.preferredDevLanguages', []);

      return (
        preferredDevLanguages.length === 0 &&
        userRoles.indexOf(USER_ROLES.player) > -1 &&
        userRoles.indexOf(USER_ROLES.manager) === -1 &&
        userRoles.indexOf(USER_ROLES.companyAdmin) === -1
      );
    };

    $rootScope.configureChurnZero = function (user) {
      const vleId = _.get(user, 'properties.company.vleId');
      if (vleId) {
        // CHURNZERO - Start
        $window.ChurnZeroStart(vleId, user.email, user.roles.join(', '));

        $timeout(function () {
          $rootScope.hasTutorial = !!document.getElementsByClassName('tutorial-button')[0];
          if ($window.ChurnZero?._successPanel?.previousUnreadCount) {
            $rootScope.churnZeroAlerts = $window.ChurnZero._successPanel.previousUnreadCount;
          }
        }, 500);
        //--- end CHURNZERO
      }
    };

    $rootScope.configureZendesk = function (user) {
      // If support app disabled do not proceed further
      const supportAppStatus = _.get(user, 'properties.preferences.integrations.supportApp.enabled');

      if (supportAppStatus !== undefined && !supportAppStatus) {
        $rootScope.hideSupportApp = true;
        return;
      }

      $rootScope.hideSupportApp = typeof zE == 'undefined';

      if (typeof zE !== 'undefined' && user.properties && user.properties.profile) {
        const { first, last } = user.properties.profile.name;

        zE('webWidget', 'identify', {
          name: `${first} ${last}`,
          email: user.email,
        });

        if (user.properties.profile.i18nLanguagePreference) {
          zE('webWidget', 'setLocale', user.properties.profile.i18nLanguagePreference);
        }
      }
    };

    $rootScope.clearZendesk = function () {
      if (typeof zE !== 'undefined') {
        zE('webWidget', 'logout');
      }
    };

    /**
     * A helper function for angularJS forms. It cannot be used for all the forms. Needs to
     * follow a pattern of form name and a way to determine which form controls have an error.
     * Usually done via the control being dirty and being marked as invalid based on error criteria.
     * @param form
     */
    $rootScope.assignFocusToFirstAngularJSFormInputWithAnError = function (form) {
      // https://docs.angularjs.org/api/ng/type/form.FormController#$getControls
      const formControls = form.$getControls().map((control) => control.$name);
      const element = formControls.filter(Boolean).find((type) => form[type].$dirty && !form[type].$valid);

      if (element) {
        document.getElementById(element).focus();
      }
    };

    function setUsersnapDisplayingState(displaying) {
      $rootScope.usersnapDisplaying = displaying;
      $rootScope.$apply();
    }

    //usersnap feedback button
    function initUserSnap() {
      if ($window.UserSnap) $window.UserSnap.destroy();
      $('#us_report_button_description').remove();
      $('#us_report_button').remove();

      var addInfo = {};
      var Session = $rootScope.Session;

      $translate(['EMAIL_ADDRESS']).then(function (translations) {
        window.onUsersnapCXLoad = function (api) {
          api.init({
            user: {
              email: $rootScope.Session?.user?.email || translations.EMAIL_ADDRESS,
            },
            nativeScreenshot: true,
          });

          api.on('open', function (event) {
            setUsersnapDisplayingState(true);
            var email = _.get(Session, 'user.email', translations.EMAIL_ADDRESS);
            event.api.setValue('visitor', email);

            const { user, customerPlan } = generateUserAndPlanPropertiesForUsersnap(Session?.user);

            addInfo.customerPlan = customerPlan;
            addInfo.user = user;

            addInfo = {
              user: user,
              requestLog: $rootScope.requestLog || 'Request log not available',
              browserLanguages: window.navigator.languages,
            };

            // let the challenge component fill it's own additional information
            $rootScope.$emit('usersnap:feedback-info', addInfo);

            // needed to wait until challenge:feedback-info loaded .logId
            addInfo.logUrl = addInfo.logId
              ? location.origin + '/#/admin/user/' + addInfo.user._id + '?gameLogFilter=' + addInfo.logId
              : 'Log URL not available';
            event.api.setValue('custom', addInfo);
          });

          api.on('error', function (event) {
            setUsersnapDisplayingState(false);
            $log.debug('Error Type: ' + event.type);
            $log.debug('Error Message: ' + event.message.en);
            $log.debug('Event Object: ' + JSON.stringify(event, null, 2));
          });

          api.on('close', function (_event) {
            setUsersnapDisplayingState(false);
          });

          window.Usersnap = api;
        };

        var script = document.createElement('script');
        script.defer = 1;
        script.src = window.SCW_ENV.USERSNAP_SCRIPT_SRC;
        document.getElementsByTagName('head')[0].appendChild(script);
      });
    }

    //usersnap feedback button - Preprocessing
    (function initUserSnapPre() {
      $rootScope.hideUserSnapApp = false;

      var maxRetries = 60;
      var retry = 0;
      // Make sure Session.user is valid before calling initUserSnap every 500ms for a maximum of 60 retries
      var checkInterval = $interval(function () {
        if ($rootScope.Session.user || retry >= maxRetries) {
          initUserSnap();
          $interval.cancel(checkInterval);
        }
        retry++;
      }, 0);
    })();

    // Set default fonts for ChartJS
    Chart.defaults.global.defaultFontFamily = '"Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif';

    // adding in a keydown event to detect which key had been pressed in the window space
    document.addEventListener('keydown', function (event) {
      // only trigger this block if challenge is in view and challenge containers are focused
      if (
        event.keyCode === 192 &&
        angular.element(document).find('challenge').length > 0 &&
        $(':focus').closest('challenge').length > 0
      ) {
        $window._codeview.$emit('challenge:toggle-diff');
      }
    });

    // React Communication Bridge Methods and Variables
    // ------------------------------------------------
    $rootScope.AngularToReactBridge = {
      $rootScope: {
        AnalyticsService: AnalyticsService,
        Session: $rootScope.Session,
        userRoles: $rootScope.userRoles,
        isLMSInitialized: $rootScope.isLMSInitialized,
        isAuthorized: $rootScope.isAuthorized,
        isAuthenticated: $rootScope.isAuthenticated,
        hasCookiePolicy: $rootScope.hasCookiePolicy,
        isFeatureEnabled: $rootScope.isFeatureEnabled,
        // Is a feature enabled? (eg 'training', 'home', 'tournaments', 'courses' etc. See also ELicenseFeature)
        // Returns a boolean.
        isFeatureUnlimited: $rootScope.isFeatureUnlimited,
        // Is a LaunchDarkly feature enabled? Returns a Promise (or a boolean).
        isFeatureFlagEnabled: $rootScope.isFeatureFlagEnabled,
        isStandaloneDeveloper: $rootScope.isStandaloneDeveloper,
        isStandaloneTeam: $rootScope.isStandaloneTeam,
        isSSOUser: $rootScope.isSSOUser,
        isStrictSso: $rootScope.isStrictSso,
        hasTournamentAccess: $rootScope.hasTournamentAccess,
        hasDevlympicsAccess: $rootScope.hasDevlympicsAccess,
        loading: $rootScope.loading,
        lmsClose: $rootScope.lmsClose,
        currentLoadingMessage: $rootScope.currentLoadingMessage,
        showSimpleFlowSignupModalFromNavbar: $rootScope.showSimpleFlowSignupModalFromNavbar,
        showTutorial: $rootScope.showTutorial,
        showUsersnap: $rootScope.showUsersnap,
        showZendesk: $rootScope.showZendesk,
        setZendeskSearchKeyword: $rootScope.setZendeskSearchKeyword,
      },
      $translate,
      $state,
      $stateParams,
    };

    $rootScope.getBackgroundColor = () => {
      if (!$rootScope.showBackground) {
        return 'transparent';
      }

      return $rootScope.showBackground === 'wanda'
        ? $rootScope.theme.palette.container.fill.body
        : $rootScope.theme.palette.background.default;
    };

    const setPageBackground = () => {
      const url = $location.url();
      const user = $rootScope.Session?.user;
      const isPlayer = user && mapDesignSystemUser(user).role === USER_ROLES.player;
      if (
        (url.startsWith('/assessments/list') && isPlayer) ||
        (url.startsWith('/assessments/view') && (isPlayer || $stateParams.ownResult)) // ownResult is true when someone (can be team manager or company admin) plays an assessment via programs
      ) {
        $rootScope.showBackground = true; // pre-wanda design system background
      } else {
        $rootScope.showBackground =
          url.startsWith('/explore') ||
          url.startsWith('/quests') ||
          url.startsWith('/admin/goals') ||
          url.startsWith('/success-hub') ||
          url.startsWith('/unsubscribe') ||
          url.startsWith('/communications') ||
          url.startsWith('/cybermon')
            ? 'wanda'
            : false;
      }
    };
    $rootScope.$watch('Session.user', setPageBackground);

    $rootScope.$on('$locationChangeStart', (_event, /** @type {string} */ next, _curr) => {
      const nextSplit = next.split('/#/', 2);

      // This is currently only affecting the background color of pages that are used in setPageBackground()
      $rootScope.palette = decideThemeByBareRoute(nextSplit[1] || '');
      $rootScope.theme = generateTheme($rootScope.palette);
      setPageBackground();
    });

    const isAuthenticated = () => !!Session.user && $rootScope.isAuthenticated();
    const isHeaderHidden = () =>
      'unsupported-api' in $rootScope.routeParts ||
      'product-trial-signup' in $rootScope.routeParts ||
      'picker' in $rootScope.routeParts ||
      'simple-flow.languages' in $rootScope.routeParts ||
      'sensei-auth' in $rootScope.routeParts ||
      ('new-simple-flow' in $rootScope.routeParts && !isAuthenticated()) ||
      (('new-simple-flow' in $rootScope.routeParts || 'microlearning' in $rootScope.routeParts) &&
        Session.isAnonymous());

    const updateHeaderHidden = () => ($rootScope.isHeaderHidden = isHeaderHidden());

    updateHeaderHidden();
    $rootScope.$watch('routeParts', updateHeaderHidden);
    $rootScope.$watch('Session', updateHeaderHidden, true);

    // ------------------------------------------------

    // force initial state load to trigger pageview event to GA
    $timeout(function () {
      var path = $window.location.pathname;
      var hash = $window.location.hash;
      var search = $window.location.search;
      $window.history.replaceState(null, '', path + search + hash); // Including 'search' to keep the UTM parameters as I observed they were getting removed from the URL
    }, 2000);
  },
]);

app.config([
  '$httpProvider',
  '$analyticsProvider',
  function ($httpProvider, $analyticsProvider) {
    // Allow cookies, required for cloudfront signed policies
    // $httpProvider.defaults.withCredentials = true;

    // for intercepting HTTP responses and broadcasting events, also for retrying HTTP requests
    $httpProvider.interceptors.push([
      '$injector',
      function ($injector) {
        return $injector.get('AuthInterceptor');
      },
    ]);

    // for logging HTTP requests
    $httpProvider.interceptors.push([
      '$injector',
      function ($injector) {
        return $injector.get('RequestLogger');
      },
    ]);

    // for detecting frontend & backend version mismatch
    $httpProvider.interceptors.push([
      '$injector',
      function ($injector) {
        return $injector.get('VersionCheckInterceptor');
      },
    ]);

    // Disable automatic tracking since it doesn't work properly with states. We will do it manually.
    $analyticsProvider.virtualPageviews(true);
    //$analyticsProvider.trackStates(false);
    //$analyticsProvider.trackRoutes(false);
  },
]);

// request logging
app.factory('RequestLogger', [
  '$rootScope',
  '$q',
  function ($rootScope, $q) {
    return {
      response: function (response) {
        if ($rootScope.requestLog.length >= 10) {
          $rootScope.requestLog.shift();
        }

        $rootScope.requestLog.push({
          method: response.config.method,
          url: response.config.url,
          //data: response.config.data,
          status: response.status,
        });
        return response;
      },
      responseError: function (response) {
        if ($rootScope.requestLog.length >= 10) {
          $rootScope.requestLog.shift();
        }

        if (response && response.config) {
          $rootScope.requestLog.push({
            method: response.config.method,
            url: response.config.url,
            //data: response.config.data,
            status: response.status,
            wasCancelled:
              (response.config &&
                response.config.timeout &&
                response.config.timeout.$$state &&
                response.config.timeout.$$state.value &&
                response.config.timeout.$$state.value === 'Abort $http') ||
              false,
          });
        }
        return $q.reject(response);
      },
    };
  },
]);

app.factory('VersionCheckInterceptor', [
  '$rootScope',
  '$window',
  function ($rootScope, $window) {
    return {
      response: function (response) {
        const backendApiVersion = response?.headers('scw-git-commit');
        const frontendVersion = $window.SCW_ENV.GIT_COMMIT;
        if (frontendVersion && backendApiVersion) {
          $rootScope.versionMismatch = backendApiVersion !== frontendVersion;
        }

        $rootScope.versionMismatch = false; // PLAT-8308 Disable this red bar until a proper solution

        return response;
      },
    };
  },
]);

// request retrying
app.factory('RequestRetry', [
  '$log',
  '$q',
  '$injector',
  '$timeout',
  '$window',
  function ($log, $q, $injector, $timeout, $window) {
    var retries = 0;
    var MAX_RETRIES = 3;
    var DELAY_MS = 5000;

    function retryRequest(httpConfig) {
      return $timeout(function () {
        var $http = $injector.get('$http');
        $log.debug('Retrying $http request to ' + httpConfig.url);
        return $http(httpConfig);
      }, DELAY_MS);
    }

    return {
      responseError: function (response) {
        //$log.warn(retries, response);

        if (response.status === 502 || response.status === 503 || response.status === -1) {
          if (
            response.status === -1 &&
            response.data &&
            response.data.error &&
            response.data.error === 'HTTP request aborted'
          ) {
            // do nothing for cancelled requests
            retries = 0;
            return $q.reject(response);
          } else if (response.config.url === $window.SCW_ENV.ApiEndpoint + '/ping') {
            // do nothing for /ping request
            retries = 0;
            return $q.reject(response);
          } else {
            if (retries < MAX_RETRIES) {
              retries++;
              return retryRequest(response.config);
            } else {
              retries = 0;
              response.status = 503;
              return $q.reject(response);
            }
          }
        } else {
          retries = 0;
          return $q.reject(response);
        }
      },
    };
  },
]);

app.filter('titleCase', function () {
  return function (input) {
    if (input) {
      var words = input.split(' ');
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(' ');
    } else {
      return input;
    }
  };
});

app.service('DownloadFile', function () {
  return {
    csv: function (filename, content) {
      return this.file('text/csv', filename, content);
    },
    file: function (type, filename, content) {
      if (window.navigator.msSaveBlob) {
        // IE
        var blob = new Blob(['\uFEFF' + content], {
          type: type + ';charset=utf-8',
        });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        // yay for standards!
        var contentData = 'data:' + type + ';base64,' + btoa('\xEF\xBB\xBF' + unescape(encodeURIComponent(content)));
        var link = document.createElement('a');
        link.setAttribute('href', contentData);
        link.setAttribute('download', filename);
        document.body.appendChild(link); // Required for FF
        link.click();
      }
    },
  };
});

app.directive('numericModel', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (_scope, _element, _attr, ngModel) {
      function parseValue(val) {
        return parseInt(val, 10);
      }

      function formatValue(val) {
        return val.toString();
      }

      ngModel.$parsers.push(parseValue);
      ngModel.$formatters.push(formatValue);
    },
  };
});

app.directive('errSrc', [
  function () {
    return {
      restrict: 'A',
      scope: {
        fallbackImg: '=errSrc',
      },
      link: function (scope, element) {
        element.on('error', function () {
          element[0].src = scope.fallbackImg;
        });
      },
    };
  },
]);

app.controller('SignupModalController', [
  '$scope',
  '$uibModalInstance',
  '$translate',
  'translations',
  'NewSimpleFlowServices',
  '$window',
  '$stateParams',
  '$rootScope',
  'AnalyticsService',
  'sourcePage',
  'AnalyticsEvents',
  function (
    $scope,
    $uibModalInstance,
    $translate,
    translations,
    NewSimpleFlowServices,
    $window,
    $stateParams,
    $rootScope,
    AnalyticsService,
    sourcePage,
    AnalyticsEvents
  ) {
    $translate([
      'JOB_ROLE_STUDENT',
      'JOB_ROLE_DEVELOPER_SOFTWARE_ENGINEER',
      'JOB_ROLE_APPSEC_ENGINEER',
      'JOB_ROLE_APPSEC_MANAGER_DIRECTOR',
      'JOB_ROLE_SECURITY_ARCHITECT',
      'JOB_ROLE_DEVELOPMENT_MANAGER',
      'JOB_ROLE_SECURITY_AWARENESS',
      'JOB_ROLE_HR_LEARNING_DEVELOPMENT',
      'JOB_ROLE_RISK_COMPLIANCE',
      'JOB_ROLE_C_SUITE',
      'JOB_ROLE_OTHER',
    ]).then(function (translations) {
      $scope.availableJobRoles = {
        JOB_ROLE_STUDENT: translations.JOB_ROLE_STUDENT,
        JOB_ROLE_DEVELOPER_SOFTWARE_ENGINEER: translations.JOB_ROLE_DEVELOPER_SOFTWARE_ENGINEER,
        JOB_ROLE_APPSEC_ENGINEER: translations.JOB_ROLE_APPSEC_ENGINEER,
        JOB_ROLE_APPSEC_MANAGER_DIRECTOR: translations.JOB_ROLE_APPSEC_MANAGER_DIRECTOR,
        JOB_ROLE_SECURITY_ARCHITECT: translations.JOB_ROLE_SECURITY_ARCHITECT,
        JOB_ROLE_DEVELOPMENT_MANAGER: translations.JOB_ROLE_DEVELOPMENT_MANAGER,
        JOB_ROLE_SECURITY_AWARENESS: translations.JOB_ROLE_SECURITY_AWARENESS,
        JOB_ROLE_HR_LEARNING_DEVELOPMENT: translations.JOB_ROLE_HR_LEARNING_DEVELOPMENT,
        JOB_ROLE_RISK_COMPLIANCE: translations.JOB_ROLE_RISK_COMPLIANCE,
        JOB_ROLE_C_SUITE: translations.JOB_ROLE_C_SUITE,
        JOB_ROLE_OTHER: translations.JOB_ROLE_OTHER,
      };
    });

    $translate(['SECURE_CODING_SKILLS', 'REGULATORY_COMPLIANCE', 'RECURRING_VULNERABILITIES', 'JOB_RELEVANT']).then(
      function (translations) {
        $scope.availablePurchasingDrivers = {
          SECURE_CODING_SKILLS: translations.SECURE_CODING_SKILLS,
          REGULATORY_COMPLIANCE: translations.REGULATORY_COMPLIANCE,
          RECURRING_VULNERABILITIES: translations.RECURRING_VULNERABILITIES,
          JOB_RELEVANT: translations.JOB_RELEVANT,
        };
      }
    );

    $scope.availableCountries = _.map($rootScope.metadata.countries.all, function (country) {
      return country.name;
    }).sort();
    $scope.countryKeyMap = {};

    Object.keys(_.get($rootScope, 'metadata.countries.all')).forEach(function (key) {
      var countryName = _.get($rootScope, 'metadata.countries.all.' + key + '.name');
      if (countryName) {
        $scope.countryKeyMap[countryName] = { code: key };
      }
    });

    function validateUpdatedProfile(updatedProfile) {
      return (
        $scope.simpleFlowSignupModal.$invalid ||
        !updatedProfile.email ||
        !updatedProfile.company ||
        !updatedProfile.firstName ||
        !updatedProfile.lastName
      );
    }

    function getProfileFromLocalStorage($window) {
      var profile = {};
      if (!$window.localStorage.profile) return profile;
      try {
        profile = JSON.parse($window.localStorage.profile);
      } catch (error) {
        $window.localStorage.removeItem('profile');
      }
      return profile;
    }

    $scope.profile = getProfileFromLocalStorage($window);
    $scope.profile.subscriptionOptIn = 'no';
    $scope.translations = translations;
    $scope.save = function (updatedProfile) {
      if ($scope.simpleFlowSignupModal && validateUpdatedProfile(updatedProfile)) {
        $scope.hasSubmittedForm = true;
        return;
      }
      var profileFromLocalStorage = getProfileFromLocalStorage($window);

      updatedProfile.submitted = true;
      updatedProfile = _.merge(profileFromLocalStorage, updatedProfile);
      $window.localStorage.profile = JSON.stringify(updatedProfile);

      if (!updatedProfile.email) {
        return $uibModalInstance.close(updatedProfile);
      }

      if (!updatedProfile.company || updatedProfile.company.length < 4) {
        updatedProfile.company = 'team 1';
      }

      var profileForSignup = {
        firstName: updatedProfile.firstName,
        lastName: updatedProfile.lastName,
        teamName: updatedProfile.company,
        jobRole: updatedProfile.jobRole,
        language: {
          _id: $stateParams.language || $stateParams._language,
          _framework: $stateParams.framework || $stateParams._framework,
        },
        email: updatedProfile.email,
        location: updatedProfile.country,
        phone: updatedProfile.phoneNumber,
        purchasingDriver: updatedProfile.purchasingDriver,
        subscriptionOptIn: updatedProfile.subscriptionOptIn,
      };
      NewSimpleFlowServices.signup(profileForSignup)
        .then(function (_result) {
          swal({
            type: 'success',
            title: $translate.instant('SimpleFlow.Signup.Messages.Success.title'),
            text: $translate.instant('SimpleFlow.Signup.Messages.Success.text'),
            confirmButtonText: $translate.instant('OK'),
          });
          AnalyticsService.logEvent(AnalyticsEvents.PlayNow.SIGN_UP, { sign_up_page: sourcePage });
        })
        .catch(function (error) {
          swal({
            type: 'error',
            title: 'Error',
            text: error.data.error,
            confirmButtonText: $translate.instant('OK'),
          });
        });
      $uibModalInstance.close(updatedProfile);
    };
    $scope.cancel = function () {
      $uibModalInstance.close();
    };
  },
]);

app.factory('NewSimpleFlowServices', [
  '$log',
  '$http',
  '$window',
  'HttpConfigService',
  '$state',
  function ($log, $http, $window, HttpConfigService, $state) {
    const { SCW_ENV } = $window;
    var self = this || {};

    self.getCategoryInformation = function (type, category, subcategory) {
      var path = ['public', 'categories', type, category, subcategory];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      $log.debug('Category information endpoint is: ', endpoint);
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {});
    };

    self.getAvailableQuestsInfo = function (_type, language, framework) {
      var path = ['public', 'quests', language, framework];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {});
    };

    self.checkQuestAvailability = function (type, category, subcategory, language, framework) {
      var path = ['public', 'quests', type, language, framework, category, subcategory];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      return $http
        .get(endpoint, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function () {});
    };

    self.signup = function (data) {
      var path = ['public', 'signup'];
      var endpoint = SCW_ENV.ApiEndpoint + '/' + path.join('/');
      $log.debug('Creating trial team through simple-flow: ', data);
      return $http
        .post(endpoint, data, HttpConfigService.getHttpConfig())
        .then(function (response) {
          return response.data;
        })
        .finally(function (data) {
          return data;
        });
    };

    self.invalidApptypeCategoryOrSubcategory = function (app_type, category, subcategory, categories, source) {
      var withParams;
      switch (source) {
        case 'category': {
          if (!app_type && category) {
            $state.go('new-picker.category', { appTypeError: true });
            return true;
          }
          break;
        }
        case 'subcategory': {
          if (!app_type) {
            $state.go('new-picker.category', { appTypeError: true });
            return true;
          }
          if (!category) {
            withParams = {
              app_type: app_type,
              categorySubcategoryError: true,
            };
            $state.go('new-picker.app-type', withParams);
            return true;
          }
          break;
        }
        case 'language': {
          if (!app_type) {
            $state.go('new-picker.category', { appTypeError: true });
            return true;
          }
          if (!category) {
            withParams = {
              app_type: app_type,
              categorySubcategoryError: true,
            };
            $state.go('new-picker.app-type', withParams);
            return true;
          }
          if (!subcategory) {
            // re-route to ssubcategory page
            withParams = {
              app_type: app_type,
              category: category,
              categorySubcategoryError: true,
            };
            $state.go('new-picker.subcategory', withParams);
            return true;
          }
          break;
        }
      }

      if (!app_type) return false; // blanks are ok
      if (!categories.hasOwnProperty(app_type)) {
        // re-route to picker
        withParams = { appTypeError: true };
        $state.go('new-picker.category', withParams);
        return true;
      }
      // is the category invalid?
      if (!category) return false; // blanks are ok
      if (!categories[app_type].hasOwnProperty(category)) {
        // re-route to app type
        withParams = {
          app_type: app_type,
          categorySubcategoryError: true,
        };
        $state.go('new-picker.app-type', withParams);
        return true;
      }
      // Is the subcategory invalid?
      if (!subcategory) return false; // blanks are ok
      if (!categories[app_type][category].subitem.hasOwnProperty(subcategory)) {
        // re-route to ssubcategory page
        withParams = {
          app_type: app_type,
          category: category,
          categorySubcategoryError: true,
        };
        $state.go('new-picker.subcategory', withParams);
        return true;
      }
      return false;
    };

    // Legacy code, not for new-simple-flow
    self.invalidLanguage = function (app_type, category, subcategory, _language, metaLanguages) {
      if (!_language || Object.keys(metaLanguages).includes(_language)) {
        return false;
      }
      var withParams = {
        app_type: app_type,
        category: category,
        subcategory: subcategory,
        invalidLangError: true,
      };
      $state.go('simple-flow.languages', withParams);
      return true;
    };

    return self;
  },
]);
