import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { usePlatformContext } from '../platformContext';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../ApiContext';
import { VideoPlayerBase } from '../players/VideoPlayerBase';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformNavigate } from '../navigation';
import { WithFooter } from '../footer/WithFooter';
export const VideoPlayerWrapper = () => {
    const { videoId } = useParams();
    if (!videoId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const isAuthenticated = !!platformCtx.sessionUser;
    const [video, setVideo] = useState(undefined);
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const [secondsSpent, setSecondsSpent] = useState(0);
    useEffect(() => {
        handleErrorPromise(trpc.explore.video.startOrContinue.mutate({ videoId }), (r) => {
            if (r.authenticated !== isAuthenticated) {
                platformCtx.onUnAuthenticated();
                return;
            }
            setVideo(r.video);
        });
    }, [videoId]); // eslint-disable-line react-hooks/exhaustive-deps
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', hash: '', search: '' });
    }, [navigate, platformCtx]);
    const onCompleteVideo = useCallback((secondsSpent) => {
        setSecondsSpent(Math.round(secondsSpent));
        setEnableMarkAsComplete(true);
    }, []);
    const onMarkAsComplete = useCallback(() => {
        setEnableMarkAsComplete(false);
        handleErrorPromise(trpc.explore.video.finish.mutate({ videoId, secondsSpent }), (_r) => {
            navigateBack();
        });
    }, [videoId, secondsSpent, handleErrorPromise, navigateBack]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsxs(WithFooter, { wrapperComponent: "main", children: [_jsx(NavigationBar, { module: "explore", type: "video", onBackNavigation: navigateBack, enableMarkAsComplete: enableMarkAsComplete, onMarkAsComplete: isAuthenticated ? onMarkAsComplete : undefined }), _jsx(VideoPlayerBase, { video: video, analyticsScope: "explore", onComplete: onCompleteVideo })] }));
};
