import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { mapCategory } from '../utils/mapper';
export const getGoalBuffer = (goalDetails) => {
    return {
        goalId: goalDetails.goalId,
        name: goalDetails.name,
        isMandatory: goalDetails.isMandatory,
        badge: goalDetails.badge,
        objectives: goalDetails.objectives,
        startDate: goalDetails.startDate,
        endDate: goalDetails.endDate,
        status: goalDetails.status,
        basedOnRevisionNumber: goalDetails.revisionNumber,
    };
};
export const getNewGoalState = (startDate) => {
    return {
        goalId: uuidv4(),
        createdAt: new Date(),
        revisionNumber: 0,
        name: '',
        isMandatory: false,
        badge: null,
        objectives: [],
        startDate,
        endDate: dayjs(startDate).add(3, 'month').toDate(),
        status: 'new',
    };
};
export const getAvailableCategories = (slugs, metadata) => {
    const availableCategories = slugs.map((slug) => mapCategory(slug, metadata));
    availableCategories.sort((a, b) => {
        return a.mainName.localeCompare(b.mainName) || a.name.localeCompare(b.name);
    });
    return availableCategories;
};
export const isCleanBuffer = (buffer, lastSavedBuffer) => {
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('goalId', 'basedOnRevisionNumber', 'name', 'badge', 'status', 'isMandatory', (key) => buffer[key] === lastSavedBuffer[key])
        .with('startDate', 'endDate', (key) => { var _a, _b; return ((_a = buffer[key]) === null || _a === void 0 ? void 0 : _a.getTime()) === ((_b = lastSavedBuffer[key]) === null || _b === void 0 ? void 0 : _b.getTime()); })
        .with('objectives', (key) => isCleanObjectivesBuffer(buffer[key], lastSavedBuffer[key]))
        .exhaustive());
};
const getNameErrors = (goal) => {
    var _a, _b;
    const nameError = ((_a = goal.name) === null || _a === void 0 ? void 0 : _a.length) < 3
        ? 'goalsAdmin.validation.nameTooShort'
        : ((_b = goal.name) === null || _b === void 0 ? void 0 : _b.length) > 250
            ? 'goalsAdmin.validation.nameTooLong'
            : null;
    return nameError ? [{ field: 'name', message: nameError }] : [];
};
const getObjectiveErrors = (goal) => {
    var _a;
    return !((_a = goal.objectives) === null || _a === void 0 ? void 0 : _a.length) ? [{ field: 'objectives', message: 'goalsAdmin.validation.missingObjectives' }] : [];
};
const getEndTimeErrors = (goal, dateNowTimestamp) => {
    var _a, _b;
    const endDateTime = (_b = (_a = goal === null || goal === void 0 ? void 0 : goal.endDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateError = !endDateTime
        ? 'goalsAdmin.validation.invalidEndDate'
        : endDateTime < dateNowTimestamp && (goal.status === 'new' || goal.status === 'draft')
            ? 'goalsAdmin.validation.invalidPastEndDate'
            : null;
    return endDateError ? [{ field: 'endDate', message: endDateError }] : [];
};
const getStartTimeErrors = (goal) => {
    var _a, _b, _c, _d;
    const startDateTime = (_b = (_a = goal === null || goal === void 0 ? void 0 : goal.startDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateTime = (_d = (_c = goal === null || goal === void 0 ? void 0 : goal.endDate) === null || _c === void 0 ? void 0 : _c.getTime) === null || _d === void 0 ? void 0 : _d.call(_c);
    const startDateError = !startDateTime
        ? 'goalsAdmin.validation.invalidStartDate'
        : endDateTime && endDateTime < startDateTime
            ? 'goalsAdmin.validation.invalidDateOrder'
            : null;
    return startDateError ? [{ field: 'startDate', message: startDateError }] : [];
};
export const getGoalValidationErrors = (goal, dateNowTimestamp) => {
    return [
        ...getNameErrors(goal),
        ...getObjectiveErrors(goal),
        ...getEndTimeErrors(goal, dateNowTimestamp),
        ...getStartTimeErrors(goal),
    ];
};
export const isCleanObjectivesBuffer = (buffer, lastSavedBuffer) => {
    const objectives = buffer.sort((a, b) => a.objectiveNumber - b.objectiveNumber);
    const lastSavedObjectives = lastSavedBuffer.sort((a, b) => a.objectiveNumber - b.objectiveNumber);
    return objectives.every((objective, index) => lastSavedObjectives[index] ? isCleanObjectiveBuffer(objective, lastSavedObjectives[index]) : false);
};
export const getDaysDelta = (dateA, dateB, useCeil = false) => {
    if (!dateA || !dateB)
        return -1;
    const dateATimestamp = new Date(dateA).getTime();
    const dateBTimestamp = new Date(dateB).getTime();
    if (!dateATimestamp || !dateBTimestamp)
        return -1; // Can occur for invalid date selection via text input editing
    const delta = (dateATimestamp - dateBTimestamp) / 86400000;
    return useCeil ? Math.ceil(delta) : Math.round(delta);
};
export const getInitialSnackbarState = () => ({
    message: '',
    severity: 'success',
    open: false,
    key: new Date().getTime(),
});
export const isCleanObjectiveBuffer = (buffer, lastSavedBuffer) => {
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('kind', 'rootCategoryId', 'categoryId', 'subcategoryId', 'objectiveNumber', (key) => buffer[key] === lastSavedBuffer[key])
        .exhaustive());
};
export const getHumanizeDurationLanguageKey = (currentLanguage) => {
    if (typeof currentLanguage !== 'string')
        return 'en';
    if (currentLanguage.startsWith('en-'))
        return 'en';
    if (currentLanguage.startsWith('zh-'))
        return currentLanguage.replace('-', '_');
    return currentLanguage.split('-')[0] || 'en';
};
export const getMuiDatePickerLanguageKey = (currentLanguage) => {
    if (typeof currentLanguage !== 'string')
        return 'en-gb';
    if (currentLanguage.startsWith('en'))
        return 'en-gb';
    return currentLanguage.split('-')[0] || 'en-gb';
};
export const getEditingDisabledTooltip = (editingDisabled, status, t, i18nFormat) => {
    const translationStatus = 'goalsAdmin.entities.goals.status.' + (status === 'in_progress' ? 'published' : status);
    return editingDisabled
        ? i18nFormat(t('goalsAdmin.components.organisms.goalEditor.general.draftsOnlyEditEnabled', { status: t(translationStatus) }), 'sentenceCase')
        : undefined;
};
