var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApiClient } from '../../ApiContext';
import { usePlatformContext } from '../../platformContext';
import { cybermonAchievements } from './Cybermon.analytics';
import { getCategoryMainNameFromCategoryId } from './utils';
const isActiveCybermon = (cybermon) => {
    var _a, _b;
    const now = Date.now();
    const start = (_a = cybermon.custom_data.active_start) === null || _a === void 0 ? void 0 : _a.valueOf();
    const end = (_b = cybermon.custom_data.active_end) === null || _b === void 0 ? void 0 : _b.valueOf();
    return cybermon.active && start < now && end >= now && cybermon.name !== 'Cyber Hero';
};
const isCyberHero = (cybermon, cyberHero) => {
    const permittedAchievements = ['Accessomorph', 'Injectopus', 'XSSassin', 'Permissite'];
    /*
     * Check if all achievements earned are true
     * The program is Cybermon
     * The name is in the list of permitted achievements
     * The length of the filtered array is greater than or equal to 4
     * The Cyber Hero achievement is not earned
     */
    return (cybermon.filter((c) => c.earned && c.__meta.program === 'Cybermon' && permittedAchievements.includes(c.name))
        .length >= 4 && !cyberHero.earned);
};
const calculateLivesEarned = (completedActivities, activeCybermon) => {
    if (!activeCybermon || !activeCybermon.custom_data.category || completedActivities.length === 0) {
        return 0;
    }
    if (activeCybermon.earned) {
        return 3;
    }
    const start = activeCybermon.custom_data.active_start.valueOf();
    const end = activeCybermon.custom_data.active_end.valueOf();
    const activeCat = activeCybermon.custom_data.category;
    return completedActivities.reduce((lives, activity) => {
        var _a, _b;
        const category = getCategoryMainNameFromCategoryId(activity.category);
        const completedAt = (_b = (_a = activity.finished_at) === null || _a === void 0 ? void 0 : _a.valueOf()) !== null && _b !== void 0 ? _b : 0;
        if (completedAt > start && completedAt <= end && category === activeCat) {
            lives += activity.type === 'coding_lab' ? 2 : 1;
        }
        return lives;
    }, 0);
};
export const useCybermon = () => {
    const [cybermon, setCybermon] = useState([]);
    const [completedActivities, setCompletedActivities] = useState([]);
    const { trpc } = useApiClient();
    const { logAnalyticsEvent } = usePlatformContext();
    const achievementAnalytics = cybermonAchievements(logAnalyticsEvent);
    const activeCybermon = useMemo(() => cybermon === null || cybermon === void 0 ? void 0 : cybermon.find(isActiveCybermon), [cybermon]);
    const cyberHero = useMemo(() => cybermon === null || cybermon === void 0 ? void 0 : cybermon.find((c) => c.name === 'Cyber Hero'), [cybermon]);
    const livesEarned = useMemo(() => calculateLivesEarned(completedActivities, activeCybermon), [activeCybermon, completedActivities]);
    const getCompletedActivities = useCallback((active) => __awaiter(void 0, void 0, void 0, function* () {
        const completedActivitiesResponse = yield trpc.achievements.getCompletedActivities.query({
            after: active.custom_data.active_start,
            before: active.custom_data.active_end,
        });
        setCompletedActivities(completedActivitiesResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const getCybermon = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const cybermonAchievementsResponse = yield trpc.achievements.getAchievements.query();
        setCybermon(cybermonAchievementsResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const updateUserCybermon = useCallback((type, active) => __awaiter(void 0, void 0, void 0, function* () {
        const { mutate } = type === 'earned' ? trpc.achievements.earnAchievement : trpc.achievements.attemptAchievement;
        const response = yield mutate({ achievementId: active._id.toString() });
        if (type === 'earned') {
            achievementAnalytics.earnAchievement(active.name);
        }
        // Update the cybermon state with the new earned status
        if (response.kind === 'ok' && type === 'earned') {
            setCybermon((prev) => prev === null || prev === void 0 ? void 0 : prev.map((cybermon) => {
                return cybermon._id === active._id ? Object.assign(Object.assign({}, cybermon), { earned: true }) : cybermon;
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    const beginAttempt = () => {
        if (activeCybermon && !activeCybermon.attempted) {
            updateUserCybermon('attempted', activeCybermon);
        }
    };
    useEffect(() => {
        getCybermon();
    }, [getCybermon]);
    useEffect(() => {
        if (activeCybermon) {
            getCompletedActivities(activeCybermon);
        }
    }, [activeCybermon, getCompletedActivities]);
    useEffect(() => {
        if (cyberHero && isCyberHero(cybermon, cyberHero)) {
            updateUserCybermon('earned', cyberHero);
        }
        if (activeCybermon && !activeCybermon.earned && livesEarned > 2) {
            updateUserCybermon('earned', activeCybermon);
        }
    }, [livesEarned, activeCybermon, updateUserCybermon, cyberHero, cybermon]);
    return { cybermon: cybermon, livesEarned, beginAttempt, activeCybermon };
};
