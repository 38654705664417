export const cybermonAchievements = (sendEvent) => ({
    earnAchievement: (achievement) => sendEvent({ event: 'Weekly Badge Achieved', program: 'cybermon', achievement }),
});
export const cybermonActivities = (sendEvent) => ({
    viewActivity: (activity) => sendEvent({
        event: 'View Activity',
        program: 'cybermon',
        activityType: activity.type,
        activityTitle: activity.title,
        activityStatus: activity.status,
    }),
});
