import { jsx as _jsx } from "react/jsx-runtime";
import { useRive } from '@rive-app/react-canvas';
export const Rive = ({ src }) => {
    const { rive, RiveComponent } = useRive({
        src: src,
        autoplay: true,
        stateMachines: ['State Machine 1'],
    }, {
        shouldResizeCanvasToContainer: true,
    });
    return _jsx(RiveComponent, { onMouseEnter: () => rive && rive.play() });
};
