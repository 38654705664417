var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext } from 'react';
import { Box as MuiBox, Button as MuiButton, ListItemButton as MuiListItemButton, ListItemText as MuiListItemText, Typography as MuiTypography, styled, useMediaQuery, } from '@mui/material';
import { fontFamily } from '@securecodewarrior/design-system-react';
import { AppBarMobileContext } from '../../AppBar.context';
import { appBarButtonStyles } from './AppBarButton.styles';
// =============================================================
// Styled Components
// =============================================================
const StyledButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active = false, theme }) => (Object.assign({}, appBarButtonStyles(active, false, theme).root)));
const StyledListItemButton = styled(MuiListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active = false, theme }) => (Object.assign({}, appBarButtonStyles(active, true, theme).root)));
const StyledTextBox = styled(MuiBox, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'mobile',
})(({ active = false, mobile = false, theme }) => (Object.assign({}, appBarButtonStyles(active, mobile, theme).textBox)));
// =============================================================
// Mobile
// =============================================================
const AppBarButtonMobile = (props) => {
    const { active, children, className = '', condition, endIcon, onClick, startIcon: _startIcon } = props, rest = __rest(props, ["active", "children", "className", "condition", "endIcon", "onClick", "startIcon"]);
    const appBarMobileContext = useContext(AppBarMobileContext);
    const handleClick = useCallback((event) => {
        var _a;
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
        !endIcon && ((_a = appBarMobileContext === null || appBarMobileContext === void 0 ? void 0 : appBarMobileContext.setOptionSelected) === null || _a === void 0 ? void 0 : _a.call(appBarMobileContext));
    }, [appBarMobileContext, endIcon, onClick]);
    return condition ? (_jsxs(StyledListItemButton, Object.assign({ active: active, className: `ScwAppBarButton-root ${className}`, onClick: handleClick }, rest, { children: [_jsx(MuiListItemText, { disableTypography: true, children: _jsx(MuiTypography, { variant: "caption", color: "inherit", children: children }) }), endIcon] }))) : null;
};
// =============================================================
// Desktop
// =============================================================
const AppBarButtonDesktop = (props) => {
    const { active, children, className = '', condition, endIcon } = props, rest = __rest(props, ["active", "children", "className", "condition", "endIcon"]);
    return condition ? (_jsx(StyledButton, Object.assign({ active: active, className: `ScwAppBarButton-root ${className}` }, rest, { children: _jsxs(StyledTextBox, { active: active, component: "div", children: [_jsx(MuiTypography, { noWrap: true, variant: "caption", color: "inherit", sx: { fontFamily: fontFamily.mona, fontWeight: 500 }, children: children }), endIcon] }) }))) : null;
};
// =============================================================
// AppBarButton
// =============================================================
const AppBarButtonComponent = (props) => {
    const isDesktopLayout = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    return isDesktopLayout ? _jsx(AppBarButtonDesktop, Object.assign({}, props)) : _jsx(AppBarButtonMobile, Object.assign({}, props));
};
AppBarButtonComponent.displayName = 'AppBarButton';
AppBarButtonComponent.defaultProps = {
    condition: true,
};
export const Unstable_AppBarButton = React.memo(AppBarButtonComponent);
/** @deprecated use {@link Unstable_AppBarButton} to indicate that you are aware that this component will change */
export const AppBarButton = Unstable_AppBarButton;
export default AppBarButton;
