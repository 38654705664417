/**
 *
 * @param hashRoute an "angularjs style" route, e.g. '#/success-hub' extracted from portal.securecodewarrior.com/#/success-hub
 * @returns whether to use light or dark theme
 */
export function decideThemeByHashRoute(hashRoute) {
    // strip leading '#/'
    const bareRoute = hashRoute.replace(/^#\//, '');
    return decideThemeByBareRoute(bareRoute);
}
/**
 *
 * @param bareRoute a "bare" route, e.g. 'success-hub' extracted from portal.securecodewarrior.com/#/success-hub
 * @returns
 */
export function decideThemeByBareRoute(bareRoute) {
    return bareRoute.startsWith('success-hub') || bareRoute.startsWith('communications') ? 'light' : 'dark';
}
