import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';
import { Header } from '../components/molecules/Header';
import { SectionContainer } from '../components/molecules/SectionContainer';
import { MainContainer } from '../components/molecules/MainContainer';
import Filters, { CustomTabPanel } from '../components/molecules/Filters';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Table from '../components/organisms/Table';
import humanizeDuration from 'humanize-duration';
import { statusKeys, statusToBadgeMap, goalsAdminUrls } from '../constants';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useGoalsAdminApi } from '../hooks/useGoalsAdminApi';
import { Pending, useApiLoad } from '../../Api.hooks';
import { Badge } from '../components/atoms/Badge';
import { Tooltip } from '../../common/Tooltip';
import { LearningQuestsIcon } from '../components/icons/LearningQuestsIcon';
import { Link, usePlatformNavigate } from '../../navigation';
import { useLocation } from 'react-router-dom';
import SnackbarAlert from '../components/molecules/SnackbarAlert';
import { listGoalAnalytics } from '../analytics/ListGoals.analytics';
import { usePlatformContext } from '../../platformContext';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { getHumanizeDurationLanguageKey } from '../utils';
dayjs.extend(duration);
export const ListGoalsRoute = () => {
    return _jsx(ListGoalsLoader, {});
};
export const ListGoalsLoader = () => {
    const api = useGoalsAdminApi();
    const goals = useApiLoad(api.list.query, undefined);
    return match(goals)
        .with(Pending, () => _jsx(PendingListGoals, {}))
        .with(P.instanceOf(Error), (error) => t('pages.multiassessments.error', { message: error.message }))
        .with({ status: 'ok' }, ({ data }) => _jsx(ListGoals, { goals: data.published, drafts: data.drafts }))
        .exhaustive();
};
const PendingListGoals = () => {
    const theme = useTheme();
    const filterItems = statusKeys.map((key) => ({
        key,
        label: `${t('goalsAdmin.entities.goals.status.' + (key === 'draft' ? 'my_drafts' : key))}  `,
    }));
    return (_jsxs(MainContainer, { children: [_jsxs(Header, { children: [_jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: t('goalsAdmin.pages.list.eyebrowHeading') }), _jsx(Typography, { component: "h1", variant: "h2", children: t('goalsAdmin.pages.list.mainHeading') })] }), _jsx(Button, { variant: "contained", disabled: true, children: t('goalsAdmin.pages.list.createNewButton') })] }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } }), _jsx(Filters, { ariaLabel: t('goalsAdmin.pages.list.filtersAriaLabel'), filterItems: filterItems, skeletonMode: true, onSelectFilter: () => {
                    // Do nothing
                } }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } }), _jsxs(SectionContainer, { isCentered: false, styleOverrides: { gap: theme.spacing(5), paddingTop: theme.spacing(5) }, children: [_jsx(Skeleton, { width: "100%", height: "49px", variant: "rounded", sx: { marginTop: theme.spacing(5) } }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" }), _jsx(Skeleton, { width: "100%", height: "52px", variant: "rounded" })] })] }));
};
export const ListGoals = ({ goals, drafts }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const theme = useTheme();
    const navigate = usePlatformNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [filterIndex, setFilterIndex] = useState(0);
    const [hasHandledCreatedOrDeletedGoal, setHasHandledCreatedOrDeletedGoal] = useState(false);
    const [hasClearedCreatedGoalClass, setHasClearedCreatedGoalClass] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => listGoalAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const newlyCreatedGoalId = (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.newlyCreatedGoalId) !== null && _b !== void 0 ? _b : null;
    const newlyDeletedGoalId = (_d = (_c = location.state) === null || _c === void 0 ? void 0 : _c.newlyDeletedGoalId) !== null && _d !== void 0 ? _d : null;
    const didDeleteDraft = (_f = (_e = location.state) === null || _e === void 0 ? void 0 : _e.deletedDraft) !== null && _f !== void 0 ? _f : false;
    const [snackbarConfig, setSnackbarConfig] = useState({
        message: '',
        severity: 'success',
        open: false,
        key: new Date().getTime(),
    });
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({
            message,
            severity,
            open: true,
            key: new Date().getTime(),
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const onCreateNewClicked = () => {
        navigate.to({ pathname: goalsAdminUrls.createPage, hash: '', search: '' });
        analytics.onCreateNewClicked((goals === null || goals === void 0 ? void 0 : goals.length) || 0, false);
    };
    const onCreateNewWithNoGoalsClicked = () => {
        navigate.to({ pathname: goalsAdminUrls.createPage, hash: '', search: '' });
        analytics.onCreateNewClicked(0, true);
    };
    const onViewGoalClickedAnalytics = (goalId) => {
        const goal = goals === null || goals === void 0 ? void 0 : goals.find((g) => g.goalId === goalId);
        if (goal) {
            analytics.onViewGoalClicked(goal);
        }
    };
    const onEditDraftClickedAnalytics = (draftId) => {
        const draft = drafts === null || drafts === void 0 ? void 0 : drafts.find((draft) => draft.goalId === draftId);
        if (draft) {
            analytics.onEditDraftClicked(draft);
        }
    };
    useEffect(() => {
        if (newlyCreatedGoalId && !hasHandledCreatedOrDeletedGoal) {
            triggerSnackbar(t('goalsAdmin.pages.edit.alerts.goalCreatedSuccessfully'), 'success');
            setHasHandledCreatedOrDeletedGoal(true);
            setTimeout(() => {
                setHasClearedCreatedGoalClass(true);
                const highlightedRow = document.querySelector(`tr.is-highlighted`);
                if (highlightedRow) {
                    highlightedRow.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
            }, 1);
        }
    }, [newlyCreatedGoalId, hasHandledCreatedOrDeletedGoal, t]);
    useEffect(() => {
        if (newlyDeletedGoalId && !hasHandledCreatedOrDeletedGoal) {
            const translationKey = didDeleteDraft
                ? 'goalsAdmin.pages.edit.alerts.draftDeletedSuccessfully'
                : 'goalsAdmin.pages.edit.alerts.goalDeletedSuccessfully';
            triggerSnackbar(t(translationKey), 'success');
            setHasHandledCreatedOrDeletedGoal(true);
        }
    }, [newlyDeletedGoalId, didDeleteDraft, hasHandledCreatedOrDeletedGoal, t]);
    const countByStatusKeyMap = (goals === null || goals === void 0 ? void 0 : goals.reduce((map, goal) => {
        if (goal.status in map) {
            map[goal.status]++;
        }
        else {
            map[goal.status] = 1;
        }
        map['all']++;
        return map;
    }, statusKeys.reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: 0 })), {}))) || {};
    countByStatusKeyMap.draft = (drafts === null || drafts === void 0 ? void 0 : drafts.length) || 0;
    countByStatusKeyMap.all += countByStatusKeyMap.draft;
    const onTabFilterChange = (newIndex) => {
        setFilterIndex(newIndex);
        const newStatus = statusKeys[newIndex];
        analytics.onGoalsListTabFiltered(newStatus, countByStatusKeyMap[newStatus], (goals === null || goals === void 0 ? void 0 : goals.length) || 0);
    };
    const filterItems = statusKeys.map((key) => {
        var _a;
        return ({
            key,
            label: `${t('goalsAdmin.entities.goals.status.' + (key === 'draft' ? 'my_drafts' : key))} (${(_a = countByStatusKeyMap[key]) !== null && _a !== void 0 ? _a : 0})`,
        });
    });
    const displayedColumns = [
        {
            key: 'name',
            label: t(`goalsAdmin.pages.list.tableHeader.goal`),
        },
        {
            key: 'participants',
            label: t(`goalsAdmin.pages.list.tableHeader.participants`),
        },
        {
            key: 'startDate',
            label: t(`goalsAdmin.pages.list.tableHeader.starts`),
        },
        {
            key: 'endDate',
            label: t(`goalsAdmin.pages.list.tableHeader.ends`),
        },
        {
            key: 'completion',
            label: t(`goalsAdmin.pages.list.tableHeader.completion`),
            tooltip: t(`goalsAdmin.pages.list.tooltips.completionTableColumn`),
        },
        {
            key: 'status',
            label: t(`goalsAdmin.pages.list.tableHeader.status`),
        },
        {
            key: 'actions',
            label: t(`goalsAdmin.pages.list.tableHeader.actions`),
        },
    ];
    const rowsPerStatus = statusKeys.map((statusKey) => {
        const publishedGoals = goals
            ? goals === null || goals === void 0 ? void 0 : goals.map((goal, index) => mapGoalToTableRow(goal, displayedColumns, statusKey, index, hasClearedCreatedGoalClass ? null : newlyCreatedGoalId, onViewGoalClickedAnalytics, i18n.language))
            : [];
        publishedGoals.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
        const draftGoals = drafts
            ? drafts.map((draft) => mapDraftToTableRow(draft, displayedColumns, statusKeys[filterIndex], onEditDraftClickedAnalytics, theme))
            : [];
        return [...draftGoals, ...publishedGoals];
    });
    return (_jsxs(MainContainer, { children: [_jsxs(Header, { children: [_jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", sx: { color: theme.palette.text.soft }, children: t('goalsAdmin.pages.list.eyebrowHeading') }), _jsx(Typography, { component: "h1", variant: "h2", children: t('goalsAdmin.pages.list.mainHeading') })] }), _jsx(Button, { variant: "contained", onClick: onCreateNewClicked, children: t('goalsAdmin.pages.list.createNewButton') })] }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } }), (!!(goals === null || goals === void 0 ? void 0 : goals.length) || !!(drafts === null || drafts === void 0 ? void 0 : drafts.length)) && (_jsxs(_Fragment, { children: [_jsx(Filters, { ariaLabel: t('goalsAdmin.pages.list.filtersAriaLabel'), filterItems: filterItems, onSelectFilter: onTabFilterChange }), _jsx(Divider, { sx: { borderColor: theme.palette.container.border.default } })] })), _jsx(SectionContainer, { styleOverrides: { paddingTop: theme.spacing(5) }, isCentered: !((_g = rowsPerStatus[filterIndex]) === null || _g === void 0 ? void 0 : _g.some((row) => row.isVisible)), children: _jsx(_Fragment, { children: statusKeys.map((statusKey, index) => {
                        var _a;
                        return (_jsx(CustomTabPanel, { value: filterIndex, index: index, children: !((_a = rowsPerStatus[index]) === null || _a === void 0 ? void 0 : _a.some((row) => row.isVisible)) ? (_jsx(GoalsMissing, { status: (goals === null || goals === void 0 ? void 0 : goals.length) || (drafts === null || drafts === void 0 ? void 0 : drafts.length) ? statusKeys[index] : 'all', onCreateNewClicked: onCreateNewWithNoGoalsClicked })) : (_jsx(Table, { displayedColumns: displayedColumns, rows: rowsPerStatus[index] })) }, `tab-panel-${statusKey}`));
                    }) }) }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
const GoalsMissing = (props) => {
    const { status, onCreateNewClicked } = props;
    const { t } = useTranslation();
    return (_jsxs(Stack, { sx: { alignItems: 'center', textAlign: 'center' }, children: [_jsx(LearningQuestsIcon, { sx: (theme) => ({
                    fontSize: theme.typography.pxToRem(44),
                    color: theme.palette.clickable.nav.item.active,
                    marginBottom: theme.typography.pxToRem(20),
                }) }), _jsx(Typography, { component: "h2", variant: "h3", marginBottom: (theme) => theme.typography.pxToRem(6), children: t(`goalsAdmin.pages.list.noGoalsHeading.${status}`) }), status === 'all' && (_jsxs(_Fragment, { children: [_jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(360), marginBottom: (theme) => theme.typography.pxToRem(12), children: t('goalsAdmin.pages.list.noGoalsParagraph') }), _jsx(Button, { variant: "contained", onClick: onCreateNewClicked, children: t('goalsAdmin.pages.list.createNewButton') })] })), status !== 'all' && (_jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(500), marginBottom: (theme) => theme.typography.pxToRem(12), children: t('goalsAdmin.pages.list.noStatusGoalsParagraph.' + status) }))] }));
};
const mapDraftToTableRow = (draft, displayedColumns, statusKey, onEditDraftClickedAnalytics, theme) => {
    const handleClickAnalytics = () => {
        onEditDraftClickedAnalytics(draft.goalId);
    };
    const cells = {
        name: (_jsxs("span", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }, children: [draft.name && (_jsx(Typography, { sx: (theme) => ({
                        color: theme.palette.text.title,
                        fontWeight: 600,
                        fontSize: theme.typography.pxToRem(16),
                    }), children: draft.name })), !draft.name && (_jsxs("span", { style: { display: 'inline-flex' }, children: [_jsxs(Typography, { sx: { fontWeight: 600, fontSize: theme.typography.pxToRem(16), color: theme.palette.text.title }, children: [t('goalsAdmin.pages.list.untitledDraft'), " -"] }), _jsx(Typography, { sx: {
                                color: theme.palette.text.title,
                                fontStyle: 'italic',
                                fontWeight: 600,
                                fontSize: theme.typography.pxToRem(16),
                                paddingRight: theme.spacing(1.5),
                                paddingLeft: theme.spacing(1),
                            }, children: dayjs(draft.createdAt).format(' DD MMM YYYY - HH:mm:ss') })] })), draft.isMandatory && (_jsx("span", { style: { marginLeft: theme.spacing(2) }, children: _jsx(Badge, { label: t('goalsAdmin.entities.mandatory'), variant: "primary" }) }))] })),
        participants: '-',
        startDate: '-',
        endDate: '-',
        completion: '-',
        status: _jsx(Badge, { label: t('goalsAdmin.entities.goals.status.draft'), variant: statusToBadgeMap.draft }),
        actions: (_jsx("span", { style: {
                display: 'flex',
                justifyContent: 'space-around',
                gap: theme.typography.pxToRem(10),
            }, children: _jsx(Link, { to: goalsAdminUrls.detailsPage(draft.goalId), analyticsCallback: handleClickAnalytics, linkProps: {
                    color: 'soft',
                    'aria-label': t('goalsAdmin.pages.list.editDraftLinkAriaLabel'),
                }, state: { goalName: draft.name, status: 'draft' }, children: _jsx(EditIcon, { fontSize: "large", sx: (theme) => ({
                        color: theme.palette.clickable.nav.item.default,
                        verticalAlign: 'middle',
                    }) }) }) })),
    };
    const displayedCells = Object.fromEntries(displayedColumns.map(({ key }) => [key, cells[key]]));
    return Object.assign(Object.assign({}, displayedCells), { isVisible: statusKey === 'draft' || statusKey === 'all', sortKey: 'not used', isHighlighted: false });
};
const mapGoalToTableRow = (goal, displayedColumns, statusKey, index, newlyCreatedGoalId, onViewGoalClickedAnalytics, currentLanguage) => {
    const handleClickAnalytics = () => {
        onViewGoalClickedAnalytics(goal.goalId);
    };
    const cells = {
        name: (_jsxs("span", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }, children: [_jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.title, fontWeight: 600, fontSize: theme.typography.pxToRem(16) }), children: goal.name }), goal.isMandatory && (_jsx("span", { style: { marginLeft: '8px' }, children: _jsx(Badge, { label: t('goalsAdmin.entities.mandatory'), variant: "primary" }) }))] })),
        participants: '' + goal.participantCount,
        startDate: (_jsx(Tooltip, { title: formatDuration(goal.startDate, 'start', currentLanguage), arrow: true, children: _jsx("p", { children: goal.startDate.toLocaleDateString(currentLanguage === 'en-US' ? 'en-GB' : currentLanguage, {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                }) }) }, `start-date-tooltip-${index}`)),
        endDate: (_jsx(Tooltip, { title: formatDuration(goal.endDate, 'end', currentLanguage), arrow: true, children: _jsx("p", { children: goal.endDate.toLocaleDateString(currentLanguage === 'en-US' ? 'en-GB' : currentLanguage, {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                }) }) }, `end-date-tooltip-${index}`)),
        completion: goal.completionRate === null
            ? t('goalsAdmin.pages.list.notApplicableAbbrevation')
            : Math.round(goal.completionRate * 100) + '%',
        status: (_jsx(Badge, { label: t('goalsAdmin.entities.goals.status.' + goal.status), variant: statusToBadgeMap[goal.status] })),
        actions: (_jsx(Box, { component: "span", sx: (theme) => ({
                display: 'flex',
                justifyContent: 'space-around',
                gap: theme.typography.pxToRem(10),
            }), children: _jsx(Link, { to: goalsAdminUrls.detailsPage(goal.goalId), analyticsCallback: handleClickAnalytics, linkProps: {
                    color: 'soft',
                    'aria-label': t('goalsAdmin.pages.list.viewGoalLinkAriaLabel'),
                }, state: { goalName: goal.name, status: goal.status }, children: _jsx(VisibilityIcon, { fontSize: "large", sx: (theme) => ({
                        color: theme.palette.clickable.nav.item.default,
                        verticalAlign: 'middle',
                    }) }) }) })),
    };
    const displayedCells = Object.fromEntries(displayedColumns.map(({ key }) => [key, cells[key]]));
    return Object.assign(Object.assign({}, displayedCells), { isVisible: statusKey === 'all' ? true : goal.status === statusKey, sortKey: getSortKey(goal.status, goal.endDate), isHighlighted: newlyCreatedGoalId === goal.goalId });
};
function getSortKey(status, endDate) {
    const statusPrecedence = {
        draft: '4',
        archived: '3',
        finished: '2',
        scheduled: '1',
        in_progress: '0',
    };
    return statusPrecedence[status] + endDate.toISOString();
}
function formatDuration(time, dateType, currentLanguage) {
    const now = Date.now();
    const duration = time.getTime() - now;
    const humanized = humanizeDuration(Math.abs(duration), {
        largest: 1,
        language: getHumanizeDurationLanguageKey(currentLanguage),
    });
    if (duration < 0) {
        return (_jsx(Trans, { i18nKey: dateType === 'start' ? 'goalsAdmin.pages.list.tooltips.startedAgo' : 'goalsAdmin.pages.list.tooltips.endedAgo', values: { duration: humanized } }));
    }
    else {
        return (_jsx(Trans, { i18nKey: dateType === 'start' ? 'goalsAdmin.pages.list.tooltips.startsIn' : 'goalsAdmin.pages.list.tooltips.endsIn', values: { duration: humanized } }));
    }
}
