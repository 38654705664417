import { Box, styled } from '@mui/material';
export const HealthBarSegment = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'completed',
})(({ theme }) => ({
    width: 100,
    height: 40,
    borderRadius: theme.shape.borderRadius * 2,
    display: 'grid',
    placeContent: 'center',
    border: '1px solid',
}), ({ completed, theme }) => ({
    borderColor: completed ? theme.palette.badge.success.border : theme.palette.badge.default.border,
    backgroundColor: completed ? theme.palette.badge.success.fill : theme.palette.badge.default.fill,
}));
