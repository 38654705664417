import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { usePlatformContext } from '../platformContext';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../ApiContext';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformNavigate } from '../navigation';
import { GuidelinePlayer } from '../players/GuidelinePlayer';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { WithFooter } from '../footer/WithFooter';
export const GuidelineWrapper = () => {
    const { guidelineId } = useParams();
    if (!guidelineId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const [guideline, setGuideline] = useState(undefined);
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    useEffect(() => {
        handleErrorPromise(trpc.explore.guideline.startOrContinue.mutate({ guidelineId }), (r) => {
            setGuideline(r.guideline);
            platformCtx.logAnalyticsEvent({
                event: 'Access Guideline',
                scope: 'explore',
                guideline_slug: guidelineId,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidelineId, handleErrorPromise, platformCtx]);
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', hash: '', search: '' });
    }, [navigate, platformCtx]);
    const onMarkAsComplete = useCallback(() => {
        setEnableMarkAsComplete(false);
        handleErrorPromise(trpc.explore.guideline.finish.mutate({ guidelineId }), (_r) => {
            platformCtx.logAnalyticsEvent({
                event: 'Finished Guideline',
                scope: 'explore',
                guideline_slug: guidelineId,
            });
            navigateBack();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidelineId, handleErrorPromise, navigateBack, platformCtx]);
    return (_jsxs(WithFooter, { wrapperComponent: "main", children: [_jsx(NavigationBar, { module: "explore", type: "guideline", onBackNavigation: navigateBack, enableMarkAsComplete: enableMarkAsComplete, onMarkAsComplete: onMarkAsComplete }), guideline && (_jsx(GuidelinePlayer, { id: guidelineId, analyticsScope: 'explore', guideline: guideline.content, title: guideline.title, category: guideline.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }) }))] }));
};
