import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Stack, Typography } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Trophy } from '../../../../common/components/organisms/Activities/Trophy';
import { useTranslation } from 'react-i18next';
export const CompletionModal = ({ open, activityType, onBack, onNext, onBackDropClicked }) => {
    const { t } = useTranslation();
    return (_jsxs(Dialog, { open: open, onClose: onBackDropClicked, sx: (theme) => ({
            '& .MuiPaper-root:not(.MuiAlert-root)': {
                border: `1px solid ${theme.palette.container.border.default}`,
                backgroundColor: theme.palette.container.fill.card2,
                backgroundImage: 'unset',
                borderRadius: 3,
                p: 10,
            },
        }), children: [_jsx(Typography, { textAlign: "center", variant: "h2", mb: 5, children: t('pages.quests.completionModal.congratulations') }), _jsxs(Stack, { alignItems: "center", gap: 4, mb: 3, children: [_jsx(Typography, { textAlign: "center", children: t(acitivityTypeTranslationkeys[activityType]) }), _jsx(Trophy, { sx: { width: '215px', height: '150px' } })] }), _jsxs(Stack, { direction: "row", gap: 2.5, children: [_jsx(Button, { variant: "text", onClick: onBack, children: t('pages.quests.completionModal.backToQuests') }), _jsx(Button, { variant: "contained", onClick: onNext, endIcon: _jsx(ArrowForwardRounded, { fontSize: "large" }), children: t('pages.quests.completionModal.nextActivity') })] })] }));
};
const acitivityTypeTranslationkeys = {
    video: 'pages.quests.completionModal.videoCompleted',
    guideline: 'pages.quests.completionModal.guidelineCompleted',
    challenge: 'pages.quests.completionModal.challengeCompleted',
    coding_lab: 'pages.quests.completionModal.codingLabCompleted',
    mission: 'pages.quests.completionModal.missionCompleted',
    walkthrough: 'pages.quests.completionModal.walkthroughCompleted',
};
