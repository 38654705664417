import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { QuestionMark } from '@mui/icons-material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { CybermonAchievementCard } from '../styled';
import { useCybermon } from '../hooks/useCybermon';
import { CybermonAchievement } from './CybermonAchievement';
import { Rive } from '../../common/Rive/Rive';
import { ModalProvider } from '../context/ModalContext';
import { CybermonAchievementDialog } from './CybermonAchievementDialog';
const ObtainedCyberHero = () => (_jsxs(CybermonAchievementCard, { sx: {
        padding: 0,
        overflow: 'hidden',
        gridRow: '1 / 3',
        gridColumn: '3',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto max-content',
    }, children: [_jsx(Box, { sx: { borderBottom: (theme) => `1px solid ${theme.palette.container.border.default}`, height: '100%' }, children: _jsx(Rive, { src: "https://media.securecodewarrior.com/achievements/cyberlord_card.riv" }) }), _jsx(Stack, { gap: 2, direction: "column", sx: { p: 4 }, children: _jsx(Typography, { color: (theme) => theme.palette.text.primary, sx: { wordBreak: 'break-all' }, fontWeight: "bold", textAlign: "center", children: "Cyber Hero" }) })] }));
const UnobtainedCyberHero = () => (_jsxs(CybermonAchievementCard, { sx: { padding: 0, overflow: 'hidden', gridRow: '1 / 3', gridColumn: '3' }, children: [_jsx(Box, { sx: { borderRight: (theme) => `1px solid ${theme.palette.container.border.default}`, height: '100%' }, children: _jsx(Box, { display: "flex", alignItems: "center", justifyContent: "center", height: "100%", color: "text.soft", sx: { background: (theme) => theme.palette.container.fill.card2 }, children: _jsx(QuestionMark, {}) }) }), _jsx(Stack, { gap: 2, direction: "column", p: 4, children: _jsx(Typography, { variant: "subtitle1", color: "text.soft", sx: { wordBreak: 'break-all' }, children: "Undiscovered" }) })] }));
const CyberHeroAchievement = ({ obtained }) => (_jsx(_Fragment, { children: obtained ? _jsx(ObtainedCyberHero, {}) : _jsx(UnobtainedCyberHero, {}) }));
export const WeeklyCybermon = () => {
    const { cybermon } = useCybermon();
    return (_jsxs(ModalProvider, { children: [_jsx(Card, { gap: 5, sx: { display: 'grid', gridTemplateColumns: '3fr 3fr 2.5fr', height: '100%' }, children: cybermon.map(({ _id, name, active, custom_data, earned, image }, i) => name !== 'Cyber Hero' ? (_jsx(CybermonAchievement, { name: name, active: active, active_end: custom_data.active_end, active_start: custom_data.active_start, earned: earned, image: image, week: i + 1 }, _id)) : (_jsx(CyberHeroAchievement, { obtained: earned }, _id))) }), _jsx(CybermonAchievementDialog, {})] }));
};
