import { createContext, useContext } from 'react';
import { create as createZustandStore } from 'zustand';
import { createQueryString, saveFilterState } from './FilterState';
import { applyChangeToFilter, calculateRelevance, mapFilterActionToFilteringEventTrigger, } from './FilterLogic';
const SEARCH_TERM_LIMIT = 25;
export const filterItems = (items, filterState) => {
    const terms = filterState.searchTerm
        ? filterState.searchTerm
            .toLocaleLowerCase()
            .split(' ')
            .filter((x) => x !== '')
            .slice(0, SEARCH_TERM_LIMIT)
        : [];
    items.forEach((itm) => (itm.relevance = calculateRelevance(itm, filterState, terms)));
    return items.filter((itm) => itm.relevance > 0).sort((a, b) => b.relevance - a.relevance);
};
export const createExploreFilterStore = (initialFilterState, initialItems, setSearchParams, logAnalyticsEvent) => {
    return createZustandStore((set) => ({
        filterState: initialFilterState,
        filteredItems: filterItems(initialItems, initialFilterState),
        doFilterAction: (action) => {
            return set((store) => {
                logAnalyticsEvent(mapFilterActionToFilteringEventTrigger(action));
                const newState = applyChangeToFilter(store.filterState, action);
                setSearchParams(createQueryString(newState));
                saveFilterState(newState);
                // normally here we would return an object with the properties that have changed,
                // but we don't need it because the URL change will call the setFilterState function which will
                // ensure we are updated. (if we still do this, it'd be a useless render)
                return {};
            });
        },
        setFilterState: (newFilterState, items) => {
            return set(() => ({ filteredItems: filterItems(items, newFilterState), filterState: newFilterState }));
        },
    }));
};
export const ExploreFilterContext = createContext(null);
export const useExploreFilterContext = () => {
    const useFilterStore = useContext(ExploreFilterContext);
    if (!useFilterStore) {
        throw new Error('useExploreFilterContext must be used within an ExploreFilterContext');
    }
    return useFilterStore;
};
