import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { Trophy } from './Trophy';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { ReplayOutlined } from '@mui/icons-material';
import { confettiOverlay } from './ConfettiOverlay';
import { Trans, useTranslation } from 'react-i18next';
/**
 * @deprecated Use `ActivityStatusBanner` instead
 */
export const CompletedBanner = (props) => {
    const { t } = useTranslation();
    return (_jsx(Box, { component: "div", display: "flex", justifyContent: "center", width: "100%", sx: { backgroundImage: confettiOverlay, backgroundPosition: 'center' }, p: 10, children: _jsxs(Stack, { bgcolor: (style) => style.palette.container.fill.card1, border: (style) => `1px solid ${style.palette.container.border.default}`, borderRadius: 3, width: "100%", gap: 5, alignItems: "center", padding: 5, direction: { xs: 'column', lg: 'row' }, children: [_jsx(Trophy, { sx: { width: '215px', height: '150px' } }), _jsxs(Stack, { gap: 5, alignItems: "flex-start", children: [_jsx(Typography, { variant: "h1", children: _jsx(Trans, { i18nKey: "components.organisms.activityStatus.title.already_completed", children: _jsx(Typography, { component: "em", sx: { font: 'inherit' }, color: (style) => style.palette.text.status.success }) }) }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "subtitle1", maxWidth: "900px", children: t('components.organisms.activityStatus.descriptionBanner.already_completed') }), _jsx(Button, { sx: { px: 6, py: 2 }, onClick: props.onClickStartRefresher, variant: "text", startIcon: _jsx(ReplayOutlined, {}), children: t('components.organisms.activityStatus.startRefresher') })] })] }) }));
};
