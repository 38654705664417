import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { QuestionMark, Shield } from '@mui/icons-material';
import { CybermonAvatarImage, CybermonAchievementCard, AchievementGrid } from '../styled';
const Badge = ({ earned, capturable }) => {
    if (!capturable && !earned) {
        return null;
    }
    return (_jsxs(Typography, { variant: "caption", color: "text.soft", gridArea: "content", justifySelf: "end", pt: 1, children: [earned && _jsx(Shield, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(12) }) }), earned ? ' Secured!' : 'Secure me'] }));
};
const CybermonAvatar = ({ image, earned = false, discovered }) => {
    if (!discovered) {
        return (_jsx(Box, { display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", color: "text.soft", sx: { background: (theme) => theme.palette.container.fill.card2 }, children: _jsx(QuestionMark, {}) }));
    }
    return _jsx(CybermonAvatarImage, { src: image, earned: earned });
};
const CybermonBody = ({ name, week, earned, capturable, discovered }) => {
    if (!discovered) {
        return (_jsx(Typography, { variant: "subtitle1", color: "text.soft", sx: { wordBreak: 'break-all' }, children: "Undiscovered" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle1", color: "text.title", sx: { wordBreak: 'break-all' }, gridArea: "name", children: name }), _jsxs(Typography, { variant: "caption", color: "text.soft", gridArea: "content", children: ["Week ", week] }), _jsx(Badge, { earned: earned, capturable: capturable })] }));
};
export const CybermonAchievement = (props) => {
    const { name, image, active_start, active_end, active, earned, week } = props;
    const discovered = active_start ? new Date() >= active_start : false;
    const capturable = active && discovered && active_end ? new Date() <= active_end : false;
    return (_jsxs(CybermonAchievementCard, { sx: { padding: 0, overflow: 'hidden' }, children: [_jsx(Box, { sx: { borderRight: (theme) => `1px solid ${theme.palette.container.border.default}`, height: '100%' }, children: _jsx(CybermonAvatar, { image: image, earned: earned, discovered: discovered }) }), _jsx(AchievementGrid, { children: _jsx(CybermonBody, { name: name, week: week, discovered: discovered, capturable: capturable, earned: earned }) })] }));
};
