import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HealthBarSegment } from '../styled';
import { useCybermon } from '../hooks/useCybermon';
export const HealthBar = () => {
    const { t } = useTranslation();
    const { livesEarned } = useCybermon();
    const lives = [1, 2, 3];
    return (_jsxs(Stack, { spacing: 2, alignItems: "center", py: 10, children: [_jsx(Typography, { variant: "h3", color: "text.body", align: "center", maxWidth: 600, children: t('cybermon.healthBarText') }), _jsx(Stack, { direction: "row", spacing: 2, children: lives.map((num) => (_jsx(HealthBarSegment, { completed: num <= livesEarned, children: _jsx(Typography, { variant: "caption", color: "badge.text", children: num }) }, num))) })] }));
};
