import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { ChallengePlayerWrapper, Skeleton as ChallengePlayerWrapperSkeleton } from './ChallengePlayerWrapper';
import { useApiClient } from '../../ApiContext';
import { VideoPlayerBase } from '../../players/VideoPlayerBase';
import { MissionPlayerWrapper } from './MissionPlayerWrapper';
import { usePlatformNavigate } from '../../navigation';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CodingLabWrapper } from './CodingLabWrapper';
import { NavigationBar } from '../../navigation/NavigationBar';
import { GuidelinePlayer } from '../../players/GuidelinePlayer';
import { usePlatformContext } from '../../platformContext';
import { mapCategory } from '../../utils/mapper';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../commonStyle';
import { CompletedPage } from '../../../common/components/organisms/Activities/CompletedPage';
import { CompletedBanner } from '../../../common/components/organisms/Activities/CompletedBanner';
import { Divider } from '../../Divider';
import { CompletionModal } from './CompletionModal/CompletionModal';
import { questAnalytics } from '../QuestAnalytics';
import { WithFooter } from '../../footer/WithFooter';
import { NewCodingLabAvailable } from '../../../common/components/organisms/NewCodingLabAvailable/NewCodingLabAvailable';
export const QuestActivityFromRouteParams = () => {
    const { questId, chapterNr: cNr, topicNr: tNr, activityType, activityNr: aNr } = useParams();
    const chapterNr = parseInt(cNr !== null && cNr !== void 0 ? cNr : '');
    const topicNr = parseInt(tNr !== null && tNr !== void 0 ? tNr : '');
    const activityNr = parseInt(aNr !== null && aNr !== void 0 ? aNr : '');
    if (!questId ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(chapterNr) ||
        isNaN(topicNr) ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const props = { questId, chapterNr, topicNr, activityType, activityNr };
    return _jsx(QuestActivity, Object.assign({}, props));
};
// eslint-disable-next-line sonarjs/cognitive-complexity
const QuestActivity = (props) => {
    const location = useLocation();
    const navigate = usePlatformNavigate();
    const { trpc, handleErrorPromise } = useApiClient();
    const metadata = usePlatformContext().metadata;
    const [onMarkAsComplete, setOnMarkAsComplete] = useState();
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const secondsSpent = useRef();
    const [shouldShowCompletedModal, setShouldShowCompletedModal] = useState(false);
    const [shouldShowUpdatedLab, setShouldShowUpdatedLab] = useState(false);
    const [discardingLab, setDiscardingLab] = useState(false);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => questAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const [response, setResponse] = useState();
    const onVideoCompleted = useCallback((timeSpent) => {
        secondsSpent.current = Math.round(timeSpent);
        setEnableMarkAsComplete(true);
    }, []);
    const showCompletedModal = () => {
        setShouldShowCompletedModal(true);
        setEnableMarkAsComplete(false);
    };
    const fetch = (redoIfCompleted = false) => {
        setResponse(undefined);
        setEnableMarkAsComplete(false);
        setOnMarkAsComplete(undefined);
        setShouldShowCompletedModal(false);
        handleErrorPromise(trpc.quests.startOrContinueActivity.mutate(Object.assign(Object.assign({}, props), { redoIfCompleted })), (response) => {
            if (response.kind === 'ok') {
                if (response.activity.kind === 'guideline') {
                    const { category, contentId, attemptId } = response.activity;
                    setOnMarkAsComplete(() => {
                        return () => {
                            setEnableMarkAsComplete(false);
                            handleErrorPromise(trpc.quests.finishGuideline.mutate({ questId: props.questId, attemptId }), () => {
                                analytics.onActivityFinished(contentId, 'guideline', category);
                                showCompletedModal();
                            });
                        };
                    });
                }
                if (response.activity.kind === 'video') {
                    const videoId = response.activity.video.contentId;
                    const category = response.activity.video.category;
                    setOnMarkAsComplete(() => {
                        return () => {
                            setEnableMarkAsComplete(false);
                            handleErrorPromise(trpc.quests.finishVideo.mutate({
                                questId: props.questId,
                                videoId,
                                secondsSpent: secondsSpent.current,
                            }), () => {
                                analytics.onActivityFinished(videoId, 'video', category);
                                showCompletedModal();
                            });
                        };
                    });
                }
                if (response.activity.kind === 'coding_lab') {
                    setShouldShowUpdatedLab(!!response.activity.newVersionAvailable);
                }
            }
            setResponse(response);
        });
    };
    useEffect(fetch, [props.questId, props.chapterNr, props.topicNr, props.activityType, props.activityNr]); // eslint-disable-line react-hooks/exhaustive-deps
    const nextActivity = useCallback(() => {
        const nextActivity = response === null || response === void 0 ? void 0 : response.nextActivity;
        if (nextActivity) {
            analytics.onNextActivityClicked(nextActivity.contentId, nextActivity.activityType, nextActivity.category);
            navigate.to({
                pathname: `/quests/${props.questId}/chapter/${nextActivity.chapterNr}/topic/${nextActivity.topicNr}/${nextActivity.activityType}/${nextActivity.activityNr}`,
                search: '',
                hash: '',
            });
        }
    }, [navigate, props.questId, response, analytics]);
    const goBack = (component) => {
        var _a;
        analytics.onBackToQuestsClicked(component, props.activityType);
        return navigate.to(((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || { pathname: '/quests', search: '', hash: '' }, {
            state: { highlightedActivity: props },
        });
    };
    const discardCodingLab = (attemptId) => {
        setDiscardingLab(true);
        handleErrorPromise(trpc.codingLabs.discard.mutate({ attemptId }), () => {
            fetch();
            setDiscardingLab(false);
        });
    };
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const showPlayer = (response) => {
        const a = response.activity;
        return a.kind === 'video' ? (_jsx(VideoPlayerBase, { video: a.video, analyticsScope: "quests", onComplete: onVideoCompleted })) : a.kind === 'guideline' ? (_jsx(GuidelinePlayer, { id: a.contentId, analyticsScope: "quests", style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: a.guideline, title: mapCategory(a.category, metadata).name, category: a.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }) })) : a.kind === 'challenge' ? (_jsx(ChallengePlayerWrapper, { baseUrl: new URL(a.playerUrl), goBack: () => {
                analytics.onActivityFinished(a.contentId, 'challenge', a.category);
                navigate.to({ pathname: '/quests', search: '', hash: '' });
            } })) : a.kind === 'coding_lab' ? (_jsxs(_Fragment, { children: [shouldShowUpdatedLab && (_jsx(NewCodingLabAvailable, { onClickLoadOldSession: () => {
                        setShouldShowUpdatedLab(false);
                    }, onClickRestartLab: () => {
                        discardCodingLab(a.attemptId);
                    }, scope: "quests", labId: a.contentId.split(':')[0], disabled: discardingLab })), _jsx(CodingLabWrapper, { baseUrl: a.playerUrl, sessionId: a.sessionId, hidden: shouldShowUpdatedLab, goBack: () => {
                        analytics.onActivityFinished(a.contentId, 'coding_lab', a.category);
                        fetch();
                    } })] })) : a.kind === 'mission' || a.kind === 'walkthrough' ? (_jsx(MissionPlayerWrapper, { missionId: a.contentId, language: a.language, playerUrl: a.playerUrl, goBack: () => {
                analytics.onActivityFinished(a.contentId, a.kind, a.category);
                fetch();
            } })) : (assertNever(a, 'invalid state'));
    };
    const showCompleted = (response) => {
        var _a, _b;
        return ((_a = response.activity) === null || _a === void 0 ? void 0 : _a.kind) === 'guideline' ? (_jsxs(GuidelinePlayer, { id: response.activity.guidelineId, analyticsScope: "quests", style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: response.activity.guideline, title: mapCategory(response.activity.category, metadata).name, category: response.activity.category, onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), children: [_jsx(CompletedBanner, { onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {})] })) : ((_b = response.activity) === null || _b === void 0 ? void 0 : _b.kind) === 'video' ? (_jsxs(_Fragment, { children: [_jsx(CompletedBanner, { onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {}), _jsx(VideoPlayerBase, { video: response.activity.video, analyticsScope: "quests" })] })) : (_jsx(CompletedPage, { onClickStartRefresher: () => fetch(true) }));
    };
    return (_jsxs(WithFooter, { children: [_jsx(NavigationBar, { type: props.activityType, module: "quests", onBackNavigation: () => goBack('NavigationBar'), enableMarkAsComplete: enableMarkAsComplete, onMarkAsComplete: onMarkAsComplete, lastCompleted: shouldShowCompletedModal
                    ? new Date()
                    : (response === null || response === void 0 ? void 0 : response.kind) === 'already_completed'
                        ? response === null || response === void 0 ? void 0 : response.lastCompleted
                        : (response === null || response === void 0 ? void 0 : response.kind) === 'ok'
                            ? null
                            : undefined, onClickNextActivity: (response === null || response === void 0 ? void 0 : response.nextActivity) ? nextActivity : undefined }), response ? (response.kind === 'ok' ? (showPlayer(response)) : response.kind === 'already_completed' ? (showCompleted(response)) : (assertNever(response, 'invalid state'))) : props.activityType === 'video' ? (_jsx(VideoPlayerBase, { video: undefined, analyticsScope: "quests" })) : props.activityType === 'challenge' ? (_jsx(ChallengePlayerWrapperSkeleton, {})) : (_jsx(_Fragment, {})), _jsx(CompletionModal, { open: shouldShowCompletedModal, activityType: props.activityType, onBack: () => goBack('CompletionModal'), onNext: nextActivity, onBackDropClicked: () => fetch() })] }));
};
