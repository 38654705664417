import angular from 'angular';
import MODULE from './module';
import templateUrl from './preferred-dev-languages.html';

/**
 * Component: preferredDevLanguages
 *
 * Displays a list of preferred developer languages to be selected ad the output will be made available in $scope.enabledLanguages
 *
 * usage: <preferred-dev-languages></preferred-dev-languages>
 *
 */
angular.module(MODULE).directive('preferredDevLanguages', [
  function () {
    return {
      restrict: 'E',
      scope: {
        hidetitle: '<',
      },
      replace: true,
      transclude: true,
      templateUrl,
      controller: [
        '$scope',
        '$rootScope',
        'AdminApiService',
        'AuthService',
        'USER_ROLES',
        '$sce',
        'Session',
        'TrainingApiService',
        async function (
          $scope,
          $rootScope,
          AdminApiService,
          AuthService,
          USER_ROLES,
          $sce,
          Session,
          TrainingApiService
        ) {
          // Scope variables.
          $scope.limit = 10;
          $rootScope.enabledLanguages = [];
          $scope.availableLanguagesWithPreselection = [];
          $scope.hideShowMoreButton = true;
          $scope.getLangIcon = getLangIcon;
          $scope.hidetitle = AuthService.isAuthorized([USER_ROLES.admin]) || !!$scope.hidetitle;

          // Return the Trusted HTML for the icon to the view.
          function getLangIcon(language) {
            // return $sce.trustAsHtml( $scope.devLanguageIcons[language._id + '.' +language._framework] );
            return $sce.trustAsHtml('<i class="' + $rootScope.utility.languageIcon(language) + '"></i>');
          }

          $scope.preselectLanguages = (availableLanguages, enabledLanguages) => {
            return availableLanguages.map((language) => {
              const isSelected = enabledLanguages.find(
                (lang) => lang._id === language._id && lang._framework === language._framework
              );
              return {
                ...language,
                selected: !!isSelected,
              };
            });
          };

          // Get Company Enabled Languages
          var user = Session.user;
          // Grab the previous user enabled/selected languages.
          $rootScope.enabledLanguages = user.properties.profile.preferredDevLanguages || [];

          if (user.properties._cid) {
            const courseAvailableLanguageObject = {};

            try {
              const courseAvailableLanguages = await TrainingApiService.getCourseReadyLanguages();
              Object.values(courseAvailableLanguages)
                .flat()
                .forEach((lang) => {
                  courseAvailableLanguageObject[lang._id + '.' + lang._framework] = true;
                });
            } catch (error) {
              console.log(error);
            }

            if (AuthService.isAuthorized([USER_ROLES.companyAdmin])) {
              // TeamAdmin / CompanyAdmin should have access to AdminApiService for languages.
              AdminApiService.getCompany(user.properties._cid).then(function (company) {
                $scope.availableLanguages = company.languages;
                if (Object.keys(courseAvailableLanguageObject)?.length) {
                  $scope.availableLanguages = $scope.availableLanguages.filter(
                    (lang) => courseAvailableLanguageObject[lang._id + '.' + lang._framework]
                  );
                }

                $scope.numberMore = $scope.availableLanguages.length - $scope.limit;
                $scope.availableLanguagesWithPreselection = $scope.preselectLanguages(
                  $scope.availableLanguages,
                  $rootScope.enabledLanguages
                );
              });
            } else {
              // Normal Developer - Team manager
              if (user.properties.company.languages) {
                $scope.availableLanguages = user.properties.company.languages;
                if (Object.keys(courseAvailableLanguageObject)?.length) {
                  $scope.availableLanguages = $scope.availableLanguages.filter(
                    (lang) => courseAvailableLanguageObject[lang._id + '.' + lang._framework]
                  );
                }

                $scope.numberMore = $scope.availableLanguages.length - $scope.limit;
                $scope.availableLanguagesWithPreselection = $scope.preselectLanguages(
                  $scope.availableLanguages,
                  $rootScope.enabledLanguages
                );
              }
            }
          }

          // Toggle enabled/disabled dev-language component view.
          $scope.toggleStatus = function (language) {
            var enabledLangs = angular.copy($rootScope.enabledLanguages);
            var langIndex = _.findIndex(enabledLangs, function (lang) {
              return lang._id === language._id && lang._framework === language._framework;
            });
            if (langIndex > -1) {
              enabledLangs.splice(langIndex, 1);
            } else {
              // This is strictly not needed, the backend would ignore the extra property added.
              // Ignore the 'selected' property destructured by reassigning it (could add eslint-disable-next-line no-unused-vars as well)
              const { selected: _, ...selectedLanguage } = language; // remove the virtual property added
              enabledLangs.push(selectedLanguage);
            }
            language.selected = !language.selected;

            $rootScope.enabledLanguages = enabledLangs;
            user.properties.profile.preferredDevLanguages = enabledLangs;
          };
        },
      ],
    };
  },
]);
