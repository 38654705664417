import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { usePlatformNavigate } from '../navigation';
import { breadcrumbsHeight, createPlayerContainerStyle, platformHeaderHeight } from '../commonStyle';
import { usePlatformContext } from '../platformContext';
import { useApiClient } from '../ApiContext';
import { NavigationBar } from '../navigation/NavigationBar';
import { NewCodingLabAvailable } from '../../common/components/organisms/NewCodingLabAvailable/NewCodingLabAvailable';
import { Box } from '@mui/material';
const PLAYER_EVENTS = {
    BackToOverview: 'backToOverview',
    Completion: 'completion',
};
export const CodingLabWrapper = () => {
    var _a;
    const { _rawId } = useParams();
    if (!_rawId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const { t } = useTranslation();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const location = useLocation();
    const { trpc, handleErrorPromise } = useApiClient();
    const [response, setResponse] = useState(undefined);
    const [showUpdateAvailable, setShowUpdateAvailable] = useState(false);
    const [discardingLab, setDiscardingLab] = useState(false);
    const [labId, versionId] = _rawId.split(':');
    if (versionId !== undefined) {
        // LX-286: the coding lab version hash should not be in the url, but we used to have it.
        navigate.to({ pathname: '/explore/coding-lab/' + labId, search: '', hash: '' });
    }
    const fetch = () => {
        handleErrorPromise(trpc.explore.codingLabs.startOrContinue.mutate({ revisionId: labId }), (response) => {
            platformCtx.logAnalyticsEvent({
                event: 'Access Coding Lab',
                scope: 'explore',
                coding_lab_slug: labId,
            });
            setResponse(response);
            setShowUpdateAvailable(!!response.codingLab.newVersionAvailable);
            setDiscardingLab(false);
        });
    };
    useEffect(fetch, [labId, platformCtx, handleErrorPromise]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        const handleCodingLabsMessage = (message) => {
            var _a, _b;
            if (message.data.event === 'cl:analytics') {
                const { eventName, eventProps } = message.data;
                const routeProps = platformCtx.rawAnalyticsService.getRouteProps();
                platformCtx.rawAnalyticsService.logEvent(eventName, Object.assign(Object.assign({}, eventProps), routeProps));
            }
            if (message.data.event === PLAYER_EVENTS.Completion) {
                platformCtx.logAnalyticsEvent({
                    event: 'Completed Coding Lab',
                    scope: 'explore',
                    coding_lab_slug: labId,
                });
            }
            if (message.data.event === PLAYER_EVENTS.BackToOverview) {
                const backLocation = (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) !== null && _b !== void 0 ? _b : { pathname: '/explore', search: '' };
                platformCtx.clearExploreCacheOnNextRequest();
                navigate.to(backLocation);
            }
        };
        window.addEventListener('message', handleCodingLabsMessage);
        return () => window.removeEventListener('message', handleCodingLabsMessage);
    }, [(_a = location.state) === null || _a === void 0 ? void 0 : _a.from, navigate, platformCtx, labId]);
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({
            pathname: '/explore',
            search: '',
            hash: '',
        });
    }, [navigate, platformCtx]);
    const buildExternalPlayerUrl = (codingLab) => {
        const config = {
            sessionToken: codingLab.sessionId,
            navigation: {
                onFinished: {
                    backToOverview: t('pages.explore.back'),
                },
            },
        };
        const stringConfig = encodeURIComponent(JSON.stringify(config));
        return `${codingLab.externalPlayerUrl}#${stringConfig}`;
    };
    const discardCodingLab = (attemptId) => {
        setDiscardingLab(true);
        setResponse(undefined);
        handleErrorPromise(trpc.codingLabs.discard.mutate({ attemptId }), fetch);
    };
    const iframeStyle = useMemo(() => {
        const baseStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
        return showUpdateAvailable ? Object.assign(Object.assign({}, baseStyle), { display: 'none' }) : baseStyle;
    }, [showUpdateAvailable]);
    return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "coding_lab", module: "explore", onBackNavigation: navigateBack }), response ? (_jsxs(_Fragment, { children: [showUpdateAvailable && (_jsx(Box, { display: "flex", flexGrow: 1, minHeight: `calc(100vh - ${platformHeaderHeight + breadcrumbsHeight}px)`, children: _jsx(NewCodingLabAvailable, { onClickLoadOldSession: () => {
                                setShowUpdateAvailable(false);
                            }, onClickRestartLab: () => {
                                discardCodingLab(response.codingLab.attemptId);
                            }, disabled: discardingLab, scope: "explore", labId: labId }) })), _jsx("iframe", { src: buildExternalPlayerUrl(response.codingLab), id: "lesson-iframe", style: Object.assign(Object.assign({}, iframeStyle), { display: showUpdateAvailable ? 'none' : 'block' }), "aria-label": t('CODING_LAB'), title: t('common.activityType.codingLab'), "data-hj-allow-iframe": "", allow: "clipboard-read; clipboard-write; display-capture;" })] })) : (_jsx(_Fragment, {}))] }));
};
