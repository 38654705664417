import angular from 'angular';
import MODULE from './module';

angular
  .module(MODULE)
  .controller('ChangePasswordDialogController', [
    '$scope',
    '$timeout',
    '$uibModalInstance',
    'AuthService',
    'ErrorHandler',
    function ($scope, $timeout, $uibModalInstance, AuthService, ErrorHandler) {
      // Begin: exposed methods
      $scope.close = close;
      $scope.changePassword = changePassword;
      // End: exposed methods

      // Begin: exposed data
      $scope.changePasswordSuccess = false;
      $scope.newPasswordReq = {
        oldPass: '',
        newPass: '',
      };
      $scope.confirmPass = '';
      // End: exposed data

      // Begin: $watchers
      $scope.$watch(['newPasswordReq.newPass', 'confirmPass'], confirmPasswordValidity);
      // End: $watchers

      function confirmPasswordValidity() {
        $scope.forms.passwordForm.confirmPassword.$setValidity(
          'match',
          $scope.newPasswordReq.newPass == $scope.confirmPass
        );
      }

      function close() {
        $uibModalInstance.dismiss();
      }

      function changePassword() {
        var newPasswordReq = $scope.newPasswordReq;
        if ($scope.forms.passwordForm.$invalid) {
          $scope.forms.passwordForm.oldPassword.$dirty = true;
          $scope.forms.passwordForm.password.$dirty = true;
          $scope.forms.passwordForm.confirmPassword.$dirty = true;
          $scope.assignFocusToFirstAngularJSProfileFormInputWithAnError($scope.forms.passwordForm);

          return;
        }

        changePassword.inprogress = true;
        AuthService.changePassword(newPasswordReq)
          .then(function (_data) {
            changePassword.success = true;
            $timeout(function () {
              changePassword.success = false;
              close();
            }, 1500);
          })
          .catch(function (response) {
            $scope.changePasswordSuccess = false;
            ErrorHandler.addError(response.data.error);
          })
          .finally(function () {
            changePassword.inprogress = false;
          });
      }
    },
  ])
  .controller('ProfileController', [
    '$window',
    '$scope',
    '$rootScope',
    'AuthService',
    '$state',
    '$translate',
    '$uibModal',
    '$timeout',
    'ErrorHandler',
    'Session',
    'UserPreferences',
    'USER_ROLES',
    'AnalyticsService',
    'AnalyticsEvents',
    'RedirectService',
    function (
      $window,
      $scope,
      $rootScope,
      AuthService,
      $state,
      $translate,
      $uibModal,
      $timeout,
      ErrorHandler,
      Session,
      UserPreferences,
      USER_ROLES,
      AnalyticsService,
      AnalyticsEvents,
      RedirectService
    ) {
      // @tmp;
      $window._profile = $scope;
      $scope.userIsSSODomain = AuthService.isSSOUser();

      // Begin: exposed data
      $scope.ui = {};
      $scope.forms = {};
      $scope.languageSupported = $scope.metadata.constants.supported_languages;
      $scope.isPlatformManaged = false;
      // End: exposed data

      // Begin: exposed methods
      $scope.toggle = toggle;
      $scope.updateProfile = updateProfile;
      $scope.updatePreferences = updatePreferences;
      $scope.companyDefault = companyDefault;
      $scope.openChangePasswordDialog = openChangePasswordDialog;
      // End: exposed methods

      $scope.goHome = function () {
        RedirectService.goHome();
      };

      // Reassigning to scope so that all child forms can use this instead of adding a rootscope dependency there
      $scope.assignFocusToFirstAngularJSProfileFormInputWithAnError =
        $rootScope.assignFocusToFirstAngularJSFormInputWithAnError;

      init(); // Init

      function init() {
        $scope.languageSupported = Object.keys($scope.languageSupported)
          .map(function (i) {
            return { i18nLanguagePreferenceNameId: i, i18nLanguagePreferenceName: $scope.languageSupported[i] };
          })
          .sort();

        AuthService.resumeSession()
          .then(function (data) {
            UserPreferences.load(function (preferences) {
              $scope.userPreferences = preferences;
            });

            Session.updateUser(data);
            $scope.email = Session.user.email;
            $scope.isPlatformManaged = Session.user.properties.platformManaged;
            for (var i = 0; i < $scope.languageSupported.length; i++) {
              if (
                $scope.languageSupported[i].i18nLanguagePreferenceNameId ==
                Session.user.properties.profile.i18nLanguagePreference
              ) {
                $scope.profile = {
                  firstName: Session.user.properties.profile.name.first,
                  middleName: Session.user.properties.profile.name.middle,
                  lastName: Session.user.properties.profile.name.last,
                  i18nLanguagePreference: $scope.languageSupported[i],
                };
              }
            }

            if (AuthService.isAuthorized([USER_ROLES.assessmentUser])) {
              $scope.profileType = 'assessment-profile';
            } else if (AuthService.isAuthorized([USER_ROLES.reseller, USER_ROLES.companyAdmin, USER_ROLES.manager])) {
              $scope.profileType = 'mgmt-profile';
              $scope.mgmtProfileFirstUpdate = !Session.user.properties.profile.isComplete;
            } else {
              $scope.profileType = 'player-profile'; // lowest access-level by default
            }

            if (Session.user.properties.profile.isComplete) {
              $scope.changePasswordSuccess = false;
              $scope.newPasswordReq = {
                oldPass: '',
                newPass: '',
              };
              $scope.confirmPass = '';
            } else if ($scope.profileType == 'player-profile') {
              // user state should never reach this state (game profile complete but user profile incomplete)
              // treat like initial case
              swal({
                title: $translate.instant('WELCOME'),
                text: $translate.instant('WELCOME_TO_SCW_BEFORE_ACTIVITY_FILL_YOUR_CHAR_PROFILE'),
                type: 'info',
                confirmButton: $translate.instant('OK'),
              });
            }

            // Preferences are only applicable for managers and company admins
            $scope.displayAdminManager = AuthService.isAuthorized([USER_ROLES.manager, USER_ROLES.companyAdmin]);
            $scope.noCompany = !Session.user.properties._cid;
          })
          .catch(function (response) {
            ErrorHandler.addHttpError($translate.instant('FAILED_TO_RETRIEVE_USER_PROFILE'), response);
          });
      }

      function updatePreferences() {
        $scope.updatePreferences.inprogress = true;

        return UserPreferences.saveAll($scope.userPreferences)
          .then(function (response) {
            $scope.updatePreferences.success = true;
            $timeout(function () {
              $scope.updatePreferences.success = false;
            }, 1500);

            return response;
          })
          .catch(function (err) {
            ErrorHandler.addHttpError($translate.instant('Profile.Preferences.Errors.SaveFail'), err);
          })
          .finally(function () {
            $scope.updatePreferences.inprogress = false;
          });
      }

      function toggle(what) {
        _.set($scope, what, !_.get($scope, what));
      }

      function openChangePasswordDialog() {
        var changePasswordModalInstance = $uibModal.open({
          windowClass: 'modal-scw',
          templateUrl: 'profile/update-password.html',
          controller: 'ChangePasswordDialogController',
          size: 'md',
          scope: $scope,
        });

        return changePasswordModalInstance.result
          .then(function () {
            changePasswordModalInstance.close();
            // $state.go($state.current.name, $stateParams, {reload: true});
          })
          .catch(angular.noop);
      }

      function companyDefault(period) {
        var holder = _.get(Session.user, 'properties.preferences.emailReports') || {};
        var rolePath = AuthService.isAuthorized([$scope.userRoles.companyAdmin])
          ? 'digestCompanyAdmin'
          : 'digestTeamManager';
        holder = _.get(holder, rolePath) || {};
        return !!holder[period];
      }

      function updateProfile(email, profile) {
        if ($scope.forms.profileForm.$invalid) {
          $scope.forms.profileForm.email.$dirty = true;
          $scope.forms.profileForm.firstName.$dirty = true;
          $scope.forms.profileForm.middleName.$dirty = true;
          $scope.forms.profileForm.lastName.$dirty = true;
          $scope.forms.profileForm.i18nLanguagePreference.$dirty = true;
          $scope.assignFocusToFirstAngularJSProfileFormInputWithAnError($scope.forms.profileForm);

          return;
        }
        var postData = {
          email: email,
          profile: Session.getUserProfile(),
        };
        postData.profile.name = {};
        postData.profile.name.first = profile.firstName;
        postData.profile.name.middle = profile.middleName;
        postData.profile.name.last = profile.lastName;
        postData.profile.i18nLanguagePreference = profile.i18nLanguagePreference.i18nLanguagePreferenceNameId;
        postData.profile.preferredDevLanguages = $rootScope.enabledLanguages;

        // dummy values TODO-remove
        postData.profile.timezone = '+1000';
        //postData.profile.i18nLanguagePreference = "en";

        updateProfile.inprogress = true;
        return AuthService.updateProfile(postData)
          .then(function (data) {
            if (!Session.user.properties.profile.isComplete) {
              AnalyticsService.logEvent(AnalyticsEvents.Onboarding.COMPLETE_USER_PROFILE, {
                is_profile_complete: 'yes',
                page_used: 'profile',
              });
            }
            updateProfile.inprogress = false;
            updateProfile.success = true;
            $timeout(function () {
              updateProfile.success = false;
            }, 2500);

            var originalProfileIsComplete = Session.user.properties.profile.isComplete;
            Session.user.email = data.email;
            Session.updateUserProfile(data.profile);
            $rootScope.changeLanguage(data.profile.i18nLanguagePreference);
            $rootScope.getLocaleMetadata(data.profile.i18nLanguagePreference);
            $translate([
              'PLAY',
              'OK',
              'PROFILE_COMPLETE',
              'PLAYER_PROFILE_UPDATE_COMPLETE',
              'ASSESSMENT_PROFILE_UPDATE_COMPLETE',
              'MANAGEMENT_PROFILE_UPDATE_COMPLETE',
            ]).then(function (translations) {
              //Set translate to use updated user language preference.
              if ($scope.profileType == 'player-profile' && !originalProfileIsComplete) {
                swal(
                  {
                    title: translations.PROFILE_COMPLETE,
                    text: translations.PLAYER_PROFILE_UPDATE_COMPLETE,
                    type: 'success',
                    confirmButtonText: translations.PLAY,
                  },
                  function () {
                    if (
                      $rootScope.assessmentProfileIncomplete &&
                      $rootScope.assessmentProfileIncomplete.assessmentId &&
                      $rootScope.assessmentProfileIncomplete.attemptId
                    ) {
                      $state.go('assessments.view.attempt', {
                        assessmentId: $rootScope.assessmentProfileIncomplete.assessmentId,
                        attemptId: $rootScope.assessmentProfileIncomplete.attemptId,
                      });
                    } else {
                      if (Session.user.status == $rootScope.metadata.constants.user.status.READ_ONLY) {
                        $state.go('home');
                      } else if (
                        AuthService.isAuthorized([USER_ROLES.player, USER_ROLES.manager]) &&
                        Session.user.properties._cid &&
                        !Session.user.properties._tid
                      ) {
                        $state.go('waiting-assignment');
                      } else {
                        $state.go('game.013');
                      }
                    }
                  }
                );
                return;
              } else if ($scope.profileType == 'assessment-profile' && !originalProfileIsComplete) {
                swal(
                  {
                    title: translations.PROFILE_COMPLETE,
                    text: translations.ASSESSMENT_PROFILE_UPDATE_COMPLETE,
                    type: 'success',
                    confirmButtonText: translations.OK,
                  },
                  function () {
                    if ($rootScope.assessmentProfileIncomplete) {
                      $state.go('assessments.view.attempt', {
                        assessmentId: $rootScope.assessmentProfileIncomplete.assessmentId,
                        attemptId: $rootScope.assessmentProfileIncomplete.attemptId,
                      });
                    } else {
                      $state.go('assessments.list');
                    }
                  }
                );
                return;
              } else if ($scope.profileType == 'mgmt-profile' && $scope.mgmtProfileFirstUpdate) {
                swal(
                  {
                    title: translations.PROFILE_COMPLETE,
                    text: translations.MANAGEMENT_PROFILE_UPDATE_COMPLETE,
                    type: 'success',
                    confirmButtonText: translations.OK,
                  },
                  function () {
                    $state.go('admin');
                  }
                );
                return;
              }
            });
          })
          .catch(function (response) {
            updateProfile.success = false;
            $translate('UPDATE_PROFILE_ERROR').then(function (errorText) {
              ErrorHandler.addHttpError(errorText, response);
            });
          })
          .finally(function () {
            updateProfile.inprogress = false;
          });
      }
    },
  ]);
