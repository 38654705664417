import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { createContext, useContext, useCallback, useState } from 'react';
import superjson from 'superjson';
import { wrapPromise } from '@securecodewarrior/design-system-react';
//need to find out how to init properly while avoiding null
export const ApiContext = createContext(undefined);
export const createApiClient = (ctx) => {
    return createTRPCProxyClient({
        transformer: superjson,
        links: [
            httpBatchLink({
                url: `${ctx.apiEndpoint}/rpc`,
                headers: () => ({ 'X-Session-ID': ctx.sessionId }),
            }),
        ],
    });
};
export const useApiClient = () => {
    const ctx = useContext(ApiContext);
    if (!ctx) {
        throw new Error(`this component requires the api context to be present`);
    }
    const [_, setError] = useState();
    const handleErrorPromise = useCallback((promise, fulfilledFn) => {
        promise
            .then((data) => {
            assertNonErrorResponse(data);
            fulfilledFn === null || fulfilledFn === void 0 ? void 0 : fulfilledFn(data);
        })
            .catch((e) => {
            setError(() => {
                throw e;
            });
        });
    }, []);
    const wrapErrorPromise = useCallback((promise) => {
        return wrapPromise(promise.then((data) => {
            assertNonErrorResponse(data);
            return data;
        }));
    }, []);
    const trpc = createApiClient(ctx);
    return { trpc, wrapErrorPromise, handleErrorPromise };
};
export const useFetchWithPlatformSessionHeader = () => {
    const ctx = useContext(ApiContext);
    if (!ctx) {
        throw new Error(`this component requires the api context to be present`);
    }
    // We return a fairly standard DOM Fetch function, except that it automatically adds
    // the Platform Session ID header (X-Session-ID) to *every* request.
    const fetchWithSession = useCallback((input, init) => {
        const newInit = Object.assign(Object.assign({}, init), { headers: Object.assign(Object.assign({}, init === null || init === void 0 ? void 0 : init.headers), { 'X-Session-ID': ctx.sessionId }) });
        return window.fetch(input, newInit);
    }, [ctx.sessionId]);
    return { fetchWithSession, apiEndpoint: ctx.apiEndpoint };
};
const isErrorResponse = (data) => {
    return typeof data === 'object' && data !== null && 'kind' in data && data.kind === 'error';
};
function assertNonErrorResponse(value) {
    if (isErrorResponse(value)) {
        throw new Error(value.message);
    }
}
