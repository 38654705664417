import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, useTheme } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { Text } from '@securecodewarrior/design-system-react/lib/legacy';
import { FilterAltRounded as FilterIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useModal } from '../context/ModalContext';
import { useCybermonActivityFilter } from '../hooks/useCybermonActivityFilter';
const SelectedLanguages = ({ languages }) => {
    const theme = useTheme();
    const { selectedLanguages } = useCybermonActivityFilter();
    if (!selectedLanguages || !languages || selectedLanguages.length === 0) {
        return null;
    }
    const shouldTruncate = selectedLanguages.length > 5;
    const displayLanguages = shouldTruncate ? selectedLanguages.slice(0, 5) : selectedLanguages;
    return (_jsx(Stack, { direction: "row", gap: 4, children: displayLanguages.map((language, idx) => {
            var _a, _b, _c;
            const label = idx > 3 && shouldTruncate ? `+${selectedLanguages.length - 4}` : (_b = (_a = languages[language]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '';
            const icon = idx > 3 && shouldTruncate ? 'devicon-devicon-plain' : (_c = languages[language]) === null || _c === void 0 ? void 0 : _c.iconClassName;
            return (_jsx(Text, { typographyProps: { color: theme.palette.text.soft }, label: label, startIcon: _jsx("i", { style: { color: theme.palette.text.soft }, className: icon }) }, `selected_language_${language}`));
        }) }));
};
const SelectLanguagesButton = ({ languages }) => {
    const { setLanguageModalOpen } = useModal();
    const { selectedLanguages } = useCybermonActivityFilter();
    const { t } = useTranslation('react_components', { keyPrefix: 'components.organisms.languageSelection.filter' });
    return !selectedLanguages || !languages ? (_jsx(Skeleton, { height: "30px", width: "135px", variant: "rounded" })) : (_jsx(Button, { onClick: () => setLanguageModalOpen(true), variant: "text", startIcon: _jsx(FilterIcon, {}), children: t((selectedLanguages === null || selectedLanguages === void 0 ? void 0 : selectedLanguages.length) === 0 ? 'select' : 'change') }));
};
export const LanguageSelect = ({ languages }) => {
    return (_jsxs(Stack, { direction: "row", gap: 4, children: [_jsx(SelectLanguagesButton, { languages: languages }), _jsx(SelectedLanguages, { languages: languages })] }));
};
