var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommunicationsSection as Section } from '../Communications.styled';
import { OptimisticSwitch } from './OptimisticSwitch';
import { useCommunicationPreferences } from '../hooks/useCommunicationPreferences';
import { communicationsAnalytics } from '../Communications.analytics';
import { usePlatformContext } from '../../platformContext';
export const CommunicationEvents = ({ showToaster, goalsFeatureEnabled }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { preferences, isLoading, savePreferences } = useCommunicationPreferences({ showToaster });
    const { logAnalyticsEvent, setSessionUserGoalEmails } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const handleInactivityReminderChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield savePreferences({
            communicationEvents: Object.assign(Object.assign({}, preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents), { sendAutomatedInactivityReminders: value }),
        });
        analytics.inactivityReminder.onToggle(value);
    });
    const handleScimEmailsChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield savePreferences({
            communicationEvents: Object.assign(Object.assign({}, preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents), { scimSendInvitationEmails: value }),
        });
        analytics.scimEmails.onToggle(value);
    });
    const handleGoalEmailsChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield savePreferences({
            communicationEvents: Object.assign(Object.assign({}, preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents), { goalEmailsEnabled: value }),
        });
        setSessionUserGoalEmails(value);
        analytics.goalEmailsEnabled.onToggle(value);
    });
    return (_jsxs(Section, { children: [_jsx(Typography, { variant: "h5", children: t('pages.administration.communicationsCentre.communicationEvents.title') }), _jsxs(Stack, { gap: 8, children: [_jsx(OptimisticSwitch, { title: t('pages.administration.communicationsCentre.communicationEvents.automatedInactivityReminder.title'), description: t('pages.administration.communicationsCentre.communicationEvents.automatedInactivityReminder.caption'), checked: (_a = preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents) === null || _a === void 0 ? void 0 : _a.sendAutomatedInactivityReminders, disabled: isLoading, onSave: (value) => handleInactivityReminderChange(value), inputProps: {
                            'aria-label': t('pages.administration.communicationsCentre.communicationEvents.automatedInactivityReminder.title'),
                        } }), _jsx(OptimisticSwitch, { title: t('pages.administration.communicationsCentre.communicationEvents.invitationEmailToSCIM.title'), description: t('pages.administration.communicationsCentre.communicationEvents.invitationEmailToSCIM.caption'), checked: (_b = preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents) === null || _b === void 0 ? void 0 : _b.scimSendInvitationEmails, disabled: isLoading, onSave: (value) => handleScimEmailsChange(value), inputProps: {
                            'aria-label': t('pages.administration.communicationsCentre.communicationEvents.invitationEmailToSCIM.title'),
                        } }), goalsFeatureEnabled && (_jsx(OptimisticSwitch, { title: t('pages.administration.communicationsCentre.communicationEvents.goalEmailsEnabled.title'), description: t('pages.administration.communicationsCentre.communicationEvents.goalEmailsEnabled.caption'), checked: (_c = preferences === null || preferences === void 0 ? void 0 : preferences.communicationEvents) === null || _c === void 0 ? void 0 : _c.goalEmailsEnabled, disabled: isLoading, onSave: (value) => handleGoalEmailsChange(value), inputProps: {
                            'aria-label': t('pages.administration.communicationsCentre.communicationEvents.goalEmailsEnabled.title'),
                        } }))] })] }));
};
