var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommunicationsSection as Section } from '../Communications.styled';
import { OptimisticSwitch } from './OptimisticSwitch';
import { useCommunicationPreferences } from '../hooks/useCommunicationPreferences';
import { communicationsAnalytics } from '../Communications.analytics';
import { usePlatformContext } from '../../platformContext';
export const EmailSettings = ({ showToaster }) => {
    var _a;
    const { t } = useTranslation();
    const { preferences, isLoading, savePreferences } = useCommunicationPreferences({ showToaster });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = communicationsAnalytics(logAnalyticsEvent);
    const handleIcsAttachmentsChange = (value) => __awaiter(void 0, void 0, void 0, function* () {
        yield savePreferences({
            emailSettings: {
                addICSAttachmentsToEmails: value,
            },
        });
        analytics.icsAttachments.onToggle(value);
    });
    return (_jsxs(Section, { children: [_jsx(Typography, { variant: "h5", children: t('pages.administration.communicationsCentre.emailSettings.title') }), _jsx(OptimisticSwitch, { title: t('pages.administration.communicationsCentre.emailSettings.icsAttachments.title'), description: t('pages.administration.communicationsCentre.emailSettings.icsAttachments.caption'), checked: (_a = preferences === null || preferences === void 0 ? void 0 : preferences.emailSettings) === null || _a === void 0 ? void 0 : _a.addICSAttachmentsToEmails, disabled: isLoading, onSave: handleIcsAttachmentsChange, inputProps: {
                    'aria-label': t('pages.administration.communicationsCentre.emailSettings.icsAttachments.title'),
                } })] }));
};
