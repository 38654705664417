import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { CybermonView } from './CybermonView';
import { usePlatformNavigate } from '../navigation';
import { usePlatformContext } from '../platformContext';
import { useExploreFilterContext } from '../explore/FilterContext';
import { possibleStatusOptions, createFilteringEvent, possibleActivityTypeOptions } from '../explore/FilterLogic';
import { hasLanguageInformation, isMission, isVideo, isWalkThrough } from '../explore/ContentItem';
import { useApiClient } from '../ApiContext';
import { backendToFrontend } from '../utils/mapper';
import { Suspenser, wrapPromise } from '@securecodewarrior/design-system-react';
import { ExploreFilterContextFromBrowserUrlAndLocalstorage } from '../explore/Explore';
import { ExploreSkeleton } from '../explore/Explore.skeleton';
import { useCybermonActivityFilter } from './hooks/useCybermonActivityFilter';
export const CybermonRecommendedActivities = () => {
    const ctx = usePlatformContext();
    const { trpc } = useApiClient();
    const navigate = usePlatformNavigate();
    const isAuthenticated = !!ctx.sessionUser;
    const availableContent = trpc.explore.availableContent;
    const getUserLanguageFrameworks = trpc.recommendationEngine.getUserLanguageFrameworks;
    const request = useMemo(() => {
        const contentPromise = availableContent.query();
        const filterPromise = getUserLanguageFrameworks.query();
        return wrapPromise(Promise.all([contentPromise, filterPromise]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { metadata } = usePlatformContext();
    const onLoginClicked = useCallback(() => {
        navigate.setRequestedState('cybermon', { path: '' });
        return navigate.toState('login');
    }, [navigate]);
    return (_jsx(Suspenser, { reader: request, skeleton: _jsx(ExploreSkeleton, { isAuthenticated: isAuthenticated, onLoginClicked: onLoginClicked }), render: ([contentData, filterData]) => {
            const items = backendToFrontend(contentData.items, metadata);
            const userLanguageFrameworks = filterData.kind === 'ok' ? filterData.userLanguageFrameworks : undefined;
            return (_jsx(ExploreFilterContextFromBrowserUrlAndLocalstorage, { items: items, children: _jsx(Cybermon, { items: items, onLoginClicked: onLoginClicked, initialUserLanguageFrameworks: userLanguageFrameworks }) }));
        } }));
};
export const Cybermon = (props) => {
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const isAuthenticated = !!sessionUser;
    const navigate = usePlatformNavigate();
    const filterStore = useExploreFilterContext().getState();
    logAnalyticsEvent(createFilteringEvent(filterStore.filterState, filterStore.filteredItems.length));
    const filterOptions = useMemo(() => {
        const languageEntries = props.items
            .filter(hasLanguageInformation)
            .map((i) => [i.language.id, i.language])
            .sort(([_aId, { label: aLabel }], [_bId, { label: bLabel }]) => aLabel.localeCompare(bLabel));
        return {
            categories: Object.fromEntries(props.items.map((i) => [i.category.id, i.category])),
            languages: Object.fromEntries(languageEntries),
            activityTypes: isAuthenticated ? possibleActivityTypeOptions : { video: possibleActivityTypeOptions.video },
            activityStatus: possibleStatusOptions,
        };
    }, [props.items, isAuthenticated]);
    const gotoActivity = useCallback((item) => {
        const extraData = {
            category: item.category.id,
            language: isVideo(item) ? '' : item.language.id,
            status: item.status,
            title: item.title,
            activityType: item.type,
        };
        logAnalyticsEvent(Object.assign({ event: 'click', scope: 'explore', type: 'button', name: 'play activity' }, extraData));
        const nextPath = isMission(item) || isWalkThrough(item)
            ? `${activityPathRecord[item.type]}/${item.contentId}/${item.language.id}`
            : `${activityPathRecord[item.type]}/${item.contentId}`;
        const path = {
            pathname: nextPath,
            search: '',
            hash: '',
        };
        navigate.to(path);
    }, [logAnalyticsEvent, navigate]);
    useCybermonActivityFilter(props.initialUserLanguageFrameworks);
    return (_jsx(CybermonView, { filterOptions: filterOptions, onActivitySelected: gotoActivity, isAuthenticated: isAuthenticated, onLoginClicked: props.onLoginClicked, unfilteredActivityCount: props.items.length }));
};
const activityPathRecord = {
    coding_lab: '/cybermon/coding-lab',
    video: '/cybermon/video',
    challenge: '/cybermon/challenge',
    guideline: '/cybermon/guideline',
    mission: '/cybermon/mission',
    walkthrough: '/cybermon/walkthrough',
};
