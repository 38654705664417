var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { CheckRounded as CompletedIcon, PauseRounded as PauseIcon, PlayArrowRounded as PlayIcon, } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const statusPalette = {
    completed: 'success',
    not_started: 'primary',
    in_progress: 'inProgress',
};
const statusTranslations = {
    completed: 'common.status.completed',
    not_started: 'common.status.notStarted',
    in_progress: 'common.status.inProgress',
};
const iconMap = {
    completed: CompletedIcon,
    in_progress: PauseIcon,
    not_started: PlayIcon,
};
export const StatusIcon = (props) => {
    const { t } = useTranslation();
    const { status } = props, boxProps = __rest(props, ["status"]);
    const Icon = iconMap[props.status];
    return (_jsx(Box, Object.assign({ "aria-label": t(statusTranslations[status]), role: "img", borderRadius: "50%", display: "inline-flex", alignItems: "center", justifyContent: "center", p: 1, border: (theme) => `1px solid ${theme.palette.badge[statusPalette[status]].border}`, bgcolor: (theme) => theme.palette.badge[statusPalette[status]].fill }, boxProps, { children: _jsx(Icon, { fontSize: "small" }) })));
};
