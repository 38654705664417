import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { visuallyHidden } from '@mui/utils';
import { Box, Stack, Typography } from '@mui/material';
import { NewReleases } from '@mui/icons-material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
import { HealthBar } from './components/HealthBar';
import { CybermonDetail } from './components/CybermonDetail';
import { WeeklyCybermon } from './components/WeeklyCybermon';
import { Activities } from './components/Activities';
export const CybermonView = ({ filterOptions, onActivitySelected, isAuthenticated, onLoginClicked, unfilteredActivityCount, }) => {
    const { t } = useTranslation();
    return (_jsxs("main", { children: [_jsxs(Box, { sx: visuallyHidden, children: [_jsx("h1", { children: t('pages.explore.title') }), _jsx("h2", { children: t('common.pages.about.accessibility') }), _jsx("p", { children: t('pages.explore.a11yInfo') })] }), _jsxs(Box, { display: "grid", gap: 6, py: 6, gridTemplateColumns: "4fr 8fr", children: [_jsx(Stack, { direction: "row", justifyContent: "space-between", style: { gridColumn: '1 / 3' }, children: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 3, children: [_jsx(NewReleases, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(40), color: 'text.soft' }) }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "overline", children: "Cyber Security Awareness Month" }), _jsx(Typography, { component: "h2", variant: "h2", children: t('cybermon.title') })] })] }) }), _jsxs(Card, { gap: 2, display: "flex", flexDirection: "column", children: [_jsx(Typography, { children: t('cybermon.introductionParagraph1') }), _jsx(Typography, { children: t('cybermon.introductionParagraph2') }), _jsx(Typography, { variant: "body0", fontWeight: "bold", children: t('cybermon.introductionParagraph3') })] }), _jsx(WeeklyCybermon, {}), _jsx(CybermonDetail, {}), _jsx(Typography, { component: "h2", sx: visuallyHidden, children: t('pages.explore.contentList') }), _jsxs(Stack, { gap: 5, flexGrow: 1, children: [_jsx(HealthBar, {}), _jsx(Activities, { filterOptions: filterOptions, onLoginClicked: onLoginClicked, isAuthenticated: isAuthenticated, onActivitySelected: onActivitySelected, unfilteredActivityCount: unfilteredActivityCount })] })] })] }));
};
