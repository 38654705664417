import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { Trophy } from './Trophy';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { ReplayOutlined } from '@mui/icons-material';
import { confettiOverlay } from './ConfettiOverlay';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../../../scw-react/commonStyle';
import { Trans, useTranslation } from 'react-i18next';
/**
 * @deprecated Use `ActivityStatusPage` instead
 */
export const CompletedPage = (props) => {
    const { t } = useTranslation();
    return (_jsx(Box, { sx: Object.assign(Object.assign({ backgroundImage: confettiOverlay, backgroundPosition: 'center' }, createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true })), { overflow: 'auto' }), children: _jsx(Box, { component: "div", display: "flex", justifyContent: "center", width: "100%", padding: "4em", children: _jsxs(Stack, { sx: {
                    width: '900px',
                    maxWidth: '900px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 5,
                    p: 10,
                    borderRadius: 3,
                }, bgcolor: (style) => style.palette.container.fill.card1, border: (style) => `1px solid ${style.palette.container.border.default}`, children: [_jsx(Trophy, { sx: { width: '215px', height: '150px' } }), _jsx(Typography, { variant: "h1", maxWidth: "635px", textAlign: "center", children: _jsx(Trans, { i18nKey: "components.organisms.activityStatus.title.already_completed", children: _jsx(Typography, { component: "em", sx: { font: 'inherit' }, color: (style) => style.palette.text.status.success }) }) }), _jsx(Typography, { sx: (theme) => ({ color: theme.palette.text.body }), variant: "subtitle1", maxWidth: "502px", textAlign: "center", children: t('components.organisms.activityStatus.descriptionPage.already_completed') }), _jsx(Button, { sx: { px: 6, py: 2 }, onClick: props.onClickStartRefresher, variant: "text", startIcon: _jsx(ReplayOutlined, {}), children: t('components.organisms.activityStatus.startRefresher') })] }) }) }));
};
