import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { HeartBrokenSharp, PlayArrowRounded, PauseRounded, CheckRounded } from '@mui/icons-material';
import { statusTranslations, possibleActivityTypeOptions } from '../../explore/FilterLogic';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../common/Tooltip';
const actionTranslationsMap = {
    in_progress: 'pages.explore.actions.inprogress',
    completed: 'pages.explore.actions.completed',
    not_started: 'pages.explore.actions.notstarted',
};
const iconMap = {
    not_started: PlayArrowRounded,
    in_progress: PauseRounded,
    completed: CheckRounded,
};
const LivesBadge = (props) => {
    const { t } = useTranslation();
    const contentKey = `cybermon.cybermon${props.type === 'coding_lab' ? 'Lives' : 'Life'}`;
    return (_jsx("span", { className: "cl-badge default", children: _jsxs("div", { className: "text-with-icon", children: [_jsx(HeartBrokenSharp, { className: "health" }), props.type === 'coding_lab' && _jsx(HeartBrokenSharp, { className: "health" }), _jsx("span", { children: t(contentKey) })] }) }));
};
const LanguageBadge = (props) => {
    const language = props.type === 'video' ? undefined : props.language;
    if (!language)
        return null;
    return (_jsx("span", { className: "cl-badge default", children: _jsxs("div", { className: "text-with-icon", children: [_jsx("i", { className: language.iconClassName }), _jsx("span", { children: language.label })] }) }));
};
function ActivityContentCard(props) {
    const { t } = useTranslation();
    const StatusIcon = iconMap[props.status];
    const activity = possibleActivityTypeOptions[props.type];
    const status = statusTranslations[props.status];
    const handleClick = () => {
        var _a;
        const selection = (_a = window === null || window === void 0 ? void 0 : window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString();
        if (selection)
            return;
        props.onClick();
    };
    const handleKeyUp = (e) => {
        if (e.key === 'Enter' || e.key === 'Space')
            props.onClick();
    };
    return (_jsxs("div", { className: "card-container", role: "button", tabIndex: 0, onClick: handleClick, onKeyUp: handleKeyUp, "aria-label": t(actionTranslationsMap[props.status], { title: props.title, type: t(activity.label) }), children: [_jsxs("div", { className: "card-info", children: [_jsx("div", { className: "activity-type-info", children: _jsxs("span", { className: "text-with-icon", children: [activity.icon, t(activity.label)] }) }), _jsx("div", { className: "play-button", children: _jsx(Tooltip, { title: t(status), arrow: true, children: _jsx("div", { className: `play-circle ${props.status}`, children: _jsx(StatusIcon, { "aria-label": t(status), "aria-hidden": false }) }) }) })] }), _jsxs("div", { className: "content", children: [_jsxs("div", { className: "content-text", children: [_jsx("h3", { className: "card-title", children: props.title }), props.description && _jsx("p", { children: props.description })] }), _jsxs("div", { className: "badges", children: [_jsx(LivesBadge, { type: props.type }), _jsx(LanguageBadge, Object.assign({}, props)), _jsx("span", { className: "cl-badge default", children: props.category.name })] })] })] }));
}
export const ActivityCard = memo(ActivityContentCard);
