import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { statusToBadgeMap, goalsAdminUrls } from '../constants';
import { useLocation, useParams } from 'react-router-dom';
import GoalEditor from '../components/organisms/goalEditor/GoalEditor';
import { MainContainer } from '../components/molecules/MainContainer';
import { Header } from '../components/molecules/Header';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../components/molecules/SectionContainer';
import { BackLink } from '../components/molecules/BackLink';
import { P, match } from 'ts-pattern';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ConfirmationDialog from '../components/molecules/ConfirmationDialog/ConfirmationDialog';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SnackbarAlert from '../components/molecules/SnackbarAlert';
import { usePlatformContext } from '../../platformContext';
import { useGoalsAdminApi } from '../hooks/useGoalsAdminApi';
import { Pending, useApiLoad, useApiMutation } from '../../Api.hooks';
import { Badge } from '../components/atoms/Badge';
import { getAvailableCategories, getGoalBuffer, getGoalValidationErrors, getNewGoalState, isCleanBuffer, } from '../utils';
import { usePlatformNavigate } from '../../navigation';
import { GoalEditorGeneralAndBadgeSection } from '../components/organisms/goalEditor/GoalEditorGeneralAndBadgeSection';
import { GoalEditorObjectivesSection } from '../components/organisms/goalEditor/GoalEditorObjectivesSection';
import { GoalEditorOtherSettingsSection } from '../components/organisms/goalEditor/GoalEditorOtherSettingsSection';
import { goalDetailsAnalytics } from '../analytics/GoalDetails.analytics';
import { ActionPanel } from '../components/organisms/actionPanel/ActionPanel';
import { ConfirmPublishDialog } from '../components/organisms/confirmPublishDialog/ConfirmPublishDialog';
export const EditGoalRoute = () => {
    const { goalId } = useParams();
    if (!goalId) {
        throw new Error('Missing goalId');
    }
    return _jsx(EditGoalLoader, { goalId: goalId });
};
export const CreateGoalRoute = () => {
    return _jsx(CreateGoalLoader, {});
};
export const CreateGoalLoader = () => {
    const api = useGoalsAdminApi();
    const { t } = useTranslation();
    const availableCategorySlugs = useApiLoad(api.availableCategorySlugs.query, undefined);
    const metadata = usePlatformContext().metadata;
    return match(availableCategorySlugs)
        .with(Pending, () => _jsx(PendingEditGoal, { createMode: true }))
        .with(P.instanceOf(Error), (error) => t('pages.multiassessments.error', { message: error.message }))
        .with({ status: 'ok' }, ({ data: slugs }) => {
        return (_jsx(EditGoal, { initialGoalDetails: getNewGoalState(new Date()), availableCategories: getAvailableCategories(slugs, metadata) }));
    })
        .exhaustive();
};
export const EditGoalLoader = ({ goalId }) => {
    const api = useGoalsAdminApi();
    const { t } = useTranslation();
    const details = useApiLoad(api.details.query, { goalId });
    const availableCategorySlugs = useApiLoad(api.availableCategorySlugs.query, undefined);
    const metadata = usePlatformContext().metadata;
    return match(details)
        .with(Pending, () => _jsx(PendingEditGoal, { createMode: false }))
        .with(P.instanceOf(Error), (error) => t('pages.multiassessments.error', { message: error.message }))
        .with({ status: 'ok' }, ({ data }) => {
        return match(availableCategorySlugs)
            .with(Pending, () => '')
            .with(P.instanceOf(Error), (error) => t('pages.multiassessments.error', { message: error.message }))
            .with({ status: 'ok' }, ({ data: slugs }) => {
            return _jsx(EditGoal, { initialGoalDetails: data, availableCategories: getAvailableCategories(slugs, metadata) });
        })
            .exhaustive();
    })
        .exhaustive();
};
const PendingEditGoal = ({ createMode }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { state } = useLocation();
    const goalName = (_a = state === null || state === void 0 ? void 0 : state.goalName) !== null && _a !== void 0 ? _a : '';
    const goalStatus = (_b = state === null || state === void 0 ? void 0 : state.status) !== null && _b !== void 0 ? _b : '';
    return (_jsxs(MainContainer, { children: [_jsx(Header, { children: _jsxs(Stack, { sx: (theme) => ({ marginBottom: theme.typography.pxToRem(6) }), children: [_jsx(BackLink, { to: goalsAdminUrls.listPage, children: t('goalsAdmin.pages.edit.backButton') }), _jsxs(Stack, { direction: "row", alignItems: "center", gap: (theme) => theme.typography.pxToRem(10), children: [_jsx(Typography, { component: "h1", variant: "h2", children: goalName || t('goalsAdmin.components.organisms.goalEditor.sections.general.goalNameInputLabel') }), goalStatus && (_jsx(Badge, { label: (_c = t('goalsAdmin.entities.goals.status.' + goalStatus)) !== null && _c !== void 0 ? _c : '', variant: statusToBadgeMap[goalStatus] }))] })] }) }), _jsx(Divider, {}), _jsxs(Stack, { direction: "row", sx: { minHeight: 0, flex: 1 }, children: [_jsx(Box, { sx: { flex: 1 }, children: _jsxs(SectionContainer, { isCentered: false, children: [_jsx(GoalEditorGeneralAndBadgeSection, { name: goalName, isMandatory: false, badge: null, status: "new", validationErrors: [], skeletonMode: createMode ? 'create' : 'edit', editingDisabled: true }), _jsx(GoalEditorObjectivesSection, { selectedCategories: [], availableCategories: [], status: "new", skeletonMode: createMode ? 'create' : 'edit', editingDisabled: true }), _jsx(GoalEditorOtherSettingsSection, { startDate: new Date(), endDate: new Date(), status: "new", validationErrors: [], skeletonMode: createMode ? 'create' : 'edit', editingDisabled: true })] }) }), goalStatus !== 'archived' && (_jsx(Box, { sx: { width: '400px' }, children: _jsx(ActionPanel, { status: goalStatus, isFormDirty: false, validationErrors: [], skeletonMode: true, isPerformingMutation: false }) }))] })] }));
};
export const EditGoal = ({ initialGoalDetails, availableCategories }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const { state } = useLocation();
    const api = useGoalsAdminApi();
    const navigate = usePlatformNavigate();
    const [isPerformingMutation, setIsPerformingMutation] = useState(false);
    const newlyCreatedDraftGoalId = (_a = state === null || state === void 0 ? void 0 : state.newlyCreatedDraftGoalId) !== null && _a !== void 0 ? _a : null;
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = useMemo(() => goalDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const [goalBuffer, setGoalBuffer] = useState(getGoalBuffer(initialGoalDetails));
    const [lastSavedRevision, setLastSavedRevision] = useState(initialGoalDetails);
    const updateGoalBuffer = (goalDetails) => {
        setGoalBuffer(getGoalBuffer(goalDetails));
        setLastSavedRevision(goalDetails);
        setIsFormDirty(false);
    };
    useEffect(() => {
        if (newlyCreatedDraftGoalId) {
            triggerSnackbar(t('goalsAdmin.pages.edit.alerts.draftCreatedSuccessfully'), 'success');
        }
    }, [newlyCreatedDraftGoalId, t]);
    const publish = useApiMutation(api.create.mutate, () => {
        analytics.onNewGoalCreated(goalBuffer);
        navigate.to({ pathname: goalsAdminUrls.listPage, hash: '', search: '' }, {
            state: { newlyCreatedGoalId: goalBuffer.goalId },
        });
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToPublishGoal'), 'error');
    });
    const createDraft = useApiMutation(api.createDraft.mutate, ({ goalId }) => {
        analytics.onDraftCreated(goalBuffer);
        navigate.to({ pathname: goalsAdminUrls.detailsPage(goalId), hash: '', search: '' }, {
            state: { newlyCreatedDraftGoalId: goalId },
        });
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToCreateDraft'), 'error');
    });
    const editDraft = useApiMutation(api.editDraft.mutate, (result) => {
        setIsPerformingMutation(false);
        analytics.onChangesSaved(goalBuffer.status, goalBuffer, lastSavedRevision, result.data.status);
        updateGoalBuffer(result.data);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.draftSavedSuccessfully'), 'success');
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToSaveDraft'), 'error');
    });
    const archive = useApiMutation(api.archive.mutate, (result) => {
        setIsPerformingMutation(false);
        updateGoalBuffer(result.data);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.goalArchivedSuccessfully'), 'success');
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToArchiveGoal'), 'error');
    });
    const deleteMutation = useApiMutation(api.delete.mutate, () => {
        navigate.to({ pathname: goalsAdminUrls.listPage, hash: '', search: '' }, {
            state: { newlyDeletedGoalId: goalBuffer.goalId },
        });
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToDeleteGoal'), 'error');
    });
    const deleteDraftMutation = useApiMutation(api.deleteDraft.mutate, () => {
        navigate.to({ pathname: goalsAdminUrls.listPage, hash: '', search: '' }, {
            state: { newlyDeletedGoalId: goalBuffer.goalId, deletedDraft: true },
        });
    }, () => {
        setIsPerformingMutation(false);
        triggerSnackbar(t('goalsAdmin.pages.edit.alerts.failedToDiscardDraft'), 'error');
    });
    const [isFormDirty, setIsFormDirty] = useState(false);
    // const { blocker } = useUnsavedChangesWarning(isFormDirty);
    const blocker = { state: 'not-blocked', proceed: () => { }, reset: () => { } };
    const [showArchiveDialog, setShowArchiveDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDeleteDraftDialog, setShowDeleteDraftDialog] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const saveGoal = useCallback(() => {
        setIsPerformingMutation(true);
        if (lastSavedRevision.status === 'draft') {
            editDraft.mutate({ goal: goalBuffer });
        }
        else if (goalBuffer.status === 'new') {
            createDraft.mutate({ draft: goalBuffer });
        }
    }, [editDraft, createDraft, goalBuffer, lastSavedRevision]);
    const archiveGoal = (goal) => {
        setIsPerformingMutation(true);
        archive.mutate({ goal });
    };
    const getPublishGoalInput = (goalBuffer) => {
        if (goalBuffer.startDate === null) {
            throw new Error('Cannot publish goal with missing start date');
        }
        if (goalBuffer.endDate === null) {
            throw new Error('Cannot publish goal with missing end date');
        }
        return {
            goalId: goalBuffer.goalId,
            name: goalBuffer.name,
            startDate: goalBuffer.startDate,
            endDate: goalBuffer.endDate,
            badge: goalBuffer.badge,
            objectives: goalBuffer.objectives,
            isMandatory: goalBuffer.isMandatory,
        };
    };
    const publishGoal = () => {
        setIsPerformingMutation(true);
        publish.mutate({
            goal: getPublishGoalInput(goalBuffer),
            draftId: goalBuffer.status === 'draft' ? goalBuffer.goalId : null,
        });
    };
    const deleteGoal = (goal) => {
        setIsPerformingMutation(true);
        deleteMutation.mutate({ goal });
    };
    const deleteDraft = (goal) => {
        setIsPerformingMutation(true);
        deleteDraftMutation.mutate({ goal });
    };
    const [snackbarConfig, setSnackbarConfig] = useState({
        message: '',
        severity: 'success',
        open: false,
        key: new Date().getTime(),
    });
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({
            message,
            severity,
            open: true,
            key: new Date().getTime(),
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const validationErrors = getGoalValidationErrors(goalBuffer, Date.now());
    const handleArchiveDialogOpen = () => {
        setShowArchiveDialog(true);
        analytics.onArchiveClicked(goalBuffer.status);
    };
    const handlePublishDialogOpen = () => {
        setShowPublishDialog(true);
    };
    const handlePublishDialogCancel = () => {
        setShowPublishDialog(false);
    };
    const handleDeleteDialogOpen = () => {
        if (lastSavedRevision && lastSavedRevision.status === 'draft') {
            setShowDeleteDraftDialog(true);
        }
        else if (lastSavedRevision) {
            setShowDeleteDialog(true);
        }
        analytics.onDeleteClicked(goalBuffer.status);
    };
    const onBackLinkClickedAnalytics = useCallback(() => {
        try {
            analytics.onBackLinkClicked(goalBuffer.status, goalBuffer, lastSavedRevision);
        }
        catch (error) {
            console.error('error in onBackLinkClickedAnalytics', error);
        }
    }, [analytics, goalBuffer, lastSavedRevision]);
    return (_jsx("main", { children: _jsxs(MainContainer, { component: "form", children: [_jsx(Header, { children: _jsxs(Stack, { sx: (theme) => ({ marginBottom: theme.typography.pxToRem(6) }), children: [_jsx(BackLink, { to: goalsAdminUrls.listPage, analyticsCallback: onBackLinkClickedAnalytics, children: t('goalsAdmin.pages.edit.backButton') }), _jsxs(Stack, { direction: "row", alignItems: "center", gap: (theme) => theme.typography.pxToRem(10), children: [_jsx(Typography, { component: "h1", variant: "h2", sx: {
                                            overflow: 'hidden',
                                            textOverflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                        }, children: goalBuffer.status === 'new'
                                            ? t('goalsAdmin.pages.edit.createNewGoalHeading')
                                            : (lastSavedRevision === null || lastSavedRevision === void 0 ? void 0 : lastSavedRevision.name) || (_jsxs(_Fragment, { children: [t('goalsAdmin.pages.list.untitledDraft'), " -", ' ', _jsx("span", { style: { fontStyle: 'italic', paddingRight: '6px' }, children: dayjs(lastSavedRevision.createdAt).format('DD MMM YYYY - HH:mm:ss') })] })) }), goalBuffer.status !== 'new' && (_jsx(Badge, { label: (_b = t('goalsAdmin.entities.goals.status.' + goalBuffer.status)) !== null && _b !== void 0 ? _b : '', variant: statusToBadgeMap[goalBuffer.status] }))] })] }) }), _jsx(Divider, {}), _jsxs(Stack, { direction: "row", sx: { minHeight: 0, flex: 1 }, children: [_jsx(Box, { sx: { flex: 1 }, children: _jsx(SectionContainer, { isCentered: false, children: _jsx(GoalEditor, { data: goalBuffer, availableCategories: availableCategories, validationErrors: validationErrors, editingDisabled: !['draft', 'new'].includes(initialGoalDetails.status), onEdit: (updatedGoalBuffer) => {
                                        setGoalBuffer(updatedGoalBuffer);
                                        setIsFormDirty(!isCleanBuffer(updatedGoalBuffer, getGoalBuffer(lastSavedRevision)));
                                    } }) }) }), goalBuffer.status !== 'archived' && (_jsx(Box, { sx: { width: '400px' }, children: _jsx(ActionPanel, { status: goalBuffer.status, isFormDirty: isFormDirty, validationErrors: validationErrors, isPerformingMutation: isPerformingMutation, onSave: saveGoal, onPublish: handlePublishDialogOpen, onArchive: handleArchiveDialogOpen, onDelete: handleDeleteDialogOpen }) }))] }), goalBuffer.startDate && (_jsx(ConfirmPublishDialog, { isOpen: showPublishDialog, isEmailSendingEnabled: (_f = (_e = (_d = (_c = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _c === void 0 ? void 0 : _c.preferences) === null || _d === void 0 ? void 0 : _d.goals) === null || _e === void 0 ? void 0 : _e.emailsEnabled) !== null && _f !== void 0 ? _f : true, startDate: goalBuffer.startDate, goalStatus: goalBuffer.status, onConfirm: publishGoal, onCancel: handlePublishDialogCancel })), _jsx(ConfirmationDialog, { id: "archive-confirmation-dialog", keepMounted: true, open: showArchiveDialog, color: "default", title: t('goalsAdmin.components.molecules.confirmationDialog.archiveDialog.title'), confirmButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.archiveDialog.confirmButtonLabel'), cancelButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.archiveDialog.cancelButtonLabel'), icon: _jsx(ArchiveRoundedIcon, {}), onConfirm: () => {
                        setShowArchiveDialog(false);
                        archiveGoal({ goalId: goalBuffer.goalId, basedOnRevisionNumber: goalBuffer.basedOnRevisionNumber });
                        analytics.onArchiveDialogClosed(goalBuffer.status, true);
                    }, onCancel: () => {
                        setShowArchiveDialog(false);
                        analytics.onArchiveDialogClosed(goalBuffer.status, false);
                    }, children: t('goalsAdmin.components.molecules.confirmationDialog.archiveDialog.content') }), _jsx(ConfirmationDialog, { id: "delete-confirmation-dialog", keepMounted: true, open: showDeleteDialog, color: "warning", title: t('goalsAdmin.components.molecules.confirmationDialog.deleteDialog.title'), confirmButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.deleteDialog.confirmButtonLabel'), cancelButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.deleteDialog.cancelButtonLabel'), icon: _jsx(DeleteRoundedIcon, {}), onConfirm: () => {
                        setShowDeleteDialog(false);
                        deleteGoal({ goalId: goalBuffer.goalId, basedOnRevisionNumber: goalBuffer.basedOnRevisionNumber });
                        analytics.onDeleteDialogClosed(goalBuffer.status, true);
                    }, onCancel: () => {
                        setShowDeleteDialog(false);
                        analytics.onDeleteDialogClosed(goalBuffer.status, false);
                    }, children: t('goalsAdmin.components.molecules.confirmationDialog.deleteDialog.content') }), _jsx(ConfirmationDialog, { id: "delete-draft-confirmation-dialog", keepMounted: true, open: showDeleteDraftDialog, color: "warning", title: t('goalsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.title'), confirmButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.confirmButtonLabel'), cancelButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.cancelButtonLabel'), icon: _jsx(DeleteRoundedIcon, {}), onConfirm: () => {
                        setShowDeleteDraftDialog(false);
                        deleteDraft({ goalId: goalBuffer.goalId });
                        analytics.onDeleteDialogClosed(goalBuffer.status, true);
                    }, onCancel: () => {
                        setShowDeleteDraftDialog(false);
                        analytics.onDeleteDialogClosed(goalBuffer.status, false);
                    }, children: t('goalsAdmin.components.molecules.confirmationDialog.deleteDraftDialog.content') }), _jsx(ConfirmationDialog, { id: "unsaved-changes-confirmation-dialog", keepMounted: true, open: blocker.state === 'blocked', color: "warning", title: t('goalsAdmin.components.molecules.confirmationDialog.unsavedChangesDialog.title'), confirmButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.unsavedChangesDialog.confirmButtonLabel'), cancelButtonLabel: t('goalsAdmin.components.molecules.confirmationDialog.unsavedChangesDialog.cancelButtonLabel'), icon: _jsx(ErrorOutlineRoundedIcon, {}), onConfirm: () => {
                        var _a;
                        (_a = blocker.proceed) === null || _a === void 0 ? void 0 : _a.call(blocker);
                    }, onCancel: () => {
                        var _a;
                        (_a = blocker.reset) === null || _a === void 0 ? void 0 : _a.call(blocker);
                    }, children: t('goalsAdmin.components.molecules.confirmationDialog.unsavedChangesDialog.content') }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }) }));
};
