import { styled, keyframes } from '@mui/material';
import { Dialog as MuiDialog } from '@mui/material';
import { confettiOverlay } from '../../../common/components/organisms/Activities/ConfettiOverlay';
const confetti = keyframes({
    '0%': {
        backgroundPositionY: '0',
    },
    '100%': {
        backgroundPositionY: '813px',
    },
});
export const CybermonDialog = styled(MuiDialog)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(10),
    '& .MuiModal-backdrop': {
        backgroundImage: `${confettiOverlay}`,
        backgroundRepeat: 'repeat',
        animation: `${confetti} 10s infinite linear`,
    },
}));
