import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Rive } from '../../common/Rive/Rive';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { CybermonDialog } from '../styled/CybermonDialog.styled';
import { Card } from '@securecodewarrior/design-system-react/lib/labs/atoms/Card/Card';
import { Box, Stack, Typography } from '@mui/material';
import { useModal } from '../context/ModalContext';
import { useMemo } from 'react';
import { useCybermon } from '../hooks/useCybermon';
export const CybermonAchievementDialog = () => {
    const { setAchievementModalOpen, isAchievementModalOpen } = useModal();
    const { cybermon: achievements } = useCybermon();
    const isCyberHero = achievements.find((c) => c.name === 'Cyber Hero');
    useMemo(() => {
        if (!isCyberHero)
            return;
        setAchievementModalOpen(isCyberHero.earned);
    }, [setAchievementModalOpen, isCyberHero]);
    const handleClose = () => setAchievementModalOpen(false);
    return (_jsxs(CybermonDialog, { open: isAchievementModalOpen, PaperComponent: Card, PaperProps: {
            sx: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                maxWidth: 960,
                alignItems: 'center',
                padding: 0,
            },
        }, children: [_jsx(Box, { height: 600, children: _jsx(Rive, { src: "https://media.securecodewarrior.com/achievements/cyberlord_card.riv" }) }), _jsxs(Stack, { justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 10, sx: { p: 6, backgroundColor: (theme) => theme.palette.container.fill.card2, height: '100%' }, children: [_jsxs(Stack, { direction: "column", gap: 10, children: [_jsxs(Typography, { variant: "h1", textAlign: "center", sx: {
                                    textWrap: 'pretty',
                                    '& > span': {
                                        color: (theme) => theme.palette.text.status.success,
                                    },
                                }, children: ["You are the ", _jsx("span", { children: "Cyber Hero!" })] }), _jsx(Typography, { textAlign: "center", children: "Congratulations on defeating all the Cybermon. As a results you have earned an extra badge and have been crowned the ultimate security warrior." })] }), _jsx(Link, { onClick: handleClose, children: "Continue on your secure coding journey" })] })] }));
};
