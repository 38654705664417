import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, Typography, useTheme } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Chip } from '../../atoms/Chip';
import { GoalEditorSection, GoalEditorSubsection } from './GoalEditorSection';
import { CategorySelection } from '../CategorySelection';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../common/Tooltip';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { usePlatformContext } from '../../../../platformContext';
import { goalDetailsAnalytics } from '../../../analytics/GoalDetails.analytics';
import { getEditingDisabledTooltip } from '../../../utils';
export const GoalEditorObjectivesSection = ({ selectedCategories, availableCategories, status, skeletonMode, editingDisabled, onSelectionEdit, }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const categoryMap = Object.fromEntries(Object.values(availableCategories).map((item) => [item.id, item]));
    const subcategoryCount = Object.keys(categoryMap).length;
    const readOnly = !!skeletonMode || editingDisabled;
    const readOnlyTooltip = getEditingDisabledTooltip(editingDisabled, status, t, i18n.format);
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = useMemo(() => goalDetailsAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const handleOpenVulnerabilityDialog = () => {
        setDialogOpen(true);
        analytics.onVulnerabilityDialogOpened(status, (selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length) || 0);
    };
    const handleVulnerabilityDialogClosing = () => {
        setDialogOpen(false);
        analytics.onVulnerabilityDialogClosed(status);
    };
    return (_jsxs(_Fragment, { children: [_jsx(GoalEditorSection, { children: _jsxs(Box, { sx: { flex: 1 }, children: [_jsx(Typography, { component: "h2", variant: "h5", sx: (theme) => ({
                                marginBottom: theme.spacing(10),
                                lineHeight: theme.typography.pxToRem(18),
                            }), children: t('goalsAdmin.components.organisms.goalEditor.sections.objectives.heading') }), _jsxs(GoalEditorSubsection, { children: [_jsxs(Box, { sx: (theme) => ({
                                        boxSizing: 'border-box',
                                        width: { xs: '100%', lg: theme.typography.pxToRem(300) },
                                    }), children: [_jsx(Typography, { component: "h3", variant: "h6", sx: (theme) => ({
                                                marginBottom: theme.spacing(4),
                                                lineHeight: theme.typography.pxToRem(18),
                                            }), children: t('goalsAdmin.components.organisms.goalEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.heading') }), skeletonMode ? (_jsx(Skeleton, { width: "240px", height: "40px", variant: "rounded" })) : (_jsx(Typography, { component: "p", variant: "subtitle1", maxWidth: (theme) => theme.typography.pxToRem(300), fontSize: (theme) => theme.typography.pxToRem(14), lineHeight: (theme) => theme.typography.pxToRem(20), children: _jsx(Trans, { i18nKey: 'goalsAdmin.components.organisms.goalEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.text', values: { count: subcategoryCount } }) }))] }), _jsxs(Box, { sx: {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: theme.spacing(2),
                                        flex: 1,
                                        boxSizing: 'border-box',
                                    }, children: [selectedCategories
                                            .sort((a, b) => { var _a, _b; return (((_a = categoryMap[a]) === null || _a === void 0 ? void 0 : _a.name) || a).localeCompare(((_b = categoryMap[b]) === null || _b === void 0 ? void 0 : _b.name) || b); })
                                            .map((cat) => {
                                            var _a;
                                            return (_jsx(Chip, { label: ((_a = categoryMap[cat]) === null || _a === void 0 ? void 0 : _a.name) || cat }, cat));
                                        }), skeletonMode === 'edit' && (_jsxs(_Fragment, { children: [_jsx(Skeleton, { variant: "rounded", width: "220px", height: "36px" }), _jsx(Skeleton, { variant: "rounded", width: "250px", height: "36px" }), _jsx(Skeleton, { variant: "rounded", width: "200px", height: "36px" })] })), _jsx(Tooltip, { title: readOnlyTooltip, arrow: true, children: _jsx(Box, { sx: { height: 'fit-content' }, children: _jsx(Button, { sx: { height: theme.spacing(9) }, startIcon: selectedCategories.length === 0 ? (_jsx(AddIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) })) : (_jsx(EditIcon, { sx: (theme) => ({ fontSize: theme.typography.pxToRem(18) }) })), disabled: readOnly, onClick: handleOpenVulnerabilityDialog, children: selectedCategories.length === 0 && skeletonMode !== 'edit'
                                                        ? t('goalsAdmin.components.organisms.goalEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.selectButton')
                                                        : t('goalsAdmin.components.organisms.goalEditor.sections.objectives.subsections.vulnerabilitiesAndConcepts.editButton') }) }) })] })] })] }) }), _jsx(Dialog, { open: dialogOpen, fullWidth: true, maxWidth: "xl", scroll: "body", onClose: handleVulnerabilityDialogClosing, sx: (theme) => ({
                    '& .MuiPaper-root:not(.MuiAlert-root)': {
                        border: `1px solid ${theme.palette.container.border.default}`,
                        borderRadius: 3,
                        '@media (max-height: 890px)': {
                            marginTop: theme.spacing(5),
                            marginBottom: theme.spacing(5),
                        },
                        '@media (max-height: 784px)': {
                            marginTop: theme.spacing(3),
                            marginBottom: theme.spacing(3),
                        },
                    },
                }), children: _jsx(CategorySelection, { categories: availableCategories, initiallySelectedCategories: selectedCategories, goalStatus: status, onSubmit: {
                        action: (updatedCategories) => {
                            setDialogOpen(false);
                            analytics.onObjectiveVulnerabilitiesUpdated(status, selectedCategories, updatedCategories);
                            onSelectionEdit === null || onSelectionEdit === void 0 ? void 0 : onSelectionEdit(updatedCategories);
                        },
                    }, onBack: {
                        action: handleVulnerabilityDialogClosing,
                        label: t('common.labels.cancel'),
                    } }) })] }));
};
