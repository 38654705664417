import { styled } from '@mui/material';
export const CybermonAvatarImage = styled('img', {
    shouldForwardProp: (prop) => prop !== 'earned',
})(({ theme: _theme }) => ({
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
}), ({ earned }) => ({
    opacity: earned ? 1 : 0.2,
    mixBlendMode: earned ? 'normal' : 'lighten',
}));
