import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Box, Stack, FormControl, Typography } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { LocalFireDepartmentRounded as LocalFireDepartmentIcon } from '@mui/icons-material';
import { LoginMessage } from '../../explore/LoginMessage';
import { CybermonGrid } from '../styled';
import { useCybermonActivityFilter } from '../hooks/useCybermonActivityFilter';
import { ActivityCard } from './ActivityCard';
import { SelectLanguagesModal } from './SelectLanguagesModal';
import { LanguageSelect } from './LanguageSelect';
import { ModalProvider } from '../context/ModalContext';
import { usePlatformContext } from '../../platformContext';
import { cybermonActivities } from '../hooks/Cybermon.analytics';
import { useCybermon } from '../hooks/useCybermon';
const LanguageFilter = ({ languages }) => {
    return (_jsx(Stack, { direction: "row", gap: 2, flexWrap: "wrap", minHeight: (theme) => theme.typography.pxToRem(24), alignItems: "center", children: _jsx(FormControl, { component: "fieldset", children: _jsxs(ModalProvider, { children: [_jsx(LanguageSelect, { languages: languages }), _jsx(SelectLanguagesModal, { languages: languages })] }) }) }));
};
const EmptyCard = () => {
    const { selectedLanguages } = useCybermonActivityFilter();
    const title = selectedLanguages.length ? 'No content left?' : 'No language selected?';
    const emptyStateCardHeader = selectedLanguages.length
        ? 'There are no more activities available in your selected language.'
        : "You don't have any language selected.";
    const emptyStateCardBody = selectedLanguages.length
        ? 'Please try a different language to find more content.'
        : 'Please select a language to find content.';
    return (_jsxs(Card, { display: "flex", flexDirection: "column", justifyContent: "center", gap: 5, sx: { backgroundColor: (theme) => theme.palette.container.fill.card2 }, children: [_jsx(Typography, { variant: "h5", className: "card-title", children: title }), _jsxs(Typography, { className: "card-body", children: [_jsx("p", { children: emptyStateCardHeader }), _jsx("p", { children: emptyStateCardBody })] })] }));
};
export const Activities = (props) => {
    const { canLoadMore, visibleActivities, loadMore, selectedLanguages } = useCybermonActivityFilter();
    const { logAnalyticsEvent } = usePlatformContext();
    const activityAnalytics = cybermonActivities(logAnalyticsEvent);
    for (const activity of visibleActivities) {
        activityAnalytics.viewActivity(activity);
    }
    const { beginAttempt } = useCybermon();
    const shouldRenderEmptyCard = (!canLoadMore && visibleActivities.length < 3) || selectedLanguages.length === 0;
    const handleClick = (activity) => {
        beginAttempt();
        props.onActivitySelected(activity);
    };
    return (_jsxs(_Fragment, { children: [_jsx(LanguageFilter, { languages: props.filterOptions.languages }), !props.isAuthenticated && _jsx(LoginMessage, { onLoginClicked: props.onLoginClicked }), _jsxs(CybermonGrid, { gridTemplateColumns: { sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }, children: [visibleActivities.map((activity) => (_jsx(ActivityCard, Object.assign({ onClick: () => handleClick(activity) }, activity), `${activity.contentId}${'language' in activity ? activity.language.id : ''}`))), shouldRenderEmptyCard && _jsx(EmptyCard, {}), canLoadMore && (_jsx(Box, { display: "flex", alignItems: "center", justifyContent: "center", gridColumn: "1 / -1", children: _jsx(Button, { onClick: loadMore, startIcon: _jsx(LocalFireDepartmentIcon, {}), disabled: shouldRenderEmptyCard || visibleActivities.length < 3, children: "Load more" }) }))] })] }));
};
