import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../platformContext';
import { usePlatformNavigate } from '../navigation';
import { useApiClient } from '../ApiContext';
import { useCallback, useEffect, useMemo } from 'react';
import { Suspenser } from '@securecodewarrior/design-system-react';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { NavigationBar } from '../navigation/NavigationBar';
export const MissionPlayerWrapper = ({ type }) => {
    const { contentId, languageFramework } = useParams();
    if (!contentId || !languageFramework)
        throw new Error('this component may only be used in a router that will set the correct params');
    const { t } = useTranslation();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const { trpc, wrapErrorPromise } = useApiClient();
    const response = useMemo(() => wrapErrorPromise(trpc.explore.mission.startOrContinue
        .mutate({ missionAlias: contentId, language: languageFramework })
        .then((response) => {
        if (response.kind === 'ok') {
            const ev = response.content_type === 'mission'
                ? { event: 'Access Mission', mission_slug: contentId, scope: 'explore' }
                : { event: 'Access Walkthrough', walkthrough_slug: contentId, scope: 'explore' };
            platformCtx.logAnalyticsEvent(ev);
        }
        return response;
    })), [wrapErrorPromise, contentId, languageFramework, platformCtx] //eslint-disable-line react-hooks/exhaustive-deps
    );
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', search: '', hash: '' });
    }, [navigate, platformCtx]);
    useEffect(() => {
        const handlePostMessage = (event) => {
            const kind = event.data.event;
            if (kind === 'navigateToMissions' || kind === 'navigateToNextStage' || kind === 'skipChallenge') {
                navigateBack();
            }
        };
        window.addEventListener('message', handlePostMessage);
        return () => window.removeEventListener('message', handlePostMessage);
    }, [navigateBack]);
    const usersnapData = {
        [`data-${type}-id`]: contentId,
        [`data-${type}-language`]: languageFramework,
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: type, module: "explore", onBackNavigation: navigateBack }), _jsx(Suspenser, { reader: response, skeleton: _jsx(_Fragment, {}), render: (x) => (_jsx("iframe", Object.assign({ src: x.playerUrl, id: "adversary", style: iframeStyle, "aria-label": t(x.content_type.toUpperCase()), title: t(x.content_type.toUpperCase()), allow: "clipboard-read; clipboard-write; display-capture;" }, usersnapData))) })] }));
};
const iframeStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
