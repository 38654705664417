export const questAnalytics = (sendEvent) => ({
    onEditLanguageClicked: () => {
        sendEvent({ event: 'Edit Language Clicked', scope: 'quests' });
    },
    onTopicCollapseToggled: (categoryId, wasCollapsed) => {
        const event = wasCollapsed ? 'Topic opened' : 'Topic closed';
        sendEvent({ event, scope: 'quests', categoryId });
    },
    onActivityStarted: (status, activityId, activityType, categoryId) => {
        sendEvent({ event: 'Activity Started', scope: 'quests', status, activityId, activityType, categoryId });
    },
    onActivityFinished: (activityId, activityType, categoryId) => {
        sendEvent({ event: 'Activity Finished', scope: 'quests', activityId, activityType, categoryId });
    },
    onNextActivityClicked: (activityId, activityType, categoryId) => {
        sendEvent({ event: 'Next Activity Clicked', scope: 'quests', activityId, activityType, categoryId });
    },
    onBackToQuestsClicked: (component, activityType) => {
        sendEvent({ event: 'Back to Quests Clicked', scope: 'quests', component, activityType });
    },
    onSearchChanged: (searchTerm) => {
        sendEvent({ event: 'Quest Search Changed', scope: 'quests', searchTerm });
    },
    onSearchReset: () => {
        sendEvent({ event: 'Quest Search Reset', scope: 'quests' });
    },
    onFirstLanguageSelection: () => {
        sendEvent({ event: 'First Language selection open', scope: 'quests' });
    },
    onQuestLanguageChanged: (languageId) => {
        sendEvent({ event: 'Quest Language Selection Changed', scope: 'quests', languageId });
    },
});
