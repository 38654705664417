import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect } from 'react';
import { CommunicationItemContainer as ItemContainer } from '../Communications.styled';
import { WandaSwitch } from '../../common/Switch/Switch';
import { Box, Stack, Typography } from '@mui/material';
export const OptimisticSwitch = ({ title, description, descriptionComponent, children, checked, disabled, onSave, inputProps, }) => {
    const [isChecked, setIsChecked] = useState(checked !== null && checked !== void 0 ? checked : false);
    const [_, setIsLoading] = useState(false);
    const [switchColor, setSwitchColor] = useState('primary');
    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked);
        }
    }, [checked]);
    const handleChange = useCallback((event) => {
        const newChecked = event.target.checked;
        setIsChecked(newChecked);
        setIsLoading(true);
        setSwitchColor('primary');
        onSave(newChecked)
            .then(() => {
            setSwitchColor('success');
            setIsLoading(false);
        })
            .catch((error) => {
            console.error(`OptimisticSwitch: Failed to save ${title}`, error);
            setTimeout(() => {
                setIsChecked(!newChecked); // Revert the change
                setSwitchColor('error');
            }, 1000);
        })
            .finally(() => {
            // Reset color to primary after 2 seconds
            setTimeout(() => {
                setSwitchColor('primary');
            }, 2000);
        });
    }, [onSave, title]);
    return (_jsxs(ItemContainer, { sx: { rowGap: descriptionComponent || description ? 4 : undefined }, children: [_jsx(WandaSwitch, { sx: { gridArea: 'switch' }, checked: isChecked, disabled: disabled, onChange: handleChange, inputProps: inputProps, color: switchColor }), _jsx(Typography, { sx: { gridArea: 'title' }, children: title }), (description || descriptionComponent) && (_jsx(Stack, { rowGap: 4, sx: { gridArea: 'caption', alignSelf: 'start' }, children: !descriptionComponent ? _jsx(Typography, { variant: "caption", children: description }) : descriptionComponent })), _jsx(Box, { sx: {
                    gridArea: 'right',
                    alignSelf: 'flex-start',
                    marginInlineStart: { sm: 0, md: 4 },
                    marginBlockStart: { sm: 4, md: 0 },
                }, children: children })] }));
};
