import { styled } from '@mui/material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
export const CybermonAchievementCard = styled(Card)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'minmax(70px, 3fr) 8fr',
    minHeight: '80px',
    height: '100%',
    borderRadius: theme.shape.borderRadius * 3,
    alignItems: 'center',
}));
