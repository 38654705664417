import { constants } from '@scw/metadata';

const { SCW_ADMIN, COMPANY_ADMIN, TEAM_MANAGER, DEVELOPER, CMS_DEVELOPER } = constants.user.role;
const { ACTIVATION_TIMED_OUT, DISABLED, ENABLED, READ_ONLY, REGISTERED } = constants.user.status;
const { PAID_USER, WEB_TRIAL, SALES_TRIAL } = constants.user.type;

export const AUTH_EVENTS = {
  loginSuccess: 'auth-login-success',
  loginFailed: 'auth-login-failed',
  logoutSuccess: 'auth-logout-success',
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized',
};

export const FEATURE_EVENTS = {
  refreshFeatures: 'refresh-features',
};

export const USER_ROLES = {
  admin: SCW_ADMIN,
  reseller: 'reseller',
  companyAdmin: COMPANY_ADMIN,
  manager: TEAM_MANAGER,
  player: DEVELOPER,
  cmsDeveloper: CMS_DEVELOPER,
  assessmentUser: 'assessment developer',
  anonymous: 'anonymous',
  higherThan: function (role) {
    const chainOfCommand = [DEVELOPER, CMS_DEVELOPER, TEAM_MANAGER, COMPANY_ADMIN, 'reseller', SCW_ADMIN];
    const roleIndex = chainOfCommand.indexOf(role);
    return chainOfCommand.filter((_role, index) => index >= roleIndex);
  },
};

export const USER_STATUS = {
  ACTIVATION_TIMED_OUT,
  DISABLED,
  ENABLED,
  READ_ONLY,
  REGISTERED,
};

export const USER_TYPE = {
  PAID_USER,
  WEB_TRIAL,
  SALES_TRIAL,
  SCW_ADMIN: constants.user.type.SCW_ADMIN,
};

export const SESSION_HEADER = 'X-Session-ID';

export const SESSION_KEY = 'sessionId';
