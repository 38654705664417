import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
export const ModalContext = createContext({
    setLanguageModalOpen: () => { },
    setCategoryModalOpen: () => { },
    setAchievementModalOpen: () => { },
    isLanguageModalOpen: false,
    isCategoryModalOpen: false,
    isAchievementModalOpen: false,
});
export const useModal = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
export const ModalProvider = ({ children }) => {
    const [isLanguageModalOpen, setLanguageModalOpen] = useState(false);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isAchievementModalOpen, setAchievementModalOpen] = useState(false);
    return (_jsx(ModalContext.Provider, { value: {
            setLanguageModalOpen,
            setCategoryModalOpen,
            setAchievementModalOpen,
            isLanguageModalOpen,
            isCategoryModalOpen,
            isAchievementModalOpen,
        }, children: children }));
};
