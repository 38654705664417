import { Box, styled } from '@mui/material';
export const CybermonGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(5),
    gridAutoRows: 'auto',
    gridTemplateRows: 'minmax(280px, 1fr)',
    '& .text-with-icon': {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(1),
    },
    '& .health': {
        color: theme.palette.badge.error.fill,
    },
    '& > button.card-button': Object.assign(Object.assign({ backgroundColor: theme.palette.container.fill.card1, border: `1px solid ${theme.palette.container.border.default}`, borderRadius: theme.shape.borderRadius * 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }, theme.typography.body1), { '&:hover': {
            backgroundColor: theme.palette.container.fill.card2,
            cursor: 'pointer',
        }, '&:focus-within, &:focus': {
            outline: `2px solid ${theme.palette.container.border.active}!important`,
        } }),
    '& .card-container': Object.assign(Object.assign({ display: 'flex', flexDirection: 'column', flexGrow: 1, border: `1px solid ${theme.palette.container.border.default}`, borderRadius: theme.shape.borderRadius * 3, backgroundColor: theme.palette.container.fill.card1 }, theme.typography.body1), { '&:hover': {
            backgroundColor: theme.palette.container.fill.card2,
            cursor: 'pointer',
        }, '&:focus-within': {
            outline: `2px solid ${theme.palette.container.border.active}`,
        } }),
    '& .content': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: theme.spacing(5),
        gap: theme.spacing(5),
        '& .content-text': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(5),
        },
        '& .card-title': Object.assign({ margin: 0, wordBreak: 'break-word', fontWeight: '600' }, theme.typography.h5),
        '& .badges': {
            display: 'flex',
            gap: theme.spacing(1.5),
            flexWrap: 'wrap',
            '& .cl-badge': Object.assign(Object.assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(1, 2), borderRadius: theme.shape.borderRadius * 2 }, theme.typography.caption), { 'i, svg': {
                    minWidth: theme.typography.pxToRem(15),
                    fontSize: theme.typography.pxToRem(15),
                } }),
            '& .default': {
                backgroundColor: theme.palette.container.fill.card2,
                border: `1px solid ${theme.palette.container.border.default}`,
            },
        },
    },
    '& .card-info': {
        display: 'flex',
        color: theme.palette.text.soft,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.container.border.default}`,
        '& > *:not(:first-of-type)': {
            borderLeft: `1px solid ${theme.palette.container.border.default}`,
            height: '100%',
        },
        '& .activity-type-info': {
            padding: theme.spacing(3, 5),
            svg: {
                fontSize: theme.typography.pxToRem(24),
            },
        },
        '& .play-button': {
            color: theme.palette.badge.text,
            padding: theme.spacing(3, 5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .play-circle': {
                display: 'inline-flex',
                padding: theme.spacing(0.75),
                border: `1px solid ${theme.palette.badge.primary.border}`,
                borderRadius: '50%',
                backgroundColor: theme.palette.badge.primary.fill,
                '&.in_progress': {
                    backgroundColor: theme.palette.badge.inProgress.fill,
                    borderColor: theme.palette.badge.inProgress.border,
                },
                '&.completed': {
                    backgroundColor: theme.palette.badge.success.fill,
                    borderColor: theme.palette.badge.success.border,
                },
            },
            svg: {
                fontSize: theme.typography.pxToRem(16),
                flexShrink: 0,
            },
        },
    },
}));
